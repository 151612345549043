import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { AuthService } from '../service/rest/auth.service';
import { UserService } from '../service/rest/user.service';
import { ProductCatalogColumnService } from '../service/td-data-table/implementation/product-catalog.service';
import { ProductCheckoutColumnService } from '../service/td-data-table/implementation/product-checkout.service';
import { PageDataService } from '../service/util/page-data.service';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { OrderDashboardColumnService } from '../service/td-data-table/implementation/contract-dashboard.service';
import { PageAgentModule } from './agent/page-agent.module';
import { PageCommonsModule } from './commons/page-commons.module';
import { PageB2BModule } from './b2b/page-b2b.module';
import { AgentCustomerListColumnService } from '../service/td-data-table/implementation/customer-list.service';
import {
	CustomerDetailDestinationsColumnService,
	CustomerDetailFullDetailPaymentsColumnService
} from '../service/td-data-table/implementation/customer-detail.service';
import { PageBackofficeModule } from './backoffice/page-backoffice.module';
import { AgentListColumnService } from '../service/td-data-table/implementation/agent-list.service';
import { FleetListColumnService } from '../service/td-data-table/implementation/fleet-list.service';
import { CustomerDetailBankColumnService, CustomerDetailOperationalHeadquarterColumnService } from '../service/td-data-table/implementation/customer-detail.service';
import { UserListColumnService } from '../service/td-data-table/implementation/user-list.service';
import { PermissionService } from '../service/rest/permission.service';

@NgModule({
	imports: [WidgetModule, SharedModule, PageAgentModule, PageB2BModule, PageCommonsModule, PageBackofficeModule],
	declarations: [],
	providers: [
		AuthService,
		PageDataService,
		UserService,
		PermissionService,
		// td-data-table columns
		AgentCustomerListColumnService,
		CustomerDetailDestinationsColumnService,
		CustomerDetailFullDetailPaymentsColumnService,
		CustomerDetailBankColumnService,
		CustomerDetailOperationalHeadquarterColumnService,
		OrderDashboardColumnService,
		ProductCatalogColumnService,
		ProductCheckoutColumnService,
		AgentListColumnService,
		UserListColumnService,
		FleetListColumnService
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageModule {}
