export interface PermissionAuxiliaryTableStateModel {
	permission_list?: PermissionItem[];
	permission_group_list?: PermissionGroupItem[];
	context_list?: Context[];
}

export interface PermissionItem {
	code: number;
	key: string;
	description: string;
	context_code: number[];
}

export interface PermissionGroupItem extends PermissionItem {
	permission: number[];
}

export interface Context {
	code: number;
	key: string;
	description: string;
}

export const PermissionAuxiliaryDictionary = [
	{
		key: 'permission_list',
		endpoint: 'permissions/detail'
	},
	{
		key: 'permission_group_list',
		endpoint: 'permission-groups/detail'
	},
	{
		key: 'context_list',
		endpoint: 'contexts/detail'
	}
];
