import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { Moment } from 'moment';
const moment = _moment;

export interface DateRange {
	from?: Moment;
	to?: Moment;
}

@Injectable({
	providedIn: 'root'
})
export class DateMomentService {
	get weekRange(): DateRange {
		const today = moment().endOf('day');
		const lastWeek = moment()
			.subtract(6, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastWeek,
			to: today
		};
		return result;
	}

	get twoWeekRange() {
		const today = moment().endOf('day');
		const lastTwoWeeks = moment()
			.subtract(13, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastTwoWeeks,
			to: today
		};
		return result;
	}

	get monthRange(): DateRange {
		const today = moment().endOf('day');
		const lastMonth = moment()
			.subtract(30, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastMonth,
			to: today
		};
		return result;
	}

	get twoMonthRange(): DateRange {
		const today = moment().endOf('day');
		const lastTwoMonths = moment()
			.subtract(59, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastTwoMonths,
			to: today
		};
		return result;
	}

	get threeMonthRange(): DateRange {
		const today = moment().endOf('day');
		const lastThreeMonths = moment()
			.subtract(89, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastThreeMonths,
			to: today
		};
		return result;
	}

	retrieveRangeDate(startDate: Moment, endDate: Moment): any[] {
		const rangeDate = [];
		rangeDate.push(startDate);
		const countDate = moment(startDate);
		while (countDate < endDate) {
			countDate.add(1, 'days');
			rangeDate.push(moment(countDate));
		}
		return rangeDate;
	}

	retrieveWeekEnds(date) {
		// recupero il primo lunedì più vicino rispetto alla data selezionata
		const firstMonday = moment(date);
		firstMonday.day(1);
		// recupero il prossimo sabato rispetto al lunedì recuperato precedentemente
		const firstSaturday = moment(firstMonday);
		firstSaturday.day(6);
		return [firstMonday, firstSaturday];
	}

	convertStringDateFromFormatToFormat(stringDate: string, fromFormat: string, toFormat) {
		return moment(stringDate, fromFormat).format(toFormat);
	}
}
