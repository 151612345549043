import { Injectable } from '@angular/core';

// model
import { BaseStateModel, BaseState } from '../../model/state/base-state.model';
import { BodyTablePouchCustomModel } from '../pouchdb/model/pouch-base.model';

// pouch instance
import { PouchDbMobeeAgentiAdapter } from '../pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';

@Injectable({
	providedIn: 'root'
})
export class PouchUtilService {
    constructor(
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {

	}
    async saveAnyDocument(action: any): Promise<any> {
		try {
			const actionReturn = await this.pouchDbMobeeAgentiAdapter.basePouch.saveBodyTable(action);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}
}
