import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TableColumnToggleComponent } from './table-column-toggle/table-column-toggle.component';
import { AutocompleteComponent } from './autocomplete/autocomplete.component';
import { FloatingMenuComponent } from './floating-menu/floating-menu.component';
import { DialogConfirmComponent } from './dialog/dialog-confirm/dialog-confirm.component';
import { DialogTextEditComponent } from './dialog/dialog-text-edit/dialog-text-edit.component';
import { ListWrapperComponent } from './list-wrapper/list-wrapper.component';
import { StatusBarComponent } from './status-bar/status-bar.component';
import { CardTrendComponent } from './card-trend/card-trend.component';
import { CardChartConfigurableComponent } from './card-chart-configurable/card-chart-configurable.component';
import { ApexchartWrapperComponent } from './apexchart-wrapper/apexchart-wrapper.component';
import { AttachmentResolverComponent } from './attachment-resolver/attachment-resolver.component';
import { DropAreaFileComponent } from './drop-area-file/drop-area-file.component';
import { TabWrapperComponent } from './tab-wrapper/tab-wrapper.component';
import { MatAccordionWrapperComponent } from './mat-accordion-wrapper/mat-accordion-wrapper.component';
import { NestedCategoryListComponent } from './nested-category-list/nested-category-list.component';
import { InputTypeSelectableComponent } from './input-type-selectable/input-type-selectable.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';
import { PersonCardComponent } from './person-card/person-card.component';
import { MatCardHighlightValueComponent } from './mat-card-highlight-value/mat-card-highlight-value.component';

import { PouchPagingBarComponent } from './pouch-paging-bar/pouch-paging-bar.component';
import { MatSnackBarWrapperComponent } from './mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { PasswordVerifyComponent } from './compound-input-interface/password-verify/password-verify.component';
import { SignatureFieldComponent } from './signature-field/signature-field.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { DialogSignatureComponent } from './dialog/dialog-signature/dialog-signature.component';
import { DialogRegistryCustomerComponent } from './dialog/dialog-registry-customer/dialog-registry-customer.component';
import { RegistryCustomerComponent } from './registry/registry-customer/registry-customer.component';
import { RegistryLegalHeadquarterComponent } from './registry/registry-legal-headquarter/registry-legal-headquarter.component';
import { RegistryOperationalHeadquarterComponent } from './registry/registry-operational-headquarter/registry-operational-headquarter.component';
import { RegistryBankDataComponent } from './registry/registry-bank-data/registry-bank-data.component';
import { RegistryGaragingComponent } from './registry/registry-garaging/registry-garaging.component';
import { EmojiResponseComponent } from './emoji-response/emoji-response.component';
import { DialogRegistryAgentComponent } from './dialog/dialog-registry-agent/dialog-registry-agent.component';
import { DialogContractTermsComponent } from './dialog/dialog-contract-terms/dialog-contract-terms.component';

@NgModule({
	imports: [SharedModule, SignaturePadModule],
	declarations: [
		TableColumnToggleComponent,
		AutocompleteComponent,
		FloatingMenuComponent,
		DialogConfirmComponent,
		DialogTextEditComponent,
		DialogRegistryCustomerComponent,
		DialogRegistryAgentComponent,
		ListWrapperComponent,
		StatusBarComponent,
		CardTrendComponent,
		CardChartConfigurableComponent,
		ApexchartWrapperComponent,
		AttachmentResolverComponent,
		DropAreaFileComponent,
		TabWrapperComponent,
		MatAccordionWrapperComponent,
		MatSnackBarWrapperComponent,
		NestedCategoryListComponent,
		InputTypeSelectableComponent,
		BreadcrumbComponent,
		PersonCardComponent,
		MatCardHighlightValueComponent,
		PouchPagingBarComponent,
		PasswordVerifyComponent,
		SignatureFieldComponent,
		DialogSignatureComponent,
		RegistryCustomerComponent,
		RegistryLegalHeadquarterComponent,
		RegistryOperationalHeadquarterComponent,
		RegistryBankDataComponent,
		RegistryGaragingComponent,
		EmojiResponseComponent,
		DialogContractTermsComponent
	],
	exports: [
		TableColumnToggleComponent,
		AutocompleteComponent,
		FloatingMenuComponent,
		DialogConfirmComponent,
		DialogTextEditComponent,
		DialogRegistryCustomerComponent,
		DialogRegistryAgentComponent,
		ListWrapperComponent,
		StatusBarComponent,
		CardTrendComponent,
		CardChartConfigurableComponent,
		ApexchartWrapperComponent,
		AttachmentResolverComponent,
		DropAreaFileComponent,
		TabWrapperComponent,
		MatAccordionWrapperComponent,
		MatSnackBarWrapperComponent,
		NestedCategoryListComponent,
		InputTypeSelectableComponent,
		BreadcrumbComponent,
		PersonCardComponent,
		MatCardHighlightValueComponent,
		PouchPagingBarComponent,
		PasswordVerifyComponent,
		SignatureFieldComponent,
		DialogSignatureComponent,
		EmojiResponseComponent,
		DialogContractTermsComponent
	],
	entryComponents: [
		DialogConfirmComponent,
		DialogTextEditComponent,
		DialogRegistryCustomerComponent,
		DialogRegistryAgentComponent,
		MatSnackBarWrapperComponent,
		DialogSignatureComponent,
		DialogContractTermsComponent
	]
})
export class WidgetModule {}
