import { ITdDataTableColumn } from '@covalent/core';

import { BaseTableListService } from '../base-table-list.service';

export class UserListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'id',
			label: '',
			width: 50
		},
		{
			name: 'username',
			label: 'Username'
		},
		{
			name: 'first_name',
			label: 'Name'
		},
		{
			name: 'last_name',
			label: 'Cognome'
		},
		{
			name: 'is_active',
			label: '',
			width: 50
		}
	];

	mockup = {
		data: [
			{
				id: '0',
				is_active: true,
				username: 'utente1@email.test',
				first_name: 'Mario',
				last_name: 'Rossi'
			},
			{
				id: '1',
				is_active: false,
				username: 'utente2@email.test',
				first_name: 'Luigi',
				last_name: 'Bianchi'
			}
		]
	};
}
