import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseStateModel } from '../../model/state/base-state.model';
import { AgentListActionEnum, AgentListStateAction } from './agent-list.actions';
import { BodyTablePouchCustomModel } from '../../service/pouchdb/model/pouch-base.model';
import { AgentCreateFullPipePayload } from '../../model/state/agent-state.model';

export namespace AgentListStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchCustomModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(AgentListStateAction.load, state => state),
		on(AgentListStateAction.update, (state, agent) => agent),
		on(AgentListStateAction.reset, () => null),
		on(AgentListStateAction.error, (state, agent) => ({ data: state ? state.data : null, type: agent.type })),
		on(AgentListStateAction.saveContextCodeSuccess, (state, param) => {
			const newState = <BaseStateModel<AgentCreateFullPipePayload[]>> {
				data: [param.data],
				type: param.type
			};
			return newState;
		})
	);

	export function reducer(state: BaseStateModel<BodyTablePouchCustomModel[]>, action: TypedAction<AgentListActionEnum>) {
		return _reducer(state, action);
	}
}
