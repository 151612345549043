import { Injectable } from '@angular/core';
import { StatusBarConfig } from '../../widget/status-bar/status-bar.component';
import * as _ from 'lodash';

@Injectable()
export abstract class BaseStatusBarConfigService {
	protected _config: StatusBarConfig;

	constructor() {}

	public set config(config: StatusBarConfig) {
		this._config = config;
	}

	public get config() {
		return this._config;
	}

	selected(key: string) {
		this._config.steps.find(step => {
			if (step.id === key) {
				return (step.selected = true);
			}
		});
	}

	selectOnly(key: string) {
		const newConfig = _.cloneDeep(this._config);
		newConfig.steps.forEach(step => {
			if (step.id === key) {
				step.selected = true;
			} else {
				step.selected = false;
			}
		});
		this._config = newConfig;
	}
}
