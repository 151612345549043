import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { PouchUtilService } from '@saep-ict/pouch-db';
import { AgentCustomerListPouchModel, BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import * as jwt_decode from 'jwt-decode';
import { LocalStorage } from 'ngx-webstorage';
import { Observable, Subject } from 'rxjs';
import { map, skipWhile, take, takeUntil, catchError } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { ContextApplicationItemCodeEnum } from '../../enum/context-application-item-code.enum';
import { TokenPayload } from '../../model/login.model';
import { RestBasePk } from '../../model/rest-base.model';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { LinkCodeModel, LinkDetailModel, UserDetailModel, UserTypeContextModel } from '../../model/user.model';
import { PermissionKeyEnum } from '../../router/permission-key.enum';
import { StateFeature } from '../../state';
import { AgentCustomerStateAction, AgentCustomerActionEnum } from '../../state/agent-customer/agent-customer.actions';
import { AuxiliaryTableStateAction } from '../../state/auxiliary-table/auxiliary-table.actions';
import { UserStateAction } from '../../state/user/user.actions';
import { AuthService } from '../rest/auth.service';
import { UserService } from '../rest/user.service';
import { ContextType, UtilGuardService } from './util-guard/util-guard.service';
import { PermissionAuxiliaryTableStateAction } from '../../state/permission-auxiliary-table/permission-auxiliary-table.actions';

@Injectable()
export class AuthTokenGuard implements CanActivate {
	@LocalStorage('authenticationToken')
	authenticationToken: string;
	@LocalStorage('link_code') link_code: LinkCodeModel;

	authState: boolean;
	destroy$: Subject<boolean> = new Subject<boolean>();
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	contextState$: Observable<BaseStateModel<ContextType>>;
	agentCustomer$: Observable<BaseStateModel<AgentCustomerListPouchModel>> = this.store.select(
		StateFeature.getAgentCustomer
	);

	constructor(
		private router: Router,
		private authService: AuthService,
		private pouchUtilService: PouchUtilService,
		private store: Store<any>,
		public snackBar: MatSnackBar,
		public translate: TranslateService,
		private utilGuardService: UtilGuardService,
		private userService: UserService
	) {}

	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!environment.mockup) {
			if (this.authenticationToken) {
				let hasBackoffice: UserTypeContextModel;
				let userState: UserDetailModel;
				let contextState: ContextType;
				let currentContext: UserTypeContextModel;
				this.user$.pipe(take(1)).subscribe(res => {
					userState = res ? res.data : null;
				});
				try {
					if (userState) {
						// TODO da modificare per l'impersonificazione
						hasBackoffice = this.utilGuardService
							.retrievePermissionRoute(userState)
							.find(userPermission => {
								return userPermission.permission === PermissionKeyEnum.BO_DASHBOARD;
							});
						if (!hasBackoffice && userState.current_context) {
							currentContext = this.utilGuardService.retrieveContextPermission(userState.current_context);
							this.contextState$ = this.store.select(currentContext.state);
							this.contextState$.pipe(take(1)).subscribe(res => {
								contextState = res ? res.data : null;
							});
						}
					}
				} catch (err) {
					console.log(err);
					this.authService.logout();
					return false;
				}
				if (userState && (contextState || hasBackoffice)) {
					return true;
				}
				const tk_decoded = jwt_decode(this.authenticationToken);
				this.authService.tokenPayload = new TokenPayload(tk_decoded);
				return this.checkUserPermission(state);
			} else {
				console.error('unauthorized: mostrare pagina di errore');
				this.authService.logout();
				return false;
			}
		}
		return true;
	}

	async checkUserPermission(state: RouterStateSnapshot): Promise<boolean> {
		const userDetailRequest: RestBasePk = { id: this.authService.tokenPayload.user_id };
		try {
			this.authState = false;
			let hasBackoffice: UserTypeContextModel;
			let contextList: UserTypeContextModel[];
			this.user = (await this.userService.getUserDetail(userDetailRequest)).data;
			await this.pouchUtilService.explicitInitCouch();
			this.store.dispatch(AuxiliaryTableStateAction.load());
			this.store.dispatch(PermissionAuxiliaryTableStateAction.load());
			if (this.link_code) {
				this.prepareUserContext(state);
			} else {
				contextList = this.utilGuardService.retrievePermissionRoute(this.user);
				if (contextList.length > 0) {
					this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
				} else {
					throw new Error('permission list is not defined');
				}
				hasBackoffice = contextList.find(userPermission => {
					return userPermission.type === ContextApplicationItemCodeEnum.BACKOFFICE;
				});
				if (hasBackoffice) {
					await this.setUserContext(hasBackoffice, state.url);
				} else if (this.utilGuardService.checkAmbiguousPermission(contextList) && !hasBackoffice) {
					this.router.navigate(['/authentication/client-code-select']);
				} else {
					await this.setUserContext(contextList[0], state.url);
				}
			}
			// set current permission by the context, code and general permission
			const currentPermission = this.utilGuardService.retrieveCurrentPermissionFromLinkCode(
				this.link_code,
				this.user
			);
			this.user.current_permission = currentPermission;
			this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
			return this.authState;
		} catch (err) {
			console.log(err);
			this.authService.logout();
			return false;
		}
	}

	prepareUserContext(state: RouterStateSnapshot) {
		const currentContext = this.utilGuardService.retrieveContextPermission(this.link_code.context);
		const contextLink = this.utilGuardService.checkUserContext(this.user, this.link_code.context);
		const currentLink = contextLink.currentContext.context_code_list.find((link: LinkDetailModel) => {
			return link.code === this.link_code.code;
		});
		if (!currentLink) {
			throw new Error('current code is invalid');
		}
		this.setUserContext(currentContext, state.url, this.link_code.code);
	}

	setUserContext(context: UserTypeContextModel, url: string, explicitLink?: string): Promise<boolean> {
		this.user.current_context = context.type;
		this.store.dispatch(UserStateAction.update(new BaseState(this.user)));
		return new Promise((resolve, reject) => {
			this.utilGuardService.dispatchUserContext(context, this.user, explicitLink);
			this.initObservableState(context.type).subscribe(
				async (res: ContextType) => {
					await this.retrieveStateForContext(context.type, res).catch(error => {
						this.destroy$.next(true);
						return reject(error);
					});
					this.destroy$.next(true);
					if (!explicitLink) {
						const contextLink = this.utilGuardService.checkUserContext(this.user, context.type);
						this.link_code = {
							context: context.type,
							code: contextLink.currentContext.context_code_list[0].code.toString()
						};
					}
					this.setRouteTree(context, url);
					return resolve(true);
				},
				error => {
					throw new Error(error);
				}
			);
		});
	}

	setRouteTree(permission: UserTypeContextModel, url: string) {
		// todo: puntate ad una chiave rispetto all'indice dell'array
		const currentPermission = this.utilGuardService.retrieveCurrentPermissionFromLinkCode(
			this.link_code,
			this.user
		);
		const currentRoutList = this.utilGuardService.filterRouteWithPermission(permission.route, currentPermission);
		this.router.config[1].children = currentRoutList;
		this.router.resetConfig(this.router.config);
		this.authState = true;
		this.router.navigate([url]);
	}

	initObservableState(key: ContextApplicationItemCodeEnum): Observable<ContextType> {
		this.contextState$ = this.store.select(this.utilGuardService.retrieveContextPermission(key).state);
		return this.contextState$.pipe(
			skipWhile((contextState: BaseStateModel<ContextType>) => !(contextState && contextState.data)),
			takeUntil(this.destroy$),
			map((contextState: BaseStateModel<ContextType>) => {
				return contextState.data;
			})
		);
	}

	retrieveStateForContext(context: ContextApplicationItemCodeEnum, data: ContextType): Promise<boolean> {
		// aggiungere la casistica quando si entra come customer direttamente
		if (context === ContextApplicationItemCodeEnum.AGENT) {
			data = data as BodyTablePouchModel;
			this.store.dispatch(AgentCustomerStateAction.load(new BaseState({ id: data.code_item })));
			return new Promise((resolve, reject) => {
				this.agentCustomer$
					.pipe(
						skipWhile(
							(agentCustomer: BaseStateModel<AgentCustomerListPouchModel>) =>
								!(agentCustomer && agentCustomer.type !== AgentCustomerActionEnum.LOAD)
						),
						takeUntil(this.destroy$),
						map((agentCustomer: BaseStateModel<AgentCustomerListPouchModel>) => {
							if (agentCustomer.type === AgentCustomerActionEnum.ERROR) {
								throw new Error(AgentCustomerActionEnum.ERROR);
							}
							return resolve(true);
						}),
						catchError(error => {
							reject(false);
							throw new Error(error);
						})
					)
					.subscribe();
			});
		} else {
			return new Promise(resolve => resolve(true));
		}
	}
}
