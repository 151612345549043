import { createReducer, on } from '@ngrx/store';

import { BaseStateModel } from '../../model/state/base-state.model';
import { TypedAction } from '@ngrx/store/src/models';
import { OrderStatisticsList } from '@saep-ict/pouch_agent_models/model/chart-pouch.model';
import { ChartStateAction, ChartActionEnum } from './chart.action';

export namespace ChartStateReducer {
    export const initialState: BaseStateModel<OrderStatisticsList> = null;

    const _reducer = createReducer(
		initialState,
		on(ChartStateAction.load, state => state),
		on(ChartStateAction.update, (state, order) => order),
		on(ChartStateAction.completed, (state, order) => ({ data: state ? state.data : null, type: order.type })),
		on(ChartStateAction.reset, () => initialState),
		on(ChartStateAction.error, (state, order) => ({ data: state ? state.data : null, type: order.type }))
	);

	export function reducer(
		state: BaseStateModel<OrderStatisticsList>,
		action: TypedAction<ChartActionEnum>
	) {
		return _reducer(state, action);
	}
}
