import { ITdDataTableColumn } from '@covalent/core';

import { BaseTableListService } from '../base-table-list.service';

export class AgentListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'id',
			label: 'ID',
			width: 50
		},
		{
			name: 'username',
			label: 'Username'
		},
		{
			name: 'first_name',
			label: 'Nome'
		},
		{
			name: 'last_name',
			label: 'Cognome'
		}
	];
}
