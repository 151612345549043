import { Component } from '@angular/core';
import { FormBuilder, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { CustomerTypeEnum } from '../../../enum/customer-type.enum';
import { RegistryModeEnum } from '../../../enum/registry-mode.enum';
import { Customer } from '../../../model/state/contract-state.model';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { BaseRegistryComponent } from '../BaseRegistryComponent';

export class FormControlConfiguration {
	name: string;
	validators?: ValidatorFn[];
	updateOn?: 'blur' | 'submit' | 'change' = 'blur';
}
@Component({
	selector: 'registry-customer',
	templateUrl: './registry-customer.component.html',
	styleUrls: ['./registry-customer.component.scss'],
	providers: [SubscribeManagerService]
})
export class RegistryCustomerComponent extends BaseRegistryComponent<Customer> {
	static COMPONENT_NAME = 'registry-customer';

	customerTypeEnum = CustomerTypeEnum;

	private businessForm: FormControlConfiguration[] = [];

	private privateForm: FormControlConfiguration[] = [];

	private formControlConfiguration: FormControlConfiguration[] = null;

	constructor(private fb: FormBuilder, private subscribeManagerService: SubscribeManagerService) {
		super(RegistryCustomerComponent.COMPONENT_NAME);
	}

	protected subscribeFields() {
		this.subscribeManagerService.populate(
			this.form.get('customer_type').valueChanges.subscribe(val => {
				if (val) {
					switch (val) {
						case CustomerTypeEnum.BUSINESS:
							this.formControlConfiguration = this.businessForm;
							this.resetFormControl(this.privateForm);
							break;
						case CustomerTypeEnum.PRIVATE:
							this.formControlConfiguration = this.privateForm;
							this.resetFormControl(this.businessForm);
							break;
					}
					this.populateFormControl(this.formControlConfiguration);
					this.setCustomerTypeDisabled();
				}
			}),
			'customer-type-change'
		);
	}

	private populateFormControl(configurationForm: FormControlConfiguration[]) {
		configurationForm.forEach(formControl => {
			this.form.addControl(
				formControl.name,
				new FormControl(this.data[formControl.name] ? this.data[formControl.name] : null, {
					validators: formControl.validators ? formControl.validators : [],
					updateOn: formControl.updateOn ? formControl.updateOn : 'blur'
				})
			);
		});
	}

	private resetFormControl(configurationForm: FormControlConfiguration[]) {
		configurationForm.forEach(field => {
			if (this.form.contains(field.name)) {
				if (this.data[field.name]) {
					this.data[field.name] = null;
				}
				this.form.removeControl(field.name);
			}
		});
	}

	private setCustomerTypeDisabled() {
		if (this.mode === RegistryModeEnum.EDIT) {
			this.data.customer_type = this.form.get('customer_type').value;
			this.form.get('customer_type').markAsTouched();
			this.form.get('customer_type').disable({ onlySelf: true, emitEvent: false });
		}
	}

	getVisibilityField(fieldName: string) {
		return this.formControlConfiguration
			? !!this.formControlConfiguration.find(formControl => formControl.name === fieldName)
			: false;
	}

	protected createForm() {
		this.businessForm = [
			{ name: 'business_name', validators: this.applyValidators ? [Validators.required] : [] },
			{
				name: 'vat_number',
				validators: this.applyValidators ? [Validators.required, Validators.minLength(11)] : []
			},
			{
				name: 'tax_code',
				validators: this.applyValidators ? [Validators.minLength(16)] : []
			},
			{ name: 'rea_number' },
			{ name: 'pec_email', validators: this.applyValidators ? [Validators.required, Validators.email] : [] },
			{ name: 'sdi_code' },
			{ name: 'phone_number' }
		];

		this.privateForm = [
			{ name: 'first_name', validators: this.applyValidators ? [Validators.required] : [] },
			{ name: 'last_name', validators: this.applyValidators ? [Validators.required] : [] },
			{ name: 'tax_code', validators: this.applyValidators ? [Validators.required, Validators.minLength(16)] : [] },
			{ name: 'email', validators: this.applyValidators ? [Validators.required, Validators.email] : [] },
			{ name: 'sdi_code' },
			{ name: 'phone_number' }
		];
		this.form = this.fb.group({
			customer_type: [null, { disabled: false, validators: Validators.required, updateOn: 'change' }]
		});
	}

	protected setForm() {
		const data = this.data;
		this.form.get('customer_type').setValue(this.data.customer_type);
	}

	protected onDestroy() {
		this.subscribeManagerService.destroy();
	}
}
