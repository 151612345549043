import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BodyTablePouchModel, OrderPouchModel, OrderStatusEnum } from '@saep-ict/pouch_agent_models';
import { OrderStatisticsList } from '@saep-ict/pouch_agent_models/model/chart-pouch.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { BaseState, BaseStateModel, Pagination } from '../../../model/state/base-state.model';
import { ChartFilterModel } from '../../../service/pouchdb/filter/chart-filter.model';
import { OrderDashboardColumnService } from '../../../service/td-data-table/implementation/contract-dashboard.service';
import { DateMomentService } from '../../../service/util/date-moment.service';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { StateFeature } from '../../../state';
import { ExtraFieldContractHeaderPouchModel } from '../../../model/state/contract-state.model';
import { ContractListFilterModel } from '../../../service/pouchdb/filter/contract-filter.model';


@Component({
	selector: 'app-dashboard',
	templateUrl: './dashboard.component.html',
	styleUrls: ['./dashboard.component.scss'],
	providers: [SubscribeManagerService]
})
export class DashboardComponent implements OnInit, OnDestroy {
	orderStatusEnum = OrderStatusEnum;
	agent$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(StateFeature.getAgentState);
	agent: BodyTablePouchModel;

	// orderList$: Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> = this.store.select(
	// 	StateFeature.getOrderListState
	// );
	orderList: BaseStateModel<OrderPouchModel<ExtraFieldContractHeaderPouchModel>[]>;
	orderFilter: BaseStateModel<ContractListFilterModel>;
	orderPagination: Pagination = { page_current: 1, page_size: 10 };
	orderSort: { [key: string]: 'asc' | 'desc' }[] = [
		{
			'header.date': 'desc'
		},
		{
			'header.status': 'desc'
		}
	];

	// orderStatistics$: Observable<BaseStateModel<OrderStatisticsList>> = this.store.select(
	// 	StateFeature.getOrderStatistics
	// );
	orderStatistics: BaseStateModel<OrderStatisticsList>;
	chartFilter: BaseStateModel<ChartFilterModel>;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public orderDashboardColumnService: OrderDashboardColumnService,
		private dateMomentService: DateMomentService
	) {
		this.agent$.pipe(take(1)).subscribe(res => {
			this.agent = res ? res.data : null;
			this.orderFilter = new BaseState(
				{
					user_code: this.agent.code_item
				},
				this.orderPagination,
				this.orderSort
			);
			this.chartFilter = new BaseState({ agent_code: this.agent.code_item });
		});
		// this.store.dispatch(OrderListStateAction.load(this.orderFilter));
		// this.store.dispatch(ChartStateAction.load(this.chartFilter));
		// this.subscribeManagerService.populate(this.retrieveOrderList().subscribe(), 'order-list');
		// this.subscribeManagerService.populate(this.retrieveOrderStatistics().subscribe(), 'order-statistics');
	}

	ngOnInit() {}

	// retrieveOrderList(): Observable<BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>> {
	// 	return this.orderList$.pipe(
	// 		skipWhile(
	// 			(orderList: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>) =>
	// 				!(this.agent && orderList && orderList.type !== OrderListActionEnum.LOAD)
	// 		),
	// 		map((orderList: BaseStateModel<OrderPouchModel<ExtraFieldOrderHeaderPouchModel>[]>) => {
	// 			if (orderList.type === OrderListActionEnum.ERROR) {
	// 				throw new Error(OrderListActionEnum.ERROR);
	// 			}
	// 			return (this.orderList = orderList);
	// 		})
	// 	);
	// }

	// retrieveOrderStatistics(): Observable<BaseStateModel<OrderStatisticsList>> {
	// 	return this.orderStatistics$.pipe(
	// 		skipWhile((stats: BaseStateModel<OrderStatisticsList>) => !(this.agent && this.orderStatistics)),
	// 		map((stats: BaseStateModel<OrderStatisticsList>) => {
	// 			if (stats.type === OrderListActionEnum.ERROR) {
	// 				throw new Error(OrderListActionEnum.ERROR);
	// 			}
	// 			return (this.orderStatistics = stats);
	// 		})
	// 	);
	// }

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		// this.store.dispatch(OrderListStateAction.reset());
	}
}
