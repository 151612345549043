import { ITdDataTableColumn } from '@covalent/core';
import { BaseTableListService } from '../base-table-list.service';

export class AgentContractListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'header.status',
			label: '',
			width: 50
		},
		{
			name: '_id',
			label: 'Contratto #',
			width: 130
		},
		{
			name: 'header.customer.business_name',
			label: 'Cliente'
		},
		{
			name: 'body.moped_data_sheet.modello',
			label: 'Mezzo',
			sortable: true
		},
		{
			name: 'header.franchiser.business_name',
			label: 'Agente'
		},
		{
			name: 'header.date',
			label: 'Data creazione',
			numeric: true,
			width: 150,
			sortable: true
		},
		{
			name: 'body.location_period',
			label: 'Durata locazione',
			numeric: true
		},
		{
			name: 'body.payment.total',
			label: 'Importo',
			numeric: true,
			width: 150,
			sortable: true
		}
	];
}

export class CompanyContractListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'header.status',
			label: '',
			width: 50
		},
		{
			name: '_id',
			label: 'Contratto #',
			width: 300,
			format: (v: string) => v.replace('contract_', '')
		},
		{
			name: 'body.moped_data_sheet.modello',
			label: 'Mezzo',
			sortable: true
		},
		{
			name: 'agent',
			label: 'Agente'
		},
		{
			name: 'header.date',
			label: 'Data creazione',
			numeric: true,
			width: 150,
			sortable: true
		},
		{
			name: 'body.location_period',
			label: 'Durata locazione',
			numeric: true,
			width: 150,
			sortable: true
		},
		{
			name: 'body.payment.total',
			label: 'Importo',
			numeric: true,
			width: 150,
			sortable: true
		}
	];
}

export class AgentDetailContractListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'header.status',
			label: '',
			width: 50
		},
		{
			name: '_id',
			label: 'Contratto #',
			width: 130
		},
		{
			name: 'header.customer.business_name',
			label: 'Cliente'
		},
		{
			name: 'body.moped_data_sheet.modello',
			label: 'Mezzo',
			sortable: true
		},
		{
			name: 'header.date',
			label: 'Data creazione',
			numeric: true,
			width: 150,
			sortable: true
		},
		{
			name: 'body.location_period',
			label: 'Durata locazione',
			numeric: true
		},
		{
			name: 'body.payment.total',
			label: 'Importo',
			numeric: true,
			width: 150,
			sortable: true
		}
	];
}