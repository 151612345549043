import { Injectable } from '@angular/core';
import {
    IResourceMethodPromise,
    IResourceMethodPromiseStrict,
    ResourceAction,
    ResourceRequestMethod,
} from '@ngx-resource/core';

import { LoginAuthResponseModel, RestPostUserListPayload } from '../../model/user.model';
import { RestBaseResponse, RestBasePk } from './../../model/rest-base.model';
import {
    LoginAuthRequestModel,
    UserActivateAccountRequestModel,
    UserDetailResponseModel,
    VerifyGuidEmailRequestModel,
    VerifyGuidEmailResponseModel,
} from './../../model/user.model';
import { BaseApiService } from './baseApi.service';
import { UserPasswordChangeModelRequest } from '../../model/password.model';
import { PersonalInfoUpdate } from '../../page/commons/user/user.component';
import { UserManagementQueryParameter } from '../../model/user-management.model';

@Injectable()
export class UserService extends BaseApiService {
	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/authenticate'
	})
	login: IResourceMethodPromise<LoginAuthRequestModel, LoginAuthResponseModel>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/Authentication/GetUserInfos?username={!mail}'
	})
	getUserInfo: IResourceMethodPromise<{ mail: string }, any>;

	@ResourceAction({
		method: ResourceRequestMethod.Patch,
		path: '/users/{!id}/activate-account/{!guid}'
	})
	activateAccount: IResourceMethodPromiseStrict<
		{ password: string },
		void,
		UserActivateAccountRequestModel,
		RestBaseResponse<void>
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/users/{!id}/detail',
	})
	getUserDetail: IResourceMethodPromise<RestBasePk, RestBaseResponse<UserDetailResponseModel>>;

	@ResourceAction({
		method: ResourceRequestMethod.Patch,
		path: '/users/{!id}/detail'
	})
	updateUserDetail: IResourceMethodPromise<PersonalInfoUpdate, RestBaseResponse<UserDetailResponseModel>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/users/{!id}/password/change'
	})
	passwordChange: IResourceMethodPromiseStrict<
		UserPasswordChangeModelRequest,
		void,
		RestBasePk,
		RestBaseResponse<void>
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/users/{!id}/password/update'
	})
	passwordUpdate: IResourceMethodPromiseStrict<
		{ password: string, old_password: string },
		void,
		RestBasePk,
		RestBaseResponse<void>
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/users/{!id}/password/reset'
	})
	passwordReset: IResourceMethodPromise<RestBasePk, RestBaseResponse<void>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/users/password/recovery'
	})
	passwordRecovery: IResourceMethodPromise<{ email: string }, RestBaseResponse<void>>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/verify-guid/{!email}/{!guid}'
	})
	verifyGuidEmail: IResourceMethodPromise<
		VerifyGuidEmailRequestModel,
		RestBaseResponse<VerifyGuidEmailResponseModel>
	>;

	@ResourceAction({
		method: ResourceRequestMethod.Get,
		path: '/users/detail'
	})
	getUserList: IResourceMethodPromise<UserManagementQueryParameter, RestBaseResponse<UserDetailResponseModel[]>>;

	@ResourceAction({
		method: ResourceRequestMethod.Post,
		path: '/users/create-with-permission'
	})
	postUserList: IResourceMethodPromise<RestPostUserListPayload, RestBaseResponse<[]>>;
}
