import { Route } from '@angular/router';

import { CompanyAgentComponent } from '../page/agent/company-agent/company-agent.component';
import {
    CompanyDetailAgentComponent,
} from '../page/agent/company-agent/company-detail-agent/company-detail-agent.component';
import { ContractAgentComponent } from '../page/agent/contract-agent/contract-agent.component';
import {
    ContractDetailAgentComponent,
} from '../page/agent/contract-agent/contract-detail-agent/contract-detail-agent.component';
import { UserAgentComponent } from '../page/agent/user-agent/user-agent.component';
import {
    CompanyDetailBankComponent,
} from '../page/commons/company/company-detail/company-detail-bank/company-detail-bank.component';
import {
    CompanyDetailDestinationComponent,
} from '../page/commons/company/company-detail/company-detail-destination/company-detail-destination.component';
import {
    CompanyDetailFullDetailComponent,
} from '../page/commons/company/company-detail/company-detail-full-detail/company-detail-full-detail.component';
import {
    CompanyDetailOperationalHeadquarterComponent,
} from '../page/commons/company/company-detail/company-detail-operational-headquarter/company-detail-operational-headquarter.component';
import { PermissionKeyEnum } from './permission-key.enum';

export namespace AgentRouteList {
	export const routes: Route[] = [
		{
			path: '',
			redirectTo: 'companies',
			data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
			pathMatch: 'full'
		},
		{
			path: 'user',
			component: UserAgentComponent,
			data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
			pathMatch: 'full'
		},
		{
			path: 'companies',
			data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
			children: [
				{
					path: '',
					data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
					component: CompanyAgentComponent
				},
				{
					path: ':idCompany',
					component: CompanyDetailAgentComponent,
					data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
					children: [
						{
							path: '',
							redirectTo: 'details',
							data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
							pathMatch: 'full'
						},
						{
							path: 'details',
							component: CompanyDetailFullDetailComponent,
							data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
							pathMatch: 'full'
						},
						{
							path: 'destinations',
							component: CompanyDetailDestinationComponent,
							data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
							pathMatch: 'full'
						},
						{
							path: 'bank',
							component: CompanyDetailBankComponent,
							data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
							pathMatch: 'full'
						},
						{
							path: 'operational_headquarter',
							component: CompanyDetailOperationalHeadquarterComponent,
							data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
							pathMatch: 'full'
						}
					]
				}
			]
		},
		{
			path: 'contracts',
			data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
			children: [
				{
					path: ':contractStatus',
					component: ContractAgentComponent,
					data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
					children: []
				},
				{
					path: ':contractStatus/:contractId',
					component: ContractDetailAgentComponent,
					data: {key: PermissionKeyEnum.AGENT_DASHBOARD},
					children: []
				}
			]
		},


	];
}
