import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RestBasePk } from '../../model/rest-base.model';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { PouchDeleteResponse, PouchErrorResponse } from '../../service/pouchdb/model/pouch-base-response.model';
import { ContractActionEnum, ContractStateAction } from './contract.actions';
import { ContractStateModel } from '../../model/state/contract-state.model';

@Injectable()
export class ContractEffects {

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractActionEnum.LOAD),
			mergeMap((action: BaseStateModel<RestBasePk>) => from(this.getContractDetail(action.data))),
			map((user: BaseStateModel<ContractStateModel>) => ContractStateAction.update(user)),
			catchError((error, caught) => {
				this.store.dispatch(ContractStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractActionEnum.SAVE),
			mergeMap((action: BaseStateModel<ContractStateModel>) =>
				from(this.saveContractDetail(action.data))
			),
			map((contract: BaseStateModel<ContractStateModel>) => ContractStateAction.update(contract)),
			catchError((error, caught) => {
				this.store.dispatch(ContractStateAction.error(null));
				return caught;
			})
		)
	);

	remove$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractActionEnum.REMOVE),
			mergeMap((action: BaseStateModel<ContractStateModel>) => from(this.deleteContract(action.data))),
			map((contract: BaseStateModel<PouchDeleteResponse>) => ContractStateAction.removed(contract)),
			catchError((error, caught) => {
				this.store.dispatch(ContractStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getContractDetail(data: RestBasePk): Promise<BaseStateModel<ContractStateModel>> {
		return this.pouchDbMobeeAgentiAdapter.contractPouch
			.getContractDetail(data)
			.then(async (contract: ContractStateModel) => {
				return new BaseState(new ContractStateModel(contract));
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async saveContractDetail(data: ContractStateModel): Promise<BaseStateModel<ContractStateModel>> {
		return this.pouchDbMobeeAgentiAdapter.contractPouch
			.putContract(data, data._id ? true : false)
			.then(async contract => {
				return new BaseState(contract);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async deleteContract(data: ContractStateModel): Promise<BaseStateModel<PouchDeleteResponse>> {
		return this.pouchDbMobeeAgentiAdapter.contractPouch
			.deleteContract(data)
			.then(async contract => {
				return new BaseState(contract);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
