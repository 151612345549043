import { Component, OnInit, Input } from '@angular/core';
import { CardTrendConfigurationModel } from '../../model/chart.model';


@Component({
	selector: 'card-trend',
	templateUrl: './card-trend.component.html',
	styleUrls: ['./card-trend.component.scss']
})

export class CardTrendComponent implements OnInit {
	@Input() config: CardTrendConfigurationModel;
	constructor() { }
	ngOnInit() { }
}
