import { Route } from '@angular/router';

import { UserManagementDetailComponent } from '../page/backoffice/user-management/user-management-detail/user-management-detail.component';
import { UserManagementComponent } from '../page/backoffice/user-management/user-management.component';
import { CompanyBackofficeComponent } from '../page/backoffice/company-backoffice/company-backoffice.component';
import {
    CompanyDetailBackofficeComponent,
} from '../page/backoffice/company-backoffice/company-detail-backoffice/company-detail-backoffice.component';
import { ContractBackofficeComponent } from '../page/backoffice/contract-backoffice/contract-backoffice.component';
import {
    ContractDetailBackofficeComponent,
} from '../page/backoffice/contract-backoffice/contract-detail-backoffice/contract-detail-backoffice.component';
import { FleetComponent } from '../page/backoffice/fleet/fleet.component';
import { UserBackofficeComponent } from '../page/backoffice/user-backoffice/user-backoffice.component';
import {
    CompanyDetailBankComponent,
} from '../page/commons/company/company-detail/company-detail-bank/company-detail-bank.component';
import {
    CompanyDetailDestinationComponent,
} from '../page/commons/company/company-detail/company-detail-destination/company-detail-destination.component';
import {
    CompanyDetailFullDetailComponent,
} from '../page/commons/company/company-detail/company-detail-full-detail/company-detail-full-detail.component';
import {
    CompanyDetailOperationalHeadquarterComponent,
} from '../page/commons/company/company-detail/company-detail-operational-headquarter/company-detail-operational-headquarter.component';
import { PermissionKeyEnum } from './permission-key.enum';

export namespace BackofficeRouteList {
	export const routes: Route[] = [
		{
			path: '',
			redirectTo: 'companies',
			pathMatch: 'full',
			data: {key: PermissionKeyEnum.BO_DASHBOARD}
		},
		{
			path: 'companies',
			data: {key: PermissionKeyEnum.BO_DASHBOARD},
			children: [
				{
					path: '',
					component: CompanyBackofficeComponent,
					data: {key: PermissionKeyEnum.BO_DASHBOARD}
				},
				{
					path: ':idCompany',
					component: CompanyDetailBackofficeComponent,
					data: {key: PermissionKeyEnum.BO_DASHBOARD},
					children: [
						{
							path: '',
							redirectTo: 'details',
							data: {key: PermissionKeyEnum.BO_DASHBOARD},
							pathMatch: 'full'
						},
						{
							path: 'details',
							component: CompanyDetailFullDetailComponent,
							data: {key: PermissionKeyEnum.BO_DASHBOARD},
							pathMatch: 'full'
						},
						{
							path: 'destinations',
							component: CompanyDetailDestinationComponent,
							data: {key: PermissionKeyEnum.BO_DASHBOARD},
							pathMatch: 'full'
						},
						{
							path: 'bank',
							component: CompanyDetailBankComponent,
							data: {key: PermissionKeyEnum.BO_DASHBOARD},
							pathMatch: 'full'
						},
						{
							path: 'operational_headquarter',
							component: CompanyDetailOperationalHeadquarterComponent,
							data: {key: PermissionKeyEnum.BO_DASHBOARD},
							pathMatch: 'full'
						}
					]
				}
			]
		},

		{
			path: 'user',
			component: UserBackofficeComponent,
			pathMatch: 'full',
			data: {key: PermissionKeyEnum.BO_DASHBOARD}
		},
		{
			path: 'fleet',
			component: FleetComponent,
			data: {key: PermissionKeyEnum.BO_DASHBOARD},
			pathMatch: 'full'
		},
		// TODO: modificare la naming conventio in modo da indicare più chiaramente che il componente
		// gestisce sempre una lista di utenti ma filtata per context_application
		{
			path: 'user-management/:contextApplicationItemCodeKey',
			component: UserManagementComponent,
			data: {key: PermissionKeyEnum.BO_DASHBOARD}
		},
		{
			path: 'user-management/detail/:idUser',
			component: UserManagementDetailComponent,
			data: {key: PermissionKeyEnum.BO_DASHBOARD},
			pathMatch: 'full'
		},
		{
			path: 'contracts',
			data: {key: PermissionKeyEnum.BO_DASHBOARD},
			children: [
				{
					path: ':contractStatus',
					component: ContractBackofficeComponent,
					data: {key: PermissionKeyEnum.BO_DASHBOARD},
					children: []
				},
				{
					path: ':contractStatus/:contractId',
					component: ContractDetailBackofficeComponent,
					data: {key: PermissionKeyEnum.BO_DASHBOARD},
					children: []
				}
			]
		}
	];
}
