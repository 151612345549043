import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { RestBasePk } from '../../model/rest-base.model';
import { PouchDeleteResponse } from '../../service/pouchdb/model/pouch-base-response.model';
import { ContractPouchModel, ExtraFieldContractHeaderPouchModel } from '../../model/state/contract-state.model';

export enum ContractActionEnum {
	UPDATE = '[contract] Update',
	LOAD = '[contract] Load',
	SAVE = '[contract] Save',
	REMOVE = '[contract] Remove',
	REMOVED = '[contract] Removed',
	COMPLETED = '[contract] Completed',
	RESET = '[contract] Reset',
	ERROR = '[contract] Error'
}

export namespace ContractStateAction {
	export const load = createAction(ContractActionEnum.LOAD, props<BaseStateModel<RestBasePk>>());
	export const update = createAction(
		ContractActionEnum.UPDATE,
		props<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>>>()
	);
	export const save = createAction(
		ContractActionEnum.SAVE,
		props<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>>>()
	);
	export const remove = createAction(
		ContractActionEnum.REMOVE,
		props<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>>>()
	);
	export const removed = createAction(ContractActionEnum.REMOVED, props<BaseStateModel<PouchDeleteResponse>>());
	export const completed = createAction(ContractActionEnum.COMPLETED, props<BaseStateModel<any>>());
	export const reset = createAction(ContractActionEnum.RESET);
	export const error = createAction(ContractActionEnum.ERROR, props<BaseStateModel<any>>());
}
