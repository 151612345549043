import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { BaseStateModel } from '../../../../model/state/base-state.model';
import { Filters } from '../../../../model/structure/list-structure.model';
import { ListDataPouchModel, BaseAs400PouchModel } from '@saep-ict/pouch_agent_models';
import { BodyTablePouchCustomModel } from '../../model/pouch-base.model';

export class BackofficePouch extends AbstractPouchdbMethod {

	getBackoffice(backofficeCode: string): Promise<BodyTablePouchCustomModel> {
		return new Promise((resolve, reject) => {
			this.get('backoffice_' + backofficeCode)
				.then((doc: BodyTablePouchCustomModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getBackofficeCodeList(data: BaseStateModel<BodyTablePouchCustomModel>): Promise<BodyTablePouchCustomModel[]> {
		return new Promise(resolve => {
			const selector = {
				$and: [
					{
						type: 'backoffice_user'
					}
				]
			} as PouchDB.Find.Selector;

			const filters: Filters = data.filters;
			if (filters) {
				if (filters.exclude_code) {
					const s = {
						code_item: {
							$nin: filters.exclude_code
						}
					};
					selector.$and.push(s);
				}
				if (filters.text) {
					const s = {
						$or: [
							{
								code_item: {
									$regex: `(?i)${filters.text}`
								}
							},
							{
								description: {
									$regex: `(?i)${filters.text}`
								}
							},
							{
								description_short: {
									$regex: `(?i)${filters.text}`
								}
							}
						]
					};
					selector.$and.push(s);
				}
			}
			this.getWithIndexFilter('backoffice-code-list', selector, data.pagination, data.sort)
				.then((res: ListDataPouchModel<BodyTablePouchCustomModel>) => {
					resolve(res.docs);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

}
