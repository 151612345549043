import { BaseStatusBarConfigService } from '../base-status-bar-config.service';
import { ContractStatusIconEnum, ContractStatusEnum } from '../../../enum/contract-status.enum';

export class StatusBarOrderService extends BaseStatusBarConfigService {
	constructor() {
		super();
		this.config = {
			condensed: true,
			theme: 'primary',
			steps: [
				{
					id: ContractStatusEnum.DRAFT,
					label: 'Bozza',
					icon: ContractStatusIconEnum.DRAFT
				},
				{
					id: ContractStatusEnum.TO_CONFIRM,
					label: 'Da confermare',
					icon: ContractStatusIconEnum.TO_CONFIRM

				},
				{
					id: ContractStatusEnum.COMPLETED,
					label: 'Completato',
					icon: ContractStatusIconEnum.COMPLETED
				}
			]
		};
	}

	// TODO-MOBEE
}
