import { createAction, props } from "@ngrx/store";
import { BaseStateModel } from "../../model/state/base-state.model";
import { DestinationPouchModel } from "@saep-ict/pouch_agent_models";
import { DestinationFilterModel } from "../../service/pouchdb/filter/destination-filter.model";

export enum DestinationActionEnum {
    UPDATE = '[destination] Update',
    LOAD = '[destination] Load',
    COMPLETED = '[destination] Completed',
    RESET = '[destination] Reset',
    ERROR = '[destination] Error'
}

export namespace DestinationStateAction{
    export const load = createAction(
        DestinationActionEnum.LOAD,
        props<BaseStateModel<DestinationFilterModel>>()
    );
    export const update = createAction(
        DestinationActionEnum.UPDATE,
        props<BaseStateModel<DestinationPouchModel>>()
    );
    export const completed = createAction(
        DestinationActionEnum.COMPLETED,
        props<BaseStateModel<any>>()
    );
    export const reset = createAction(
        DestinationActionEnum.RESET
    );
    export const error = createAction(
        DestinationActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
