import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'person-card',
  templateUrl: './person-card.component.html',
  styleUrls: ['./person-card.component.scss']
})
export class PersonCardComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() personImage: string;
  @Input() isActiveIndicator: boolean = null;

  constructor() {}

  ngOnInit() {
  }

}
