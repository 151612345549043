import { BaseStateModel } from '../../model/state/base-state.model';
import { createReducer, on } from '@ngrx/store';
import { ContractStateAction, ContractActionEnum } from './contract.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { ContractPouchModel, ExtraFieldContractHeaderPouchModel } from '../../model/state/contract-state.model';

export namespace ContractStateReducer {
	export const initialState: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>> = null;

	const _reducer = createReducer(
		initialState,
		on(ContractStateAction.load, state => state),
		on(ContractStateAction.update, (state, contract) => contract),
		on(ContractStateAction.save, state => state),
		on(ContractStateAction.remove, (state, contract) => ({ data: state ? state.data : null, type: contract.type })),
		on(ContractStateAction.removed, (state, contractRemoved) => contractRemoved),
		on(ContractStateAction.completed, (state, contract) => ({ data: state ? state.data : null, type: contract.type })),
		on(ContractStateAction.reset, () => initialState),
		on(ContractStateAction.error, (state, contract) => ({ data: state ? state.data : null, type: contract.type }))
	);

	export function reducer(
		state: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>>,
		action: TypedAction<ContractActionEnum
	>
	) {
		return _reducer(state, action);
	}
}
