import { Injectable } from '@angular/core';
import { PouchDbAdapter } from '@saep-ict/pouch-db';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CustomerAppConfig } from '../../../customer-app.config';
import { LoaderService } from '../../structure/loader.service';
import { AgentCustomerPouch } from './pouch-function/agent-customer.pouch';
import { AgentPouch } from './pouch-function/agent.pouch';
import { AuxiliaryTablePouch } from './pouch-function/auxiliary-table.pouch';
import { CustomerPouch } from './pouch-function/customer.pouch';
import { DestinationPouch } from './pouch-function/destination.pouch';
import { OrderStatisticsPouch } from './pouch-function/order-statistics.pouch';
import { HttpClient } from '@angular/common/http';
import { ContractPouch } from './pouch-function/contract.pouch';
import { AgentStatisticsPouch } from './pouch-function/agent-statistics.pouch';
import { MopedPouch } from './pouch-function/moped.pouch';
import { BasePouch } from './pouch-function/base.pouch';
import { BackofficePouch } from './pouch-function/backoffice.pouch';

@Injectable()
export class PouchDbMobeeAgentiAdapter extends PouchDbAdapter {
	database = 'mobee';
	filteredOptions: any = {};
	basePouch: BasePouch;
	backofficePouch: BackofficePouch;
	agentPouch: AgentPouch;
	customerPouch: CustomerPouch;
	auxiliaryTablePouch: AuxiliaryTablePouch;
	destinationPouch: DestinationPouch;
	agentCustomerPouch: AgentCustomerPouch;
	agentStatisticsPouch: AgentStatisticsPouch;
	contractPouch: ContractPouch;
	mopedPouch: MopedPouch;
	// TODO - da eliminare
	orderStatisticsPouch: OrderStatisticsPouch;

	constructor(private config: CustomerAppConfig, private httpClient: HttpClient, private loaderService: LoaderService, deviceDetectorService: DeviceDetectorService) {
		super(config, deviceDetectorService);
	}

	async initFunctions() {
		this.basePouch = new BasePouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.agentPouch = new AgentPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.customerPouch = new CustomerPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.auxiliaryTablePouch = new AuxiliaryTablePouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.destinationPouch = new DestinationPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.agentCustomerPouch = new AgentCustomerPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.agentStatisticsPouch = new AgentStatisticsPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.contractPouch = new ContractPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.mopedPouch = new MopedPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		// TODO - da eliminare
		this.orderStatisticsPouch = new OrderStatisticsPouch(this.getDB(), this.httpClient, this.config, this.loaderService);
		this.backofficePouch = new BackofficePouch(this.getDB(), this.httpClient, this.config, this.loaderService);
	}
}
