import { BaseStateModel } from '../../model/state/base-state.model';
import { createReducer, on } from '@ngrx/store';
import { ContractListStateAction, ContractListActionEnum } from './contract-list.actions';
import { TypedAction } from '@ngrx/store/src/models';
import { ContractPouchModel, ExtraFieldContractHeaderPouchModel } from '../../model/state/contract-state.model';

export namespace ContractListStateReducer {
	export const initialState: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]> = null;

	const _reducer = createReducer(
		initialState,
		on(ContractListStateAction.load, state => state),
		on(ContractListStateAction.loadWithDetail, state => state),
		on(ContractListStateAction.update, (state, contract) => contract),
		on(ContractListStateAction.completed, (state, contract) => ({
			data: state ? state.data : null,
			type: contract.type
		})),
		on(ContractListStateAction.reset, () => initialState),
		on(ContractListStateAction.error, (state, contract) => ({
			data: state ? state.data : null,
			type: contract.type
		}))
	);

	export function reducer(
		state: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>,
		action: TypedAction<ContractListActionEnum>
	) {
		return _reducer(state, action);
	}
}
