import { createReducer, on } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { TypedAction } from '@ngrx/store/src/models';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerListStateAction, CustomerListActionEnum } from './customer-list.actions';

export namespace CustomerListStateReducer {
	export const initialState: BaseStateModel<CustomerPouchModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(CustomerListStateAction.load, state => state),
		on(CustomerListStateAction.update, (state, customerList) => customerList),
		on(CustomerListStateAction.completed, (state, customer) => ({
			data: state ? state.data : null,
			type: customer.type
		})),
		on(CustomerListStateAction.reset, () => initialState),
		on(CustomerListStateAction.error, (state, customer) => ({
			data: state ? state.data : null,
			type: customer.type
		}))
	);

	export function reducer(
		state: BaseStateModel<CustomerPouchModel[]>,
		action: TypedAction<CustomerListActionEnum>
	) {
		return _reducer(state, action);
	}
}
