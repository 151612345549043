import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { AgentStateModel, AgentCreateFullPipePayload } from '../../model/state/agent-state.model';
import { BodyTablePouchCustomModel } from '../../service/pouchdb/model/pouch-base.model';
export enum AgentActionEnum {
	UPDATE = '[agent] Update',
	LOAD = '[agent] Load',
	RESET = '[agent] Reset',
	ERROR = '[agent] Error',
}

export namespace AgentStateAction {
	export const load = createAction(AgentActionEnum.LOAD, props<BaseStateModel<BasePouchModel>>());
	export const update = createAction(AgentActionEnum.UPDATE, props<BaseStateModel<AgentStateModel>>());
	export const reset = createAction(AgentActionEnum.RESET);
	export const error = createAction(AgentActionEnum.ERROR, props<BaseStateModel<any>>());
}
