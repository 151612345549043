import { Injectable } from "@angular/core";
import { ITdDataTableColumn } from "@covalent/core";

@Injectable()
export abstract class BaseTableListService {
  protected _columns: ITdDataTableColumn[];

  constructor() {}

  public set columns(col: ITdDataTableColumn[]){
    this._columns = col;
  }

  public get columns() {
    return this._columns;
  }
}
