import { Filters } from '../structure/list-structure.model';
export interface BaseStateModel<T> {
	data: T;
	type?: string;
	pagination?: Pagination;
	sort?: { [key: string]: 'asc' | 'desc' }[];
	filters?: Filters; // TODO: aggiornamento del BaseState per inclusione oggetti di query
}

export interface Pagination {
	page_current: number;
	page_size: number;
	total_element?: number;
}

export class BaseState<T> {
	data: T;
	type?: string;
	pagination?: Pagination;
	sort?: { [key: string]: 'asc' | 'desc' }[];

	constructor(innerData?: T, pagination?: Pagination, sort?: { [key: string]: 'asc' | 'desc' }[]) {
		this.data = innerData ? innerData : null;
		this.pagination = pagination ? pagination : null;
		this.sort = sort ? sort : null;
	}
}
