// model
import { BaseStateModel } from '../../../model/state/base-state.model';
import { UserDetailModel } from '../../../model/user.model';

// store
import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { UserManagementStateAction, UserManagementStateActionEnum } from './user-management.actions';

export namespace UserManagementStateReducer {
	export const initialState: BaseStateModel<UserDetailModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(UserManagementStateAction.loadList, state => state),
		on(UserManagementStateAction.loadDetail, state => state),
		on(UserManagementStateAction.update, (state, param) => param),
		on(UserManagementStateAction.saveListSuccess, (state, param) => param),
		on(UserManagementStateAction.reset, () => initialState),
		on(UserManagementStateAction.error, (state, param) => ({ data: state ? state.data : null, type: param.type }))
	);

	export function reducer(
		state: BaseStateModel<UserDetailModel[]>,
		action: TypedAction<UserManagementStateActionEnum>
	) {
		return _reducer(state, action);
	}
}
