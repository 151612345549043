import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { AgentStateModel } from '../../model/state/agent-state.model';
import { BaseStateModel } from '../../model/state/base-state.model';
import { AgentActionEnum, AgentStateAction } from './agent.actions';

export namespace AgentStateReducer {
	export const initialState: BaseStateModel<AgentStateModel> = null;

	const _reducer = createReducer(
		initialState,
		on(AgentStateAction.load, state => state),
		on(AgentStateAction.update, (state, agent) => agent),
		on(AgentStateAction.reset, () => initialState),
		on(AgentStateAction.error, (state, agent) => ({ data: state ? state.data : null, type: agent.type }))
	);

	export function reducer(state: BaseStateModel<AgentStateModel>, action: TypedAction<AgentActionEnum>) {
		return _reducer(state, action);
	}
}
