import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ITdDataTableColumn, ITdDataTableSortChangeEvent } from '@covalent/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Pagination } from '@saep-ict/pouch-db';
import { Observable } from 'rxjs';
import { filter, map, mergeMap } from 'rxjs/operators';
import { ContextApplicationItemCodeEnum } from '../../../../enum/context-application-item-code.enum';
import { ContractStatusEnum } from '../../../../enum/contract-status.enum';
import {
	AgentCreateFullPipePayload,
	AgentStateModel,
	AgentStatisticsModel,
	FranchiserModel
} from '../../../../model/state/agent-state.model';
import { BaseState, BaseStateModel } from '../../../../model/state/base-state.model';
import {
	ContractPouchModel,
	ContractStateModel,
	ExtraFieldContractHeaderPouchModel
} from '../../../../model/state/contract-state.model';
import { UserDetailModel } from '../../../../model/user.model';
import { ContractListFilterModel, DateFilterModel } from '../../../../service/pouchdb/filter/contract-filter.model';
import { AgentDetailContractListColumnService } from '../../../../service/td-data-table/implementation/contract-list.service';
import { DateMomentService } from '../../../../service/util/date-moment.service';
import { PermissionUtilService } from '../../../../service/util/permission-util.service';
import { SubscribeManagerService } from '../../../../service/util/subscribe-manager.service';
import { UtilContractService } from '../../../../service/util/util-contract.service';
import { UtilCustomerService } from '../../../../service/util/util-customer.service';
import { StateFeature } from '../../../../state';
import { AgentListActionEnum, AgentListStateAction } from '../../../../state/agent-list/agent-list.actions';
import {
	UserManagementStateAction,
	UserManagementStateActionEnum
} from '../../../../state/backoffice/user-management/user-management.actions';
import { ContractListActionEnum, ContractListStateAction } from '../../../../state/contract-list/contract-list.actions';
import { DialogConfirmComponent } from '../../../../widget/dialog/dialog-confirm/dialog-confirm.component';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { UtilService } from '../../../../service/util/util.service';

@Component({
	selector: 'user-management-detail',
	templateUrl: './user-management-detail.component.html',
	styleUrls: ['./user-management-detail.component.scss'],
	providers: [AgentDetailContractListColumnService, SubscribeManagerService]
})
export class UserManagementDetailComponent implements OnInit, OnDestroy {
	editMode = false;
	contractState: string;

	userManagementState$ = this.store.select(StateFeature.getUserManagementState);
	agentListState$ = this.store.select(StateFeature.getAgentListState);
	backofficeCodeListState$ = this.store.select(StateFeature.getBackofficeCodeList);

	contractList$: Observable<
		BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>
	> = this.store.select(StateFeature.getContractListState);
	contractList: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>;
	contractFilters: BaseStateModel<ContractListFilterModel>;

	// TODO: se la pagina fungerà esclusivamente per la modifica, eliminare il controllo
	user = <UserDetailModel>{
		id: this.route.snapshot.paramMap.get('idUser') ? this.route.snapshot.paramMap.get('idUser') : null
	};

	franchiser: FranchiserModel;
	agent: AgentStateModel;

	// filteredCustomerList: Observable<AgentCustomerPouchModel[]>;

	formUser: FormGroup;
	formFranchiser: FormGroup;

	contractListFilterForm: FormGroup;

	columns: ITdDataTableColumn[];
	minHeight: string;
	idUser: string;
	agentStats: AgentStatisticsModel;

	/**
	 * Viene usato per creare il filtro nel contesto company detail
	 */
	get contractStates() {
		return Object.keys(ContractStatusEnum).filter(
			status => status !== ContractStatusEnum.DELETED && status !== ContractStatusEnum.DRAFT
		);
	}

	constructor(
		private store: Store<any>,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private fb: FormBuilder,
		public agentDetailContractListColumnService: AgentDetailContractListColumnService,
		private dateMomentService: DateMomentService,
		public utilContractService: UtilContractService,
		private subscribeManagerService: SubscribeManagerService,
		public utilCustomerService: UtilCustomerService,
		private permissionUtilService: PermissionUtilService,
		private dialog: MatDialog,
		private snackBar: MatSnackBar,
		private utilService: UtilService
	) {
		this.idUser = this.route.snapshot.paramMap.get('idUser');
		this.createFormFilters();
		this.createFormUser();
		this.createFormFranchiser();
		this.subscribeManagerService.populate(this.subscribeUserManagementState().subscribe(), 'user-management-state');
		this.subscribeManagerService.populate(this.subscribeAgentListState().subscribe(), 'agent-list-state');

		if (this.user.id) {
			this.store.dispatch(UserManagementStateAction.loadDetail({ id: this.user.id }));
		}
	}

	subscribeUserManagementState(): Observable<UserDetailModel> {
		return this.userManagementState$.pipe(
			filter((state: BaseStateModel<UserDetailModel[]>) => state && state.data && state.data.length > 0),
			map((state: BaseStateModel<UserDetailModel[]>) => {
				if (state.type === UserManagementStateActionEnum.UPDATE) {
					this.user = state.data[0];
					this.setValueFromRemoteUser();

					if (this.user.context_application_list && this.user.context_application_list.length > 0) {
						// su questa declinazione di progetto diamo per scontato che ci sia
						// un solo agente associato all'utente
						const context_application_agent = this.permissionUtilService.getContextApplicationItem(
							this.user.context_application_list,
							ContextApplicationItemCodeEnum.AGENT
						);
						if (
							context_application_agent &&
							context_application_agent.context_code_list &&
							context_application_agent.context_code_list.length > 0
						) {
							const query = <BaseStateModel<AgentCreateFullPipePayload>>{
								data: null,
								filters: {
									id: context_application_agent.context_code_list[0].code
								}
							};
							this.store.dispatch(AgentListStateAction.loadDetailWithFranchiser(query));
						}
					}
				}

				if (state.type === UserManagementStateActionEnum.SAVE_LIST_SUCCESS) {
					this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
						duration: 5000,
						data: {
							message: `Utente salvato correttamente`
						}
					});
				}

				// console.log(this.user);
				return this.user;
			})
		);
	}

	// TODO: valutare se bonificare o deprecare lo store dedicato al dettaglio agente
	// per ora le nuove dinamiche vengono appoggiate sullo store di agent-list
	subscribeAgentListState(): Observable<FranchiserModel> {
		return this.agentListState$.pipe(
			filter(
				(state: BaseStateModel<AgentCreateFullPipePayload[]>) => state && state.data && state.data.length > 0
			),
			mergeMap((state: BaseStateModel<AgentCreateFullPipePayload[]>) => {
				// console.log(state);

				if (state.type === AgentListActionEnum.UPDATE) {
					// TODO: una volta ridefinita la modellazione dell'agent (extends di BodyTablePouchCustomModel avente custom_field.franchiser)
					// ovunque si potrà deprecare l'oggetto franchiser
					this.agent = state.data[0];
					this.franchiser = state.data[0].custom_field.franchiser;
					this.agentStats = state.data[0].custom_field.stats;
					this.setValueFromRemoteFranchiser();

					this.resetContractFormFilter();
					this.contractFilters.pagination = this.getInitialPagination();
				}
				return this.contractList$;
			}),
			filter(
				(contractList: BaseStateModel<ContractStateModel[]>) =>
					contractList && contractList.type !== ContractListActionEnum.LOAD_WITH_DETAIL
			),
			map((contractList: BaseStateModel<ContractStateModel[]>) => {
				if (contractList.type === ContractListActionEnum.UPDATE) {
					// console.log(contractList.data);
					this.contractList = contractList;
					return this.contractList;
				}
			})
		);
	}

	// _filterCompanyNames(value: string): AgentCustomerPouchModel[] {
	// 	const filterValue = value.toLowerCase();

	// 	return this.agentCustomer.data.values
	// 		.filter(company => company.business_name.toLowerCase().includes(filterValue))
	// 		.sort((a, b) => (a.business_name < b.business_name ? -1 : 1));
	// }

	createFormFilters() {
		this.contractListFilterForm = this.fb.group({
			date: [''],
			business_name: [''],
			contract_states: ['']
		});
	}

	createFormUser() {
		this.formUser = this.fb.group({
			username: [null, { disabled: false, validators: [Validators.required, Validators.email] }],
			first_name: [null, { disabled: false, validators: Validators.required }],
			last_name: [null, { disabled: false, validators: Validators.required }]
		});
		this.formUser.disable({ emitEvent: false });
	}

	createFormFranchiser() {
		this.formFranchiser = this.fb.group({
			_id: [null],
			_rev: [null],
			type: ['franchiser'],
			code_item: [null],
			business_name: [null, { disabled: false, validators: Validators.required }],
			phone: [null, { disabled: false, validators: Validators.required }],
			email_pec: [null, { disabled: false, validators: [Validators.required, Validators.email] }],
			legal_representative: [null, { disabled: false, validators: Validators.required }],
			vat_number: [null, { disabled: false, validators: [Validators.required, Validators.minLength(11)] }],
			rea_number: [null, { disabled: false, validators: Validators.required }],
			address: [null, { disabled: false, validators: Validators.required }],
			zip_code: [null, { disabled: false, validators: [Validators.required, Validators.minLength(5)] }],
			locality: [null, { disabled: false, validators: Validators.required }],
			province: [null, { disabled: false, validators: [Validators.required, Validators.minLength(2)] }]
		});
		this.formFranchiser.disable({ emitEvent: false });
	}

	setValueFromRemoteUser() {
		this.formUser.setValue(
			{
				username: this.user.username ? this.user.username : null,
				first_name: this.user.first_name ? this.user.first_name : null,
				last_name: this.user.last_name ? this.user.last_name : null
			},
			{ emitEvent: false }
		);
	}

	setValueFromRemoteFranchiser() {
		this.formFranchiser.setValue(
			{
				_id: this.franchiser._id ? this.franchiser._id : null,
				_rev: this.franchiser._rev ? this.franchiser._rev : null,
				type: this.franchiser.type ? this.franchiser.type : null,
				code_item: this.franchiser.code_item ? this.franchiser.code_item : null,
				business_name: this.franchiser.business_name ? this.franchiser.business_name : null,
				phone: this.franchiser.phone ? this.franchiser.phone : null,
				email_pec: this.franchiser.email_pec ? this.franchiser.email_pec : null,
				legal_representative: this.franchiser.legal_representative
					? this.franchiser.legal_representative
					: null,
				vat_number: this.franchiser.vat_number ? this.franchiser.vat_number : null,
				rea_number: this.franchiser.rea_number ? this.franchiser.rea_number : null,
				address: this.franchiser.address ? this.franchiser.address : null,
				zip_code: this.franchiser.zip_code ? this.franchiser.zip_code : null,
				locality: this.franchiser.locality ? this.franchiser.locality : null,
				province: this.franchiser.province ? this.franchiser.province : null
			},
			{ emitEvent: false }
		);
	}

	ngOnInit() {
		this.minHeight = window.innerHeight - 200 + 'px';
	}

	paginationChange(pagination: Pagination) {
		this.contractFilters.pagination = pagination;
		this.loadContractList();
	}

	loadContractList() {
		this.store.dispatch(ContractListStateAction.load(this.contractFilters));
	}

	resetContractFilters() {
		const pagination: Pagination = this.contractFilters
			? this.contractFilters.pagination
			: this.getInitialPagination();
		const sort = this.contractFilters ? this.contractFilters.sort : this.getInitialSort();
		const resetFilter: ContractListFilterModel = this.getInitialFilter();
		this.contractFilters = {
			data: resetFilter,
			pagination: pagination,
			sort: sort
		};
		this.loadContractList();
	}

	getInitialFilter(): ContractListFilterModel {
		const status: ContractStatusEnum[] = this.getCurrentContractStatus();
		const initialFilter: ContractListFilterModel = {
			user_code: this.agent.code_item
		};
		if (status) {
			initialFilter.status = status;
		}
		return initialFilter;
	}

	getCurrentContractStatus(): ContractStatusEnum[] {
		return [ContractStatusEnum.COMPLETED, ContractStatusEnum.TO_CONFIRM];
	}

	getInitialPagination(): Pagination {
		const pagination: Pagination = {
			page_current: 1,
			page_size: 10
		};
		return pagination;
	}

	getInitialSort(): { [key: string]: 'asc' | 'desc' }[] {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				'header.date': 'desc'
			}
		];
		return sort;
	}
	sortChange(sortEvent: ITdDataTableSortChangeEvent): void {
		this.contractFilters.sort = this.utilService.getSortChanged(sortEvent);
		this.loadContractList();
	}

	goToContractDetail(selectedContract: ContractPouchModel<ExtraFieldContractHeaderPouchModel>) {
		this.router.navigate([`/contracts/${selectedContract.header.status}/${selectedContract._id}`]);
	}

	submitContractListFilters() {
		// Data creazione
		if (this.contractListFilterForm && this.contractListFilterForm.value.date) {
			const filterDate: DateFilterModel = {
				begin: this.contractListFilterForm.value.date.begin.startOf('day').format('x'),
				end: this.contractListFilterForm.value.date.end.endOf('day').format('x')
			};
			this.contractFilters.data.date = filterDate;
		}

		// Azienda
		// if (this.companyNameCtrl && (this.companyNameCtrl.value || this.companyNameCtrl.value === '')) {
		// 	this.contractFilters.data.business_name = this.companyNameCtrl.value;
		// }

		// Stato contratti
		if (this.contractListFilterForm && this.contractListFilterForm.value.contract_states) {
			this.contractFilters.data.status = this.contractListFilterForm.value.contract_states.map(
				(status: string) => ContractStatusEnum[status]
			);
		}

		this.contractFilters.pagination.page_current = 1;
		this.loadContractList();
	}

	saveBothForm() {
		this.formUserSubmit();
		if (this.franchiser) {
			this.formFranchiserSubmit();
		}
		this.editUserToggle();
	}

	// TODO: eventualmente valutare la parametrizzazione di un unico metodo che faccia i due save separatamente
	formUserSubmit() {
		if (this.formUser.valid) {
			const formModelUser = this.formUser.value;
			const user = {
				first_name: formModelUser.first_name.trim(),
				last_name: formModelUser.last_name.trim(),
				username: formModelUser.username
			};
			this.user.first_name = user.first_name;
			this.user.last_name = user.last_name;
			this.user.username = user.username;

			this.store.dispatch(UserManagementStateAction.saveList(new BaseState([this.user])));
		}
	}

	formFranchiserSubmit() {
		if (this.formFranchiser.value) {
			const agentFranchiserPayload = <BaseStateModel<AgentCreateFullPipePayload>>{
				data: {
					custom_field: {
						franchiser: this.formFranchiser.value
					}
				}
			};
			this.store.dispatch(AgentListStateAction.saveFranchiserDetail(agentFranchiserPayload));
		}
	}

	resetContractFormFilter() {
		this.contractListFilterForm.patchValue({
			date: '',
			business_name: '',
			contract_states: ''
		});

		this.resetContractFilters();
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	convertMillisStringToTime(stringMillis: string) {
		const date = new Date(parseInt(stringMillis, 10));
		const options = { hour: 'numeric', minute: 'numeric' };
		return new Intl.DateTimeFormat('it', options).format(date);
	}

	sendUserActivationEmail() {}

	enableAllForm() {
		this.formUser.enable({ emitEvent: false });
		this.formUser.get('username').disable({ emitEvent: false }); // prevent username modify
		this.formFranchiser.enable({ emitEvent: false });
	}

	disableAllForm() {
		this.formUser.disable({ emitEvent: false });
		this.formFranchiser.disable({ emitEvent: false });
	}

	editUserToggle() {
		this.editMode = !this.editMode;
		if (this.editMode) {
			this.enableAllForm();
		} else {
			this.disableAllForm();
		}
	}

	deleteUser() {
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: "Sei sicuro di voler cancellare l'utente?"
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				// console.log('delete user');
			}
		});
	}

	ngOnDestroy() {
		this.store.dispatch(AgentListStateAction.reset());
		this.store.dispatch(UserManagementStateAction.reset());
		this.store.dispatch(ContractListStateAction.reset());
		this.subscribeManagerService.destroy();
	}
}
