import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { ContractPouchModel, ExtraFieldContractHeaderPouchModel } from '../../model/state/contract-state.model';
import { ContractListFilterModel } from '../../service/pouchdb/filter/contract-filter.model';


export enum ContractListActionEnum {
    UPDATE = '[contract list] Update',
    LOAD = '[contract list] Load',
    LOAD_WITH_DESTINATION = '[contract list] Load With Destination',
    LOAD_WITH_DETAIL = '[contract list] Load With Detail',
    COMPLETED = '[contract list] Completed',
    RESET = '[contract list] Reset',
    ERROR = '[contract list] Error'
}

export namespace ContractListStateAction {
	export const load = createAction(
        ContractListActionEnum.LOAD,
        props<BaseStateModel<ContractListFilterModel>>()
    );
    export const loadWithDestination = createAction(
        ContractListActionEnum.LOAD_WITH_DESTINATION,
        props<BaseStateModel<ContractListFilterModel>>()
    );
    export const loadWithDetail = createAction(
        ContractListActionEnum.LOAD_WITH_DETAIL,
        props<BaseStateModel<ContractListFilterModel>>()
    );
    export const update = createAction(
        ContractListActionEnum.UPDATE,
        props<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>>()
    );
    export const completed = createAction(
        ContractListActionEnum.COMPLETED,
        props<BaseStateModel<any>>()
    );
    export const reset = createAction(
        ContractListActionEnum.RESET
    );
    export const error = createAction(
        ContractListActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
