import { RestBasePk } from './../../model/rest-base.model';
import { createAction, props } from '@ngrx/store';
import { UserDetailModel } from '../../model/user.model';
import { BaseStateModel } from '../../model/state/base-state.model';
import { UserPasswordUpdateModel } from '../../model/password.model';
export enum UserActionEnum {
    UPDATE = '[user] Update',
    LOAD = '[user] Load',
    PASSWORD_CHANGE = '[user] Passwor_Change',
    PASSWORD_RECOVERY = '[user] Passwor_Recovery',
    COMPLETED = '[user] Completed',
    RESET = '[user] Reset',
    ERROR = '[user] Error'
}

export namespace UserStateAction{
    export const load = createAction(
        UserActionEnum.LOAD,
        props<RestBasePk>()
    );
    export const update = createAction(
        UserActionEnum.UPDATE,
        props<BaseStateModel<UserDetailModel>>()
    );
    export const password_change = createAction(
        UserActionEnum.PASSWORD_CHANGE,
        props<BaseStateModel<UserPasswordUpdateModel>>()
    );
    export const password_recovery = createAction(
        UserActionEnum.PASSWORD_RECOVERY,
        props<BaseStateModel<{email: string}>>()
    );
    export const completed = createAction(
        UserActionEnum.COMPLETED,
        props<BaseStateModel<any>>()
    );
    export const reset = createAction(
        UserActionEnum.RESET
    );
    export const error = createAction(
        UserActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
