import { Component, OnInit } from '@angular/core';
import { ContextApplicationItemCodeEnum } from '../../../../enum/context-application-item-code.enum';

@Component({
  selector: 'contract-detail-backoffice',
  templateUrl: './contract-detail-backoffice.component.html',
  styleUrls: ['./contract-detail-backoffice.component.scss']
})
export class ContractDetailBackofficeComponent implements OnInit {

  userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.BACKOFFICE;

  constructor() { }

  ngOnInit() {
  }

}
