import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ITdDataTableColumn, TdDataTableComponent } from '@covalent/core';
import { Store } from '@ngrx/store';
import { AgentCustomerPouchModel, BodyTablePouchModel, CustomerPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseState, BaseStateModel } from '../../../model/state/base-state.model';
import { Pagination } from '../../../model/structure/list-structure.model';
import { CustomerListFilterModel } from '../../../service/pouchdb/filter/customer-filter.model';
import { AgentCustomerListColumnService } from '../../../service/td-data-table/implementation/customer-list.service';
import { UtilContractService } from '../../../service/util/util-contract.service';
import { CustomerStateAction } from '../../../state/customer/customer.actions';
import { DialogRegistryCustomerComponent } from '../../../widget/dialog/dialog-registry-customer/dialog-registry-customer.component';
import { Customer, LocationModel, BankDataModel, Uuid } from '../../../model/state/contract-state.model';
import { RegistryModeEnum } from '../../../enum/registry-mode.enum';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { Observable } from 'rxjs';
import { StateFeature } from '../../../state';
import { AgentStateModel } from '../../../model/state/agent-state.model';
import { take } from 'rxjs/operators';

@Component({
	selector: 'company',
	templateUrl: './company.component.html',
	styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
	@Input() userType: ContextApplicationItemCodeEnum;
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;
	@Output() loadCompanyList: EventEmitter<BaseStateModel<CustomerListFilterModel>> = new EventEmitter();
	@Input() companyList: BaseStateModel<AgentCustomerPouchModel[]> | BaseStateModel<CustomerPouchModel[]>;

	// agent
	agent$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(StateFeature.getAgentState);
	agent: AgentStateModel;

	userTypeEnum = ContextApplicationItemCodeEnum;
	initialFilter: CustomerListFilterModel = {
		companySearchText: ''
	};
	customerFilters: BaseStateModel<CustomerListFilterModel>;
	get companyListColumns(): ITdDataTableColumn[] {
		return this.companyColumnService.columns;
	}

	constructor(
		private store: Store<any>,
		private companyColumnService: AgentCustomerListColumnService,
		private router: Router,
		private dialog: MatDialog,
		private utilContractService: UtilContractService
	) {
		// agent
		this.agent$.pipe(take(1)).subscribe(res => {
			this.agent = res ? res.data : null;
		});
	}

	ngOnInit() {
		this.resetFilter();
	}

	resetFilter() {
		const resetFilter: CustomerListFilterModel = this.customerFilters
			? this.customerFilters.data
			: this.initialFilter;
		const pagination: Pagination = this.customerFilters
			? this.customerFilters.pagination
			: this.getInitialPagination();
		const sort = this.customerFilters ? this.customerFilters.sort : this.getInitialSort();
		this.customerFilters = {
			data: resetFilter,
			pagination: pagination,
			sort: sort
		};
		this.loadCompanyList.emit(this.customerFilters);
	}

	getInitialPagination(): Pagination {
		const pagination: Pagination = {
			page_current: 1,
			page_size: 10
		};
		return pagination;
	}

	getInitialSort(): { [key: string]: 'asc' | 'desc' }[] {
		return [];

		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				'header.date': 'desc'
			}
		];
		return sort;
	}

	goToCompanyDetail(row: AgentCustomerPouchModel) {
		this.router.navigate([`companies/${row.code}`]);
	}

	goToOrderDetail(row: AgentCustomerPouchModel) {
		this.router.navigate(['orders', 'DRAFT', row.code, 'new', 'checkout']);
		event.stopPropagation();
	}

	onTdSearchBox() {
		this.companyList.pagination.page_current = 1;
		this.loadCompanyList.emit(this.customerFilters);
	}

	addCustomer() {
		const newRecord: Customer = new Customer();
		newRecord.address = new LocationModel();
		// add agent code (AGENT context)
		if (this.agent) {
			newRecord.code_agent.push(this.agent.code_item);
		}

		const dialogRef = this.dialog.open(DialogRegistryCustomerComponent, {
			data: {
				title: 'Nuovo cliente',
				context: this.utilContractService.contextDialog.customerOnly,
				customer: newRecord,
				legalHeadquarter: newRecord.address,
				mode: RegistryModeEnum.ADD
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res && res.customer) {
				const newCustomer: Customer = res.customer;
				this.store.dispatch(CustomerStateAction.save(new BaseState(newCustomer)));
				this.loadCompanyList.emit(this.customerFilters);
			}
		});
	}

	paginationChange(pagination: Pagination) {
		this.companyList.pagination = pagination;
		this.loadCompanyList.emit(this.customerFilters);
	}
}
