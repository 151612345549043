import { Injectable } from '@angular/core';
import { LocalStorage } from 'ngx-webstorage';

import { TokenPayload } from './model/login.model';
import { ConfigCouchModel, IPouchAppConfig, IPouchConfigModel } from '@saep-ict/pouch-db';
import { IAppConfig, IConfigModel } from '@saep-ict/env-configurator';
import { CompanyConfigModel, UrlConfigModel, ConfigModel } from './model/structure/environment-config.model';
import { BehaviorSubject } from 'rxjs';
import { ContextApplicationItemCodeEnum } from './enum/context-application-item-code.enum';

@Injectable({
	providedIn: 'root'
})
export class CustomerAppConfig implements IPouchAppConfig<CustomerAppConfigModel>, IAppConfig<CustomerAppConfigModel> {
	@LocalStorage('payload')
	private _tokenPayload: TokenPayload;
	get username(): string {
		return this._tokenPayload && this._tokenPayload.username;
	}
	get signature(): string {
		return this._tokenPayload && this._tokenPayload.signature;
	}
	get config(): CustomerAppConfigModel {
		return this.configSource.getValue();
	}
	set config(val: CustomerAppConfigModel) {
		this.configSource.next(val);
	}
	set tag (val: string){
		const config = this.config;
		config.tag = val;
		this.config = config;
	}
	configSource = new BehaviorSubject<CustomerAppConfigModel>(undefined as CustomerAppConfigModel);
	config$ = this.configSource.asObservable();
	populateConfig(configuration: CustomerAppConfigModel) {
		this.config = configuration;
	}
}

export class CustomerAppConfigModel implements IPouchConfigModel, IConfigModel, ConfigModel {
	couch: ConfigCouchModel[];
	company: CompanyConfigModel;
	permissionContext: ContextApplicationItemCodeEnum[];
	environment: string;
	urlConfig: UrlConfigModel;
	tag: string;
}
