import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorage, LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';

import { TokenPayload } from '../../model/login.model';
import { RestBaseMessageError } from '../../model/rest-base.model';
import { LoginAuthRequestModel, LoginAuthResponseModel } from '../../model/user.model';
import { AgentStateAction } from '../../state/agent/agent.actions';
import { CustomerStateAction } from '../../state/customer/customer.actions';
import { UserStateAction } from '../../state/user/user.actions';
import { MatSnackBarWrapperComponent } from '../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { UserService } from './user.service';
import { AuxiliaryTableStateModel } from '../../model/state/auxiliary-table-list';
import { AuxiliaryTableStateAction } from '../../state/auxiliary-table/auxiliary-table.actions';
import { AgentCustomerStateAction } from '../../state/agent-customer/agent-customer.actions';
import { DestinationListFilterModel } from '../pouchdb/filter/destination-filter.model';
import { DestinationListStateAction } from '../../state/destination-list/destination-list.actions';

@Injectable()
export class AuthService {
	@LocalStorage('authenticationToken')
	authenticationToken: string;
	@LocalStorage('payload')
	private _tokenPayload: TokenPayload;

	private tokenPayloadChange: BehaviorSubject<TokenPayload> = new BehaviorSubject<TokenPayload>(undefined);
	tokenPayloadChange$: Observable<TokenPayload> = this.tokenPayloadChange.asObservable();

	constructor(
		private store: Store<any>,
		private router: Router,
		private localStorageService: LocalStorageService,
		private userService: UserService,
		public translate: TranslateService,
		public snackBar: MatSnackBar
	) {}

	login(loginAuthRequest: LoginAuthRequestModel) {
		this.userService
			.login(loginAuthRequest)
			.then((res: LoginAuthResponseModel) => {
				this.authenticationToken = res.accessToken;
				this.router.navigate(['/']);
			})
			.catch((err: RestBaseMessageError) => {
				const message = `Accesso non riuscito. <br> ${err.body.detail}`;
				this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: 5000,
					data: {
						message: message
					}
				});
			});
	}

	logout() {
		this.localStorageService.clear('authenticationToken');
		this.localStorageService.clear('payload');
		this.localStorageService.clear('link_code');
		this.store.dispatch(UserStateAction.reset());
		this.store.dispatch(AgentStateAction.reset());
		this.store.dispatch(CustomerStateAction.reset());
		this.store.dispatch(AuxiliaryTableStateAction.reset());
		this.store.dispatch(AgentCustomerStateAction.reset());
		this.store.dispatch(DestinationListStateAction.reset());
		// this.store.dispatch(OrderStateAction.reset());
		// cancellazione info relative alle routes navigabili
		const routes = this.router.config;
		routes[1].children = [];
		this.router.resetConfig(routes);
		this.router.navigate(['/authentication/login']);
	}

	get tokenPayload(): TokenPayload {
		if (!this._tokenPayload) {
			return undefined;
		}
		return new TokenPayload(this._tokenPayload);
	}

	set tokenPayload(tk: TokenPayload) {
		if (tk) {
			this._tokenPayload = new TokenPayload(tk);
		} else {
			this._tokenPayload = null;
		}
		this.tokenPayloadChange.next(this._tokenPayload);
	}

	openSnackBar(message: string, action = 'Ok') {
		this.snackBar.open(message, action, {
			duration: 3000,
			verticalPosition: 'top'
		});
	}
}
