import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { FormValidatorCustom } from './../../../../shared/misc/form-validator';
import { Observable } from 'rxjs';
import { BaseStateModel, BaseState } from '../../../../model/state/base-state.model';
import { StateFeature } from '../../../../state';
import { UserDetailModel } from '../../../../model/user.model';
import { Store } from '@ngrx/store';
import { filter, map, catchError } from 'rxjs/operators';
import { UserActionEnum, UserStateAction } from '../../../../state/user/user.actions';
import { SubscribeManagerService } from '../../../../service/util/subscribe-manager.service';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';

@Component({
	selector: 'password-recovery',
	templateUrl: './password-recovery.component.html',
	styleUrls: ['./password-recovery.component.scss'],
	providers: [SubscribeManagerService]
})
export class PasswordRecoveryComponent implements OnInit, OnDestroy {
	form: FormGroup;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);

	constructor(
		private fb: FormBuilder,
		private snackBar: MatSnackBar,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private router: Router,
		public translate: TranslateService
	) {
		this.createForm();
	}

	ngOnInit() {}

	createForm() {
		this.form = this.fb.group({
			email: ['', [FormValidatorCustom.email, Validators.required]]
		});
	}

	prepareSaveForm() {
		const formModel = this.form.value;
		const saveForm = {
			email: formModel.email as string
		};
		return saveForm.email;
	}

	userPasswordRecovery() {
		if (this.form.valid) {
			const userPasswordRecoveryRequest: BaseStateModel<{ email: string }> = new BaseState(this.form.value);
			this.store.dispatch(UserStateAction.password_recovery(userPasswordRecoveryRequest));
			this.subscribeManagerService.populate(
				this.initObservableState().subscribe(
					res => {
						this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
							duration: 5000,
							data: {
								message: `Richiesta recupero Password inviata con successo. Verificare la casella Email`
							}
						});
						this.router.navigate(['/']);
					},
					error => {
						console.warn(error);
						this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
							duration: 5000,
							data: {
								message: `Siamo spiacenti, Qualcosa è andato storto.`
							}
						});
					}
				),
				'user-password-change'
			);
		}
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	initObservableState(): Observable<BaseStateModel<UserDetailModel | void>> {
		return this.user$.pipe(
			filter(
				(user: BaseStateModel<UserDetailModel | void>) =>
					user && (user.type === UserActionEnum.COMPLETED || user.type === UserActionEnum.ERROR)
			),
			map((user: BaseStateModel<UserDetailModel | void>) => {
				if (user.type === UserActionEnum.COMPLETED) {
					return user;
				} else if (user.type === UserActionEnum.ERROR) {
					throw new Error(UserActionEnum.ERROR);
				}
			}),
			catchError(error => {
				throw new Error(error);
			})
		);
	}
}
