import { Component, OnInit } from '@angular/core';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { AgentCustomerPouchModel, AgentCustomerListPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { StateFeature } from '../../../state';
import { take } from 'rxjs/operators';
import { CustomerListFilterModel } from '../../../service/pouchdb/filter/customer-filter.model';

@Component({
	selector: 'company-agent',
	templateUrl: './company-agent.component.html',
	styleUrls: ['./company-agent.component.scss']
})
export class CompanyAgentComponent implements OnInit {
	userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.AGENT;
	agentCustomer$: Observable<BaseStateModel<AgentCustomerListPouchModel>>;
	agentCustomer: AgentCustomerListPouchModel;

	companyList: BaseStateModel<AgentCustomerPouchModel[]> = {
		data: [],
		pagination: {
			page_current: 1,
			page_size: 10
		},
		sort: [
			{
				business_name: 'asc'
			}
		]
	};

	constructor(private store: Store<any>) {
		this.agentCustomer$ = this.store.select(StateFeature.getAgentCustomer);
		this.agentCustomer$.pipe(take(1)).subscribe(res => {
			this.agentCustomer = res.data;
		});
	}

	ngOnInit() {}

	loadCompanyList(contractFilters: BaseStateModel<CustomerListFilterModel>) {
		const companySearchText = contractFilters.data.companySearchText;
		const filteredData = this.applySearchFilter(this.agentCustomer.values, companySearchText);
		this.companyList.pagination.total_element = filteredData.length;
		this.companyList.data = filteredData
			.sort((a: AgentCustomerPouchModel, b: AgentCustomerPouchModel) =>
				a['business_name'] < b['business_name'] ? -1 : 1
			)
			.slice(
				(this.companyList.pagination.page_current - 1) * this.companyList.pagination.page_size,
				(this.companyList.pagination.page_current - 1) * this.companyList.pagination.page_size +
					this.companyList.pagination.page_size
			);
	}

	applySearchFilter(
		agentCustomerList: AgentCustomerPouchModel[],
		companySearchText: string
	): AgentCustomerPouchModel[] {
		return agentCustomerList.filter((agentCustomer: AgentCustomerPouchModel) => {
			const tokens: string[] = companySearchText.toLowerCase().split(' ');
			let toReturn = true;
			tokens.forEach(token => {
				if (agentCustomer.business_name) {
					if (!agentCustomer.business_name.toLowerCase().includes(token)) {
						toReturn = false;
					}
				} else {
					toReturn = false;
				}
			});
			return toReturn;
		});
	}
}
