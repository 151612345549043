import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LocationModel } from '../../../model/state/contract-state.model';
import { BaseRegistryComponent } from '../BaseRegistryComponent';

@Component({
	selector: 'registry-garaging',
	templateUrl: './registry-garaging.component.html',
	styleUrls: ['./registry-garaging.component.scss']
})
export class RegistryGaragingComponent extends BaseRegistryComponent<LocationModel> {
	static COMPONENT_NAME = 'registry-garaging';

	constructor(private fb: FormBuilder) {
		super(RegistryGaragingComponent.COMPONENT_NAME);
	}

	protected createForm() {
		const isDisabled = false;
		this.form = this.fb.group({
			address: [{ value: null, disabled: isDisabled }, this.applyValidators ? Validators.required : ''],
			zip_code: [{ value: null, disabled: isDisabled }, this.applyValidators ? [Validators.required, Validators.minLength(5)] : ''],
			locality: [{ value: null, disabled: isDisabled }, this.applyValidators ? Validators.required : ''],
			province: [{ value: null, disabled: isDisabled }, this.applyValidators ? [Validators.required, Validators.minLength(2)] : '']
		});
	}

	protected setForm() {
		const data = this.data;
		this.form.patchValue({
			address: data.address ? data.address : null,
			zip_code: data.zip_code ? data.zip_code : null,
			locality: data.locality ? data.locality : null,
			province: data.province ? data.province : null
		});
	}
}
