import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';

export class BasePouch extends AbstractPouchdbMethod {

	saveBodyTable(data: any): Promise<any> {
		const useGuid = data._id ? false : true;
		const some = useGuid ? data.type : data._id;
		return new Promise(resolve => {
			this.put(data, some, useGuid)
				.then((res => {
					resolve(res);
				}))
				.catch((error) => {
					console.log(error);
				});
		});
	}

}
