import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { ITdDataTableSortChangeEvent } from '@covalent/core';
import { Store } from '@ngrx/store';
import { Pagination } from '@saep-ict/pouch-db';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { RegistryModeEnum } from '../../../enum/registry-mode.enum';
import { AgentStateModel } from '../../../model/state/agent-state.model';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { AgentListColumnService } from '../../../service/td-data-table/implementation/agent-list.service';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { UtilCustomerService } from '../../../service/util/util-customer.service';
import { UtilService } from '../../../service/util/util.service';
import { StateFeature } from '../../../state';
import { AgentListStateAction } from '../../../state/agent-list/agent-list.actions';
import { DialogRegistryAgentComponent } from '../../../widget/dialog/dialog-registry-agent/dialog-registry-agent.component';
import { UserManagementStateActionEnum, UserManagementStateAction } from '../../../state/backoffice/user-management/user-management.actions';
import { UserDetailModel } from '../../../model/user.model';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { UserManagementDialoRegistryModel } from '../../../model/user-management.model';
import { UserListFilterModel } from '../../../service/pouchdb/filter/user-filter.model';

@Component({
	selector: 'user-management',
	templateUrl: './user-management.component.html',
	styleUrls: ['./user-management.component.scss'],
	providers: [AgentListColumnService, SubscribeManagerService]
})
export class UserManagementComponent implements OnInit, OnDestroy {
	initialLoadSub: Subscription;

	userManagementState$: Observable<BaseStateModel<UserDetailModel[]>> =
		this.store.select(StateFeature.getUserManagementState);
	userList = <BaseStateModel<UserDetailModel[]>> {};
	userListFilters = <BaseStateModel<UserListFilterModel>> {};

	contextApplicationItemCodeKey: string;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		private store: Store<any>,
		private router: Router,
		private route: ActivatedRoute,
		public agentListColumnService: AgentListColumnService,
		private subscribeManagerService: SubscribeManagerService,
		public utilCustomerService: UtilCustomerService,
		private dialog: MatDialog,
		private utilService: UtilService,
		private permissionUtilService: PermissionUtilService
	) {
		this.subscribeManagerService.populate(this.routeParamSubscribe().subscribe(), 'route-param-state');
	}

	// subscribe

	routeParamSubscribe(): Observable<string> {
		return this.route.paramMap.pipe(
			map((routeParam: ParamMap) => {
				this.contextApplicationItemCodeKey = routeParam.get('contextApplicationItemCodeKey');
				this.resetUserListFilters();
				return this.contextApplicationItemCodeKey;
			})
		);
	}

	ngOnInit() {
		if (!this.initialLoadSub) {
			this.initialLoadSub = this.userManagementState$
				.pipe(
					filter(
						(agentList: BaseStateModel<UserDetailModel[]>) =>
							agentList &&
							agentList.type !== UserManagementStateActionEnum.LOAD_LIST &&
							agentList.type !== UserManagementStateActionEnum.SAVE_LIST &&
							agentList.type !== UserManagementStateActionEnum.SAVE_LIST_SUCCESS
					),
					map((agentList: BaseStateModel<UserDetailModel[]>) => {
						this.userList = agentList;
						return this.userList;
					})
				)
				.subscribe();
		}
	}

	resetUserListFilters() {
		const pagination: Pagination =
			this.userListFilters && this.userListFilters.pagination
			? this.userListFilters.pagination
			: this.getInitialPagination();
		const sort =
			this.userListFilters && this.userListFilters.sort
			? this.userListFilters.sort
			: this.getInitialSort();
		const resetFilter: UserListFilterModel = this.getInitialFilter();

		this.userListFilters = {
			data: null,
			filters: resetFilter,
			pagination: pagination,
			sort: sort
		};

		this.loadUserList();
	}

	loadUserList() {
		const query =  <BaseStateModel<UserDetailModel[]>> {
			filters: this.userListFilters.filters,
			pagination: this.userListFilters.pagination,
			sort: this.userListFilters.sort
		};
		this.store.dispatch(UserManagementStateAction.loadList(query));
	}

	getInitialFilter(): UserListFilterModel {
		const initialFilter: UserListFilterModel = {
			context_code: ContextApplicationItemCodeEnum[this.contextApplicationItemCodeKey]
		};
		return initialFilter;
	}

	getInitialPagination(): Pagination {
		const pagination: Pagination = {
			page_current: 1,
			page_size: 10
		};
		return pagination;
	}

	getInitialSort(): { [key: string]: 'asc' | 'desc' }[] {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				email: 'desc'
			}
		];
		return sort;
	}

	paginationChange(pagination: Pagination) {
		this.userListFilters.pagination = pagination;
		this.loadUserList();
	}

	goToUserDetail(selectedAgent: UserDetailModel) {
		this.router.navigate([`/user-management/detail/${selectedAgent.id}`]);
	}

	// TODO: generalizzare la naming convention del componente per chiarire che gestisce sia il flusso di creazione
	// dell'agent che del utente backoffice
	openDialogUser() {

		const userManagementDialoRegistryModel = <UserManagementDialoRegistryModel> {
			title: 'Azienda',
			mode: RegistryModeEnum.ADD,
			contextTypeAndApplicationLink:
				this.permissionUtilService.contextTypeAndApplicationLink.find(i =>
					i.context_application_item_code ===
						ContextApplicationItemCodeEnum[this.contextApplicationItemCodeKey]
				)
		};

		const dialogRef = this.dialog.open(DialogRegistryAgentComponent, {
			data: userManagementDialoRegistryModel,
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe((res: AgentStateModel) => {
			if (res) {
				this.resetUserListFilters();
			}
		});
	}

	sortChange(sortEvent: ITdDataTableSortChangeEvent): void {
		this.userListFilters.sort = this.utilService.getSortChanged(sortEvent);
		this.loadUserList();
	}

	ngOnDestroy() {
		if (this.initialLoadSub) {
			this.initialLoadSub.unsubscribe();
		}
		this.store.dispatch(AgentListStateAction.reset());
		this.store.dispatch(UserManagementStateAction.reset());
		this.subscribeManagerService.destroy();
	}
}
