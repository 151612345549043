import { EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { Customer } from '../../model/state/contract-state.model';
import { RegistryModeEnum } from '../../enum/registry-mode.enum';
import { CustomerTypeEnum } from '../../enum/customer-type.enum';
import { UtilContractService } from '../../service/util/util-contract.service';

export abstract class BaseRegistryComponent<T> implements OnInit, OnDestroy {
	private currentComponentName: string;
	public customerTypeEnum = CustomerTypeEnum;

	@Input() data: T;
	@Input() applyValidators = true;
	@Input() mode: RegistryModeEnum;
	@Input() customer: Customer;
	@Output() formCreated = new EventEmitter<{ form: FormGroup; componentName: string }>();

	form: FormGroup;
	private formValueChangesSubscription: Subscription;

	constructor(currentComponentName: string) {
		this.currentComponentName = currentComponentName;
	}

	protected abstract createForm(): void;
	protected abstract setForm(): void;
	protected subscribeFields() {}

	ngOnInit() {
		this.createForm();

		if (this.form) {
			this.subscribeFields();
		}

		if (this.data) {
			this.setForm();
		}

		if (!this.formValueChangesSubscription) {
			this.onChanges();
		}

		this.formCreated.emit({ form: this.form, componentName: this.currentComponentName });
	}

	protected onChanges() {
		this.formValueChangesSubscription = this.form.valueChanges.subscribe(val => {
			this.updateDataModel();
		});
	}

	protected updateDataModel(): T {
		if (!this.data) {
			this.data = <T>{};
		}
		Object.assign(this.data, this.form.value);
		// console.log('updateDataModel: ', this.data);
		return this.data;
	}

	protected onDestroy() {}

	ngOnDestroy() {
		// console.log('ngOnDestroy: ' + this.currentComponentName);
		if (this.formValueChangesSubscription) {
			this.formValueChangesSubscription.unsubscribe();
		}
		this.onDestroy();
	}
}
