import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { DEAFULT_DESTINATION_CODE } from '../../constants/destination.constants';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { DestinationListFilterModel } from '../../service/pouchdb/filter/destination-filter.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { PouchErrorResponse } from '../../service/pouchdb/model/pouch-base-response.model';
import { DestinationListActionEnum, DestinationListStateAction } from './destination-list.actions';

@Injectable()
export class DestinationListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DestinationListActionEnum.LOAD),
			mergeMap((action: BaseStateModel<DestinationListFilterModel>) => from(this.getDestinationList(action))),
			map((user: BaseStateModel<DestinationPouchModel[]>) => DestinationListStateAction.update(user)),
			catchError((error, caught) => {
				this.store.dispatch(DestinationListStateAction.error(null));
				return caught;
			})
		)
	);

	loadFilterd$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DestinationListActionEnum.LOAD_FILTERED),
			mergeMap((action: BaseStateModel<DestinationListFilterModel>) =>
				from(this.getDestinationListFiltered(action))
			),
			map((user: BaseStateModel<DestinationPouchModel[]>) => DestinationListStateAction.update(user)),
			catchError((error, caught) => {
				this.store.dispatch(DestinationListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getDestinationList(
		filter: BaseStateModel<DestinationListFilterModel>
	): Promise<BaseStateModel<DestinationPouchModel[]>> {
		return this.pouchDbMobeeAgentiAdapter.destinationPouch
			.getDestinationList(filter)
			.then(async destinationList => {
				return new BaseState(destinationList, filter.pagination, filter.sort);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getDestinationListFiltered(
		filter: BaseStateModel<DestinationListFilterModel>
	): Promise<BaseStateModel<DestinationPouchModel[]>> {
		return this.pouchDbMobeeAgentiAdapter.destinationPouch
			.getDestinationList(filter)
			.then(async destinationList => {
				destinationList = destinationList.filter(
					destination =>
						destination.code === DEAFULT_DESTINATION_CODE
						// ||
						// (destination.code_agent && destination.code_agent.includes(filter.data.code_agent))
				);
				return new BaseState(destinationList, filter.pagination, filter.sort);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
