import { createAction, props } from '@ngrx/store';
import { BasePouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { BodyTablePouchCustomModel } from '../../../service/pouchdb/model/pouch-base.model';

export enum BackofficeCodeListActionEnum {
	UPDATE_LIST = '[backoffice-code-list] Update List',
	LOAD = '[backoffice-code-list] Load',
	LOAD_LIST = '[backoffice-code-list] Load List',
	RESET = '[backoffice-code-list] Reset',
	ERROR = '[backoffice-code-list] Error',
	CREATE_CONTEXT_CODE = '[backoffice-code-list] Create Context Code',
	SAVE_CONTEXT_CODE_SUCCESS = '[backoffice-code-list] Save Context Code Success'
}

export namespace BackofficeCodeListStateAction {
	export const load = createAction(BackofficeCodeListActionEnum.LOAD, props<BaseStateModel<BasePouchModel>>());
	export const loadList = createAction(BackofficeCodeListActionEnum.LOAD_LIST, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const updateList = createAction(BackofficeCodeListActionEnum.UPDATE_LIST, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const reset = createAction(BackofficeCodeListActionEnum.RESET);
	export const error = createAction(BackofficeCodeListActionEnum.ERROR, props<BaseStateModel<any>>());
	export const createContextCode = createAction(BackofficeCodeListActionEnum.CREATE_CONTEXT_CODE, props<BaseStateModel<BodyTablePouchCustomModel>>());
	export const saveContextCodeSuccess = createAction(
		BackofficeCodeListActionEnum.SAVE_CONTEXT_CODE_SUCCESS,
		props<BaseStateModel<BodyTablePouchCustomModel>>()
	);
}
