import { createAction, props } from '@ngrx/store';
import { OrderStatisticsList } from '@saep-ict/pouch_agent_models/model/chart-pouch.model';

import { BaseStateModel } from '../../model/state/base-state.model';
import { ChartFilterModel } from '../../service/pouchdb/filter/chart-filter.model';

export enum ChartActionEnum {
    UPDATE = '[chart] Update',
    LOAD = '[chart] Load',
    COMPLETED = '[chart] Completed',
    RESET = '[chart] Reset',
    ERROR = '[chart] Error'
}

export namespace ChartStateAction{
    export const load = createAction(
        ChartActionEnum.LOAD,
        props<BaseStateModel<ChartFilterModel>>()
    );
    export const update = createAction(
        ChartActionEnum.UPDATE,
        props<BaseStateModel<OrderStatisticsList>>()
    );
    export const completed = createAction(
        ChartActionEnum.COMPLETED,
        props<BaseStateModel<any>>()
    );
    export const reset = createAction(
        ChartActionEnum.RESET
    );
    export const error = createAction(
        ChartActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
