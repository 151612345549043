import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { LoginComponent } from './authentication/login/login.component';
import { PasswordRecoveryComponent } from './authentication/password-recovery/password-recovery.component';
import { PasswordUpdateComponent } from './authentication/password-update/password-update.component';
import { ClientCodeSelectComponent } from './authentication/client-code-select/client-code-select.component';
import { WidgetModule } from '../../widget/widget.module';
import { SharedModule } from '../../shared/shared.module';
import { NotFoundComponent } from './not-found/not-found.component';
import { TokenEmailNotFoundComponent } from './user-activation/token-email-not-found/token-email-not-found.component';
import { BoxWrapperComponent } from './box-wrapper/box-wrapper.component';
import { PasswordNewComponent } from './user-activation/password-new/password-new.component';
import { PrintComponent } from './print/print.component';
import { PrintContractComponent } from './print/print-contract/print-contract.component';
import { ContractComponent } from './contract/contract.component';
import { ContractDetailComponent } from './contract/contract-detail/contract-detail.component';
import { CompanyComponent } from './company/company.component';
import { CompanyDetailComponent } from './company/company-detail/company-detail.component';
import { CompanyDetailDestinationComponent } from './company/company-detail/company-detail-destination/company-detail-destination.component';
import { CompanyDetailFullDetailComponent } from './company/company-detail/company-detail-full-detail/company-detail-full-detail.component';
import { CompanyDetailBankComponent } from './company/company-detail/company-detail-bank/company-detail-bank.component';
import { CompanyDetailOperationalHeadquarterComponent } from './company/company-detail/company-detail-operational-headquarter/company-detail-operational-headquarter.component';
import { UserComponent } from './user/user.component';

@NgModule({
	imports: [WidgetModule, SharedModule],
	declarations: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		ClientCodeSelectComponent,
		NotFoundComponent,
		TokenEmailNotFoundComponent,
		PasswordNewComponent,
		BoxWrapperComponent,
		PrintComponent,
		PrintContractComponent,
		ContractComponent,
		ContractDetailComponent,
		CompanyComponent,
		CompanyDetailComponent,
		CompanyDetailDestinationComponent,
		CompanyDetailBankComponent,
		CompanyDetailOperationalHeadquarterComponent,
		CompanyDetailFullDetailComponent,
		UserComponent,
	],
	exports: [
		LoginComponent,
		PasswordRecoveryComponent,
		PasswordUpdateComponent,
		ClientCodeSelectComponent,
		NotFoundComponent,
		TokenEmailNotFoundComponent,
		PasswordNewComponent,
		BoxWrapperComponent,
		PrintComponent,
		PrintContractComponent,
		ContractComponent,
		ContractDetailComponent,
		CompanyComponent,
		CompanyDetailComponent,
		CompanyDetailDestinationComponent,
		CompanyDetailBankComponent,
		CompanyDetailOperationalHeadquarterComponent,
		CompanyDetailFullDetailComponent,
		UserComponent,
	],
	entryComponents: [],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageCommonsModule {}
