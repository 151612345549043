export enum PermissionKeyEnum {
	BO_DASHBOARD = 1,
	BO_CONTRACT_LIST = 7,
	BO_REGISTRIES = 8,
	BO_COMPANY = 9,

	AGENT_DASHBOARD = 3,
	AGENT_CONTRACT_LIST = 5,
	AGENT_COMPANY = 6,

	B2B_DASHBOARD = 2
}
