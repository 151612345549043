import { ITdDataTableColumn } from '@covalent/core';
import { BaseTableListService } from '../base-table-list.service';

export class FleetListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: '_id',
			label: 'Motorino #'
		},
		{
			name: 'plate',
			label: 'Targa'
		},
		{
			name: 'model',
			label: 'Modello'
		},
		{
			name: 'client',
			label: 'Cliente'
		},
		{
			name: 'expiration',
			label: 'Scadenza',
			numeric: true,
			width: 150
		},
		{
			name: 'amount',
			label: 'Valore contratto',
			numeric: true,
			width: 150
		}
	];

	data = [];
}
