import { createReducer, on } from "@ngrx/store";
import { BaseStateModel } from "../../model/state/base-state.model";
import { TypedAction } from "@ngrx/store/src/models";
import { DestinationPouchModel } from "@saep-ict/pouch_agent_models";
import { DestinationStateAction, DestinationActionEnum } from "./destination.actions";

export namespace DestinationStateReducer {
	export const initialState: BaseStateModel<DestinationPouchModel> = null;

	const _reducer = createReducer(
		initialState,
		on(DestinationStateAction.load, state => state),
		on(DestinationStateAction.update, (state, destination) => destination),
		on(DestinationStateAction.completed, (state, destination) => ({ data: state ? state.data : null, type: destination.type })),
		on(DestinationStateAction.reset, () => initialState),
		on(DestinationStateAction.error, (state, destination) => ({ data: state ? state.data : null, type: destination.type }))
	);

	export function reducer(state: BaseStateModel<DestinationPouchModel>, action: TypedAction<DestinationActionEnum>) {
		return _reducer(state, action);
	}
}
