import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ResourceActionReturnType, ResourceGlobalConfig, ResourceHandler } from '@ngx-resource/core';
import { ResourceModule } from '@ngx-resource/handler-ngx-http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { EnvConfiguratorModule } from '@saep-ict/env-configurator';
import { PouchdbModule } from '@saep-ict/pouch-db';
import { SortablejsModule } from 'angular-sortablejs';
import { DeviceDetectorService } from 'ngx-device-detector';
import {
	PerfectScrollbarConfigInterface,
	PerfectScrollbarModule, PERFECT_SCROLLBAR_CONFIG
} from 'ngx-perfect-scrollbar';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { CustomerAppConfig } from './customer-app.config';
import { FrameModule } from './frame/frame.module';
import { InjectorModule } from './injector.module';
import { PageModule } from './page/page.module';
import { RoutingModule } from './router/app-routing.module';
import { AuthLoginGuard } from './service/guard/auth-login.guard';
import { AuthTokenGuard } from './service/guard/auth-token.guard';
import { AuthUserStateGuard } from './service/guard/auth-user-state.guard';
import { UtilGuardService } from './service/guard/util-guard/util-guard.service';
import { VerifyTokenEmailGuard } from './service/guard/verify-token-email.guard';
import { PouchDbMobeeAgentiAdapter } from './service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { EventHandlerService } from './service/util/event-handler.service';
import { ResourceUrlEncodedHandler } from './service/util/resource-urlencoded-handler.service';
import { AgentCustomerStateModule } from './state/agent-customer/agent-customer-state.module';
import { AgentCustomerEffects } from './state/agent-customer/agent-customer.effects';
import { AgentListStateModule } from './state/agent-list/agent-list-state.module';
import { AgentListEffects } from './state/agent-list/agent-list.effects';
import { AgentStateModule } from './state/agent/agent-state.module';
import { AgentEffects } from './state/agent/agent.effects';
import { AuxiliaryTableStateModule } from './state/auxiliary-table/auxiliary-table-state.module';
import { AuxiliaryTableEffects } from './state/auxiliary-table/auxiliary-table.effects';
import { ChartEffects } from './state/chart/chart.effect';
import { ChartStateModule } from './state/chart/chart.state.module';
import { ContractListStateModule } from './state/contract-list/contract-list-state.module';
import { ContractListEffects } from './state/contract-list/contract-list.effects';
import { ContractStateModule } from './state/contract/contract-state.module';
import { ContractEffects } from './state/contract/contract.effects';
import { CustomerListStateModule } from './state/customer-list/customer-list-state.module';
import { CustomerListEffects } from './state/customer-list/customer-list.effects';
import { CustomerStateModule } from './state/customer/customer-state.module';
import { CustomerEffects } from './state/customer/customer.effects';
import { DestinationListStateModule } from './state/destination-list/destination-list-state.module';
import { DestinationListEffects } from './state/destination-list/destination-list.effects';
import { DestinationStateModule } from './state/destination/destination-state.module';
import { DestinationEffects } from './state/destination/destination.effects';
import { UserActivateStateModule } from './state/user-activate/user-activate-state.module';
import { UserActivateEffects } from './state/user-activate/user-activate.effects';
import { UserStateModule } from './state/user/user-state.module';
import { UserEffects } from './state/user/user.effects';
import { MopedEffects } from './state/moped-list/moped-list.effects';
import { MopedListStateModule } from './state/moped-list/moped-list-state.module';
import { UserManagementStateModule } from './state/backoffice/user-management/user-management-state.module';
import { BackofficeCodeListStateModule } from './state/backoffice/backoffice-code-list/backoffice-code-list-state.module';
import { UserManagementEffects } from './state/backoffice/user-management/user-management.effects';
import { BackofficeCodeListEffects } from './state/backoffice/backoffice-code-list/backoffice-code-list.effects';
import { PermissionAuxiliaryTableStateModule } from './state/permission-auxiliary-table/permission-auxiliary-table-state.module';
import { PermissionAuxiliaryTableEffects } from './state/permission-auxiliary-table/permission-auxiliary-table.effects';


const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
	suppressScrollX: false
};

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function customResourceHandlerFactory(http: HttpClient) {
	return new ResourceUrlEncodedHandler(http);
}

@NgModule({
	declarations: [AppComponent],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		RoutingModule,
		SortablejsModule,
		PerfectScrollbarModule,
		HttpClientModule,
		ResourceModule.forRoot({
			handler: { provide: ResourceHandler, useFactory: customResourceHandlerFactory, deps: [HttpClient] }
		}),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: createTranslateLoader,
				deps: [HttpClient]
			}
		}),
		NgxWebstorageModule.forRoot(),
		// EnvironmentFunction
		EnvConfiguratorModule.forRoot({ env: environment, appConfig: CustomerAppConfig }),
		// CouchFunction
		PouchdbModule.forRoot({ config: [PouchDbMobeeAgentiAdapter], appConfig: CustomerAppConfig }),
		// NgRx - Store
		StoreModule.forRoot({}),
		PermissionAuxiliaryTableStateModule,
		UserStateModule,
		AgentStateModule,
		CustomerStateModule,
		CustomerListStateModule,
		UserActivateStateModule,
		AuxiliaryTableStateModule,
		DestinationStateModule,
		DestinationListStateModule,
		AgentCustomerStateModule,
		ChartStateModule,
		ContractStateModule,
		ContractListStateModule,
		AgentListStateModule,
		MopedListStateModule,
		// backoffice
		UserManagementStateModule,
		BackofficeCodeListStateModule,
		EffectsModule.forRoot([
			PermissionAuxiliaryTableEffects,
			AgentEffects,
			CustomerEffects,
			CustomerListEffects,
			UserEffects,
			UserActivateEffects,
			AuxiliaryTableEffects,
			DestinationEffects,
			DestinationListEffects,
			AgentCustomerEffects,
			ChartEffects,
			ContractEffects,
			ContractListEffects,
			AgentListEffects,
			MopedEffects,
			// backoffice
			UserManagementEffects,
			BackofficeCodeListEffects
		]),
		StoreDevtoolsModule.instrument({
			maxAge: 25, // Retains last 25 states
			logOnly: environment.production // Restrict extension to log-only mode
		}),
		// structure modules
		FrameModule,
		PageModule,
		InjectorModule,
	],
	providers: [
		PouchDbMobeeAgentiAdapter,
		EventHandlerService,
		DeviceDetectorService,
		MatIconRegistry,
		UtilGuardService,
		AuthTokenGuard,
		AuthUserStateGuard,
		AuthLoginGuard,
		VerifyTokenEmailGuard,
		{ provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
		{ provide: LocationStrategy, useClass: HashLocationStrategy }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor() {
		ResourceGlobalConfig.returnAs = ResourceActionReturnType.Promise;
	}
}
