import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { from } from 'rxjs';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { Store } from '@ngrx/store';
import { BaseStateModel, BaseState } from '../../model/state/base-state.model';
import { AgentCustomerActionEnum, AgentCustomerStateAction } from './agent-customer.actions';
import { RestBasePk } from '../../model/rest-base.model';
import { AgentCustomerListPouchModel } from '@saep-ict/pouch_agent_models';

@Injectable()
export class AgentCustomerEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AgentCustomerActionEnum.LOAD),
			mergeMap((action: BaseStateModel<RestBasePk>) => from(this.getAgentCustomer(action.data.id))),
			map((agentCustomer: BaseStateModel<AgentCustomerListPouchModel>) =>
				AgentCustomerStateAction.update(agentCustomer)
			),
			catchError((error, caught) => {
				this.store.dispatch(AgentCustomerStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getAgentCustomer(agentCode: string): Promise<BaseStateModel<AgentCustomerListPouchModel>> {
		try {
			const agentCustomer = await this.pouchDbMobeeAgentiAdapter.agentCustomerPouch.getAgentCustomer(agentCode);
			return new BaseState(agentCustomer);
		} catch (err) {
			throw new Error(err);
		}
	}
}
