import { NgModule } from '@angular/core';
import { NavigationService } from '../service/navigation/navigation.service';
import { LoaderService } from '../service/structure/loader.service';
import { MediaReplayService } from '../service/util/media-replay.service';
import { SharedModule } from '../shared/shared.module';
import { WidgetModule } from '../widget/widget.module';
import { FramePageComponent } from './admin/admin.component';
import { IconSidenavDirective } from './sidenav/icon-sidenav.directive';
import { SidenavItemComponent } from './sidenav/sidenav-item/sidenav-item.component';
import { SidenavComponent } from './sidenav/sidenav.component';
import { ToolbarNotificationsComponent } from './toolbar/toolbar-notifications/toolbar-notifications.component';
import { ToolbarUserButtonComponent } from './toolbar/toolbar-user-button/toolbar-user-button.component';
import { ToolbarComponent } from './toolbar/toolbar.component';

@NgModule({
  imports: [
    SharedModule,
    WidgetModule,
  ],
  declarations: [
    FramePageComponent,
    SidenavComponent,
    SidenavItemComponent,
    IconSidenavDirective,
    ToolbarComponent,
    ToolbarUserButtonComponent,
    ToolbarNotificationsComponent,
  ],
  providers: [
    NavigationService,
    MediaReplayService,
    LoaderService
  ]
})
export class FrameModule { }
