import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'breadcrumb',
	templateUrl: './breadcrumb.component.html',
	styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {
	@Input() title: string;
	@Input() subtitle: string;
	@Input() badge: string;
	@Input() options: boolean;
	@Input() badgeClass: string = '';
	@Input() expandedActions: boolean = false;
	@Input() backRoute: any[];

	constructor() {}
	ngOnInit() {}
}
