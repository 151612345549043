import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { MopedDataSheetModel } from '../../model/state/contract-state.model';
import { MopedListActionEnum, MopedListStateAction } from './moped-list.actions';

export namespace MopedStateReducer {
	// const fakeData: BaseState<MopedDataSheetModel[]> = new BaseState<MopedDataSheetModel[]>();
	// fakeData.data = [
	// 	{
	// 		_id: "moped_10f2d6207349042ed84d162e4408da74",
	// 		_rev: "1-be18edba25563947f266cad62091c10c",
	// 		marca: "Vmoto soco",
	// 		modello: "Cux",
	// 		motore: "Bosch",
	// 		potenza: "1.6 kw",
	// 		fari: "lec",
	// 		prima_batteria: "60V 30Ah",
	// 		carica_batterie: "60V 4.5A",
	// 		omologazione: "e13*168/2013*00522*00",
	// 		tipo: null,
	// 		categoria: "L1e-B",
	// 		pendenza_max: "15%",
	// 		gomma_ant: "90/90-12 54J ",
	// 		gomma_post: "90/90-12 54J",
	// 		freno_ant: "disco",
	// 		freno_post: "disco",
	// 		carico_massimo: "227 kg",
	// 		sospensioni_ant: "doppia forcella",
	// 		sospensioni_post: "doppio ammortizzatore",
	// 		antifurto: true
	// 	   }
	// ];

	export const initialState: BaseStateModel<MopedDataSheetModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(MopedListStateAction.load, state => state),
		on(MopedListStateAction.update, (state, contract) => contract),
		on(MopedListStateAction.reset, () => initialState),
		on(MopedListStateAction.error, (state, contract) => ({ data: state ? state.data : null, type: contract.type }))
	);

	export function reducer(state: BaseStateModel<MopedDataSheetModel[]>, action: TypedAction<MopedListActionEnum>) {
		return _reducer(state, action);
	}
}
