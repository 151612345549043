import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { BasePouchModel, BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import { Observable, Subscription } from 'rxjs';
import { map, skipWhile, take } from 'rxjs/operators';

import { BaseState, BaseStateModel } from '../../../../model/state/base-state.model';
import { StateFeature } from '../../../../state';
import { ChartStateAction } from '../../../../state/chart/chart.action';
import { CustomerStateAction } from '../../../../state/customer/customer.actions';
import { ContextApplicationItemCodeEnum } from '../../../../enum/context-application-item-code.enum';
import { Customer } from '../../../../model/state/contract-state.model';
import { SubscribeManagerService } from '../../../../service/util/subscribe-manager.service';

@Component({
	selector: 'company-detail',
	templateUrl: './company-detail.component.html',
	styleUrls: ['./company-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
	@Input() userType: ContextApplicationItemCodeEnum;

	agent$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(StateFeature.getAgentState);
	agent: BodyTablePouchModel;

	customer$: Observable<BaseStateModel<CustomerPouchModel>> = this.store.select(StateFeature.getCustomerState);
	customer: Customer;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.agent$.pipe(take(1)).subscribe(res => {
			this.agent = res ? res.data : null;
		});

		this.subscribeManagerService.populate(
			this.customer$
				.pipe(
					skipWhile((customerState: BaseStateModel<CustomerPouchModel>) => !customerState),
					map((customerState: BaseStateModel<CustomerPouchModel>) => {
						this.customer = Object.assign(new Customer(), customerState.data);
					})
				)
				.subscribe(),
			'customer'
		);

		const companyCode = this.route.snapshot.paramMap.get('idCompany');
		const customerRequest: BasePouchModel = { _id: companyCode };

		this.store.dispatch(CustomerStateAction.load(new BaseState(customerRequest)));
	}

	ngOnInit() {}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(CustomerStateAction.reset());
	}
}
