import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export class NestedCategoryItemModel {
  id?: string;
  label?: string;
  parent?: string;
  data?: NestedCategoryItemModel[]
}
export class NestedCategoryConfigModel {
  selected?: string;
  data: NestedCategoryItemModel[];
}

@Component({
  selector: 'nested-category-list',
  templateUrl: './nested-category-list.component.html',
  styleUrls: ['./nested-category-list.component.scss']
})
export class NestedCategoryListComponent implements OnInit {

  _list: NestedCategoryItemModel[] = [];
  listRendered: NestedCategoryItemModel = new NestedCategoryItemModel();

  selected: string = null;
  breadcrumb: string[] = [null];

  @Input('list') set list(l: NestedCategoryItemModel[]) {
    if (l) {
      this._list = l;
      this.selectCategory();
    }
  }

  @Output() selectionEmitter = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  selectCategory(id?: string) {
    let found = false;
    let a = this._list;
    if (id) {
      // ricerca ricorsiva della categoria selezionata
      let search = (a, id) => {
        for (let i=0; i<a.length; i++) {
          let l = a[i];
          if (l.id == id) {
            this.selected = l;
            this.listRendered.id = l.id;
            this.listRendered.label = l.label;
            if (l.data) {
              this.listRendered.data = l.data;
            } else {
              this.listRendered.data = [];
            }
            found = true;
          } else if (l.data) {
            search(l.data, id);
          }
          if (found) {
            break
          }
        }
      }
      search(a, id);
    } else {
      // categorie di primo livello
      this.selected = null;
      this.listRendered.data = this._list;
    }

  }

  // eventi

  selectSubCategory(selected: string) {
    this.breadcrumb.push(selected);
    this.selectCategory(selected);
    this.selectionEmitter.emit(selected);
  }

  selcteParentCategory() {
    this.breadcrumb.pop();
    let selected = this.breadcrumb[this.breadcrumb.length-1];
    this.selectCategory(selected);
    this.selectionEmitter.emit(selected);
  }
}
