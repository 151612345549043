import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { CustomerAppConfig } from '../../../customer-app.config';

@Component({
	selector: 'app-box-wrapper',
	templateUrl: './box-wrapper.component.html',
	styleUrls: ['./box-wrapper.component.scss']
})
export class BoxWrapperComponent implements OnInit, OnDestroy {
	configSubscription: Subscription;
	appTag: string;

	constructor(protected appConfig: CustomerAppConfig) {
		this.appConfig.config$.subscribe(config => {
			this.appTag = config.tag;
		});
	}

	ngOnInit() {}

	ngOnDestroy() {
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
	}
}
