import { BodyTablePouchModel, HeaderTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import {
	ArticleListTypePouchModel,
	CausalHeaderSoPouchModel,
	CausalRowSoPouchModel,
	CodeVatPouchModel,
	CountryIsoPouchModel,
	DivisionPouchModel,
	LocationDepartureDivisionPouchModel,
	LocationDepartureGoodsPouchModel,
	PaymentPouchModel,
	UmPouchModel
} from '@saep-ict/pouch_agent_models/model/table-list-pouch.model';

export interface AuxiliaryTableStateModel {
	areaCommercialList?: BodyTablePouchModel[];
	causalHeaderSoList?: CausalHeaderSoPouchModel[];
	causalRowSoList?: CausalRowSoPouchModel[];
	countryIsoList?: CountryIsoPouchModel[];
	codeVatList?: CodeVatPouchModel[];
	articlePriceList?: ArticleListTypePouchModel[];
	methodShippingList?: BodyTablePouchModel[];
	methodDeliveryList?: BodyTablePouchModel[];
	paymentList?: PaymentPouchModel[];
	causalSuspensionOrderList?: BodyTablePouchModel[];
	umList?: UmPouchModel[];
	typesCurrencyList?: BodyTablePouchModel[];
	carrierList?: BodyTablePouchModel[]; // vettori
	divisionList?: DivisionPouchModel[];
	locationDepartureDivisionList?: LocationDepartureDivisionPouchModel[];
	locationDepartureGoodsList?: LocationDepartureGoodsPouchModel[];
}

export const AuxiliaryDictionary = [
	// {
	// 	key: 'table_area_commercial',
	// 	type: 'areaCommercialList'
	// },
	// {
	// 	key: 'table_causal_header_so',
	// 	type: 'causalHeaderSoList'
	// },
	// {
	// 	key: 'table_causal_row_so',
	// 	type: 'causalRowSoList'
	// },
	// {
	// 	key: 'table_country_iso',
	// 	type: 'countryIsoList'
	// },
	// {
	// 	key: 'table_code_vat',
	// 	type: 'codeVatList'
	// },
	// {
	// 	key: 'table_list_type',
	// 	type: 'articlePriceList'
	// },
	// {
	// 	key: 'table_method_shipping',
	// 	type: 'methodShippingList'
	// },
	// {
	// 	key: 'table_method_delivery',
	// 	type: 'methodDeliveryList'
	// },
	// {
	// 	key: 'table_payment',
	// 	type: 'paymentList'
	// },
	// {
	// 	key: 'table_causal_suspension_order',
	// 	type: 'causalSuspensionOrderList'
	// },
	// {
	// 	key: 'table_um',
	// 	type: 'umList'
	// },
	// {
	// 	key: 'table_type_currency',
	// 	type: 'typesCurrencyList'
	// },
	// {
	// 	key: 'table_carrier',
	// 	type: 'carrierList'
	// },
	// {
	// 	key: 'table_division',
	// 	type: 'divisionList'
	// },
	// {
	// 	key: 'table_location_departure_division',
	// 	type: 'locationDepartureDivisionList'
	// },
	// {
	// 	key: 'table_location_departure_goods',
	// 	type: 'locationDepartureGoodsList'
	// }
];
