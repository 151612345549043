import { createFeatureSelector } from '@ngrx/store';
import { AgentCustomerListPouchModel, DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import { AgentStateModel } from '../model/state/agent-state.model';
import { AuxiliaryTableStateModel } from '../model/state/auxiliary-table-list';
import { BaseStateModel } from '../model/state/base-state.model';
import { ContractStateModel, MopedDataSheetModel } from '../model/state/contract-state.model';
import { UserActivationStateModel, UserDetailModel } from '../model/user.model';
import { BodyTablePouchCustomModel } from '../service/pouchdb/model/pouch-base.model';
import { PermissionAuxiliaryTableStateModel } from '../model/state/permission-auxiliary-table.model';

export namespace StateFeature {
	export const getUserState = createFeatureSelector<BaseStateModel<UserDetailModel>>('user');
	export const getAgentState = createFeatureSelector<BaseStateModel<AgentStateModel>>('agent');
	export const getAgentListState = createFeatureSelector<BaseStateModel<BodyTablePouchCustomModel[]>>('agent-list');

	export const getCustomerState = createFeatureSelector<BaseStateModel<CustomerPouchModel>>('customer');
	export const getCustomerListState = createFeatureSelector<BaseStateModel<CustomerPouchModel[]>>('customer-list');

	export const getUserActivateState = createFeatureSelector<BaseStateModel<UserActivationStateModel>>('user-activate');

	export const getAuxiliaryTableState = createFeatureSelector<BaseStateModel<AuxiliaryTableStateModel>>('auxiliary-table');

	export const getDestination = createFeatureSelector<BaseStateModel<DestinationPouchModel>>('destination');
	export const getDestinationList = createFeatureSelector<BaseStateModel<DestinationPouchModel[]>>('destination-list');

	export const getAgentCustomer = createFeatureSelector<BaseStateModel<AgentCustomerListPouchModel>>('agent-customer');

	export const getContractState = createFeatureSelector<BaseStateModel<ContractStateModel>>('contract');
	export const getContractListState = createFeatureSelector<BaseStateModel<ContractStateModel[]>>('contract-list');
	// export const getContractStatistics = createFeatureSelector<BaseStateModel<ContractStatisticsList>>('contract-statistics');

	export const getMopedListState = createFeatureSelector<BaseStateModel<MopedDataSheetModel[]>>('moped-list');

	export const getBackofficeCodeList = createFeatureSelector<BaseStateModel<BodyTablePouchCustomModel[]>>('backoffice-code-list');

	export const getUserManagementState = createFeatureSelector<BaseStateModel<UserDetailModel[]>>('user-management');

	export const getPermissionAuxiliaryTableState = createFeatureSelector<BaseStateModel<PermissionAuxiliaryTableStateModel>>(
		'permission-auxiliary-table'
	);
}
