import { Injectable } from '@angular/core';
import { AgentCustomerListPouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import { AgentStateModel } from '../../model/state/agent-state.model';
import { RegistryModeEnum } from '../../enum/registry-mode.enum';
import { Customer } from '../../model/state/contract-state.model';
import { UserDetailModel } from '../../model/user.model';

@Injectable({
	providedIn: 'root'
})
export class UtilCustomerService {
	isCustomerByDestination(agentCustomer: AgentCustomerListPouchModel, customer: CustomerPouchModel): boolean {
		return agentCustomer.values.find(cust => cust.code === customer.code).customer_destination;
	}

	getFormattedName(user: UserDetailModel): string {
		if (!user) {
			return '-';
		}
		let formattedName = '';
		if (user.first_name) {
			formattedName = formattedName.concat(user.first_name);
		}
		if (user.last_name) {
			formattedName = formattedName.concat(' ' + user.last_name);
		}
		return formattedName.trim();
	}

	/**
	 *
	 * @param mode declare if you are adding or editing
	 * @param customer the customer to update
	 * @param newData the data of the customer to update
	 * @param property the propery of the customer to update
	 */
	public handleCustomer(mode: RegistryModeEnum, customer: Customer, property: string, newData: any): Customer {
		const newCustomer = Object.assign(new Customer(), customer);

		switch (mode) {
			case RegistryModeEnum.ADD:
				newCustomer[property].push(newData);
				break;

			case RegistryModeEnum.EDIT:
				newCustomer[property].forEach((g, index) => {
					if (g.code === newData.code) {
						newCustomer[property][index] = newData;
					}
				});
				break;

			default:
				break;
		}
		return newCustomer;
	}
}
