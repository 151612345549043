import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { RestBasePk } from '../../../../model/rest-base.model';
import { BaseStateModel } from '../../../../model/state/base-state.model';
import { ContractListFilterModel } from '../../filter/contract-filter.model';
import { PouchDeleteResponse, PouchErrorResponse } from '../../model/pouch-base-response.model';
import {
	ContractPouchModel,
	ExtraFieldContractHeaderPouchModel,
	ContractStateModel
} from '../../../../model/state/contract-state.model';

export class ContractPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	getContractDetail(data: RestBasePk): Promise<ContractPouchModel<ExtraFieldContractHeaderPouchModel>> {
		return new Promise((resolve, reject) => {
			this.get(data.id)
				.then((doc: ContractPouchModel<ExtraFieldContractHeaderPouchModel>) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	putContract(data: ContractStateModel, isGuid: boolean): Promise<ContractStateModel> {
		const guid = isGuid ? data._id : 'contract';
		data.type = 'contract';
		return new Promise((resolve, reject) => {
			this.put<ContractStateModel>(data, guid, !isGuid)
				.then((doc: ContractStateModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	deleteContract(contract: ContractStateModel): Promise<PouchDeleteResponse> {
		return new Promise(resolve => {
			this.delete(contract)
				.then((doc: PouchDeleteResponse) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
				});
		});
	}

	getContractList(
		filter: BaseStateModel<ContractListFilterModel>
	): Promise<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]> {
		const exists = {
			$exists: true
		};
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'contract'
					}
				]
			};

			if (filter.data) {
				this.setField('header.code_agent', filter.data.user_code ? filter.data.user_code : exists);
				if (filter.data.business_code) {
					this.setField(
						'header.customer.code',
						filter.data.business_code ? filter.data.business_code : exists
					);
				}
				this.setField(
					'header.status',
					filter.data.status && filter.data.status.length > 0 ? { $in: filter.data.status } : exists
				);
				this.setField(
					'header.date',
					filter.data.date
						? {
								$gte: +filter.data.date.begin,
								$lte: +filter.data.date.end
						  }
						: exists
				);
			}

			this.getWithIndexFilter('contract-list', this.selector, filter.pagination, filter.sort ? filter.sort : [])
				.then((res: ListDataPouchModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>>) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}

	setField(key: string, condition: any) {
		this.selector.$and.push({ [key]: condition });
	}
}
