import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// routes
import * as baseRouteList from './base-routing';

const routes: Routes = baseRouteList.routes;

@NgModule({
	imports: [RouterModule.forRoot(routes)],
	exports: [RouterModule],
	providers: []
})
export class RoutingModule {}
