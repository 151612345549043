import { NgModule } from '@angular/core';
import { SafeDomSanitizerPipe } from './safe-dom-sanitizer.pipe';
import { DatePipe, TitleCasePipe } from '@angular/common';
import { EnumIteratorPipe } from './enum-iterator';

@NgModule({
	declarations: [SafeDomSanitizerPipe, EnumIteratorPipe],
	exports: [SafeDomSanitizerPipe, EnumIteratorPipe],
	providers: [DatePipe, TitleCasePipe]
})
export class PipeModule {}
