import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { MopedDataSheetModel } from '../../model/state/contract-state.model';
import { MopedListFilterModel } from '../../service/pouchdb/filter/moped-filter.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { PouchErrorResponse } from '../../service/pouchdb/model/pouch-base-response.model';
import { MopedListActionEnum, MopedListStateAction } from './moped-list.actions';

@Injectable()
export class MopedEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(MopedListActionEnum.LOAD),
			mergeMap((action: BaseStateModel<MopedListFilterModel>) => from(this.getMopedList(action))),
			map((moped: BaseStateModel<MopedDataSheetModel[]>) => MopedListStateAction.update(moped)),
			catchError((error, caught) => {
				this.store.dispatch(MopedListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getMopedList(data: BaseStateModel<MopedListFilterModel>): Promise<BaseStateModel<MopedDataSheetModel[]>> {

		return this.pouchDbMobeeAgentiAdapter.mopedPouch
			.getMopedList(data)
			.then(async (mopedList: MopedDataSheetModel[]) => {
				return new BaseState(mopedList);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
