import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { MopedDataSheetModel } from '../../model/state/contract-state.model';
import { MopedListFilterModel } from '../../service/pouchdb/filter/moped-filter.model';

export enum MopedListActionEnum {
	UPDATE = '[moped list] Update',
	LOAD = '[moped list] Load',
	SAVE = '[moped list] Save',
	REMOVE = '[moped list] Remove',
	REMOVED = '[moped list] Removed',
	COMPLETED = '[moped list] Completed',
	RESET = '[moped list] Reset',
	ERROR = '[moped list] Error'
}

export namespace MopedListStateAction {
	export const load = createAction(MopedListActionEnum.LOAD, props<BaseStateModel<MopedListFilterModel>>());
	export const update = createAction(MopedListActionEnum.UPDATE, props<BaseStateModel<MopedDataSheetModel[]>>());
	export const reset = createAction(MopedListActionEnum.RESET);
	export const error = createAction(MopedListActionEnum.ERROR, props<BaseStateModel<any>>());
}
