import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { AgentListFilterModel } from '../../service/pouchdb/filter/agent-filter.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { AgentListActionEnum, AgentListStateAction } from './agent-list.actions';
import {
	AgentStateModel,
	AgentCreateFullPipePayload,
	AgentFranchiserModel,
	AgentStatisticsModel,
	OrderStatusStatisticsModel
} from '../../model/state/agent-state.model';
import { BodyTablePouchCustomModel } from '../../service/pouchdb/model/pouch-base.model';
import { AgentStateAction } from '../agent/agent.actions';
import { PouchUtilService } from '../../service/util/pouch-util.service';
import { PermissionUtilService } from '../../service/util/permission-util.service';
import _ from 'lodash';
import { ListViewDataPouchModel } from '@saep-ict/pouch_agent_models';
import { ContractStatusEnum } from '../../enum/contract-status.enum';
import { StatsResultModel } from '../../service/pouchdb/mobee-agenti/pouch-function/agent-statistics.pouch';
import { BaseViewPouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';

@Injectable()
export class AgentListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AgentListActionEnum.LOAD),
			mergeMap((action: BaseStateModel<AgentListFilterModel>) => from(this.getAgentList(action))),
			map((agentList: BaseState<BodyTablePouchCustomModel[]>) => AgentListStateAction.update(agentList)),
			catchError((error, caught) => {
				this.store.dispatch(AgentListStateAction.error(null));
				return caught;
			})
		)
	);

	loadDetailWithFranchiser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AgentListActionEnum.LOAD_DETAIL_WITH_FRANCHISER),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload[]>) => from(this.getAgentDetail(action))),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload[]>) =>
				from(this.mergeAgentFranchiserLinkInAgentList(action))
			),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload[]>) =>
				from(this.mergeFranchiserInAgentList(action))
			),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload[]>) =>
				from(this.mergeAgentStatsInAgentList(action))
			),
			map((agentList: BaseState<AgentCreateFullPipePayload[]>) => AgentListStateAction.update(agentList)),
			catchError((error, caught) => {
				this.store.dispatch(AgentListStateAction.error(null));
				return caught;
			})
		)
	);

	createContextCodeFullPipe$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AgentListActionEnum.CREATE_CONTEXT_CODE_FULL_PIPE),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload>) => from(this.saveContextCode(action))),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload>) =>
				from(this.saveContextCodeCodeItem(action))
			),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload>) => from(this.saveAgentCustomerLink(action))),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload>) => from(this.saveFranchiser(action))),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload>) => from(this.saveFranchiserCodeItem(action))),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload>) =>
				from(this.saveContextCodeFranchiserLink(action))
			),
			map((action: BaseStateModel<AgentCreateFullPipePayload>) =>
				AgentListStateAction.saveContextCodeSuccess(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(AgentStateAction.error(null));
				return caught;
			})
		)
	);

	saveFranchiserDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AgentListActionEnum.SAVE_FRANCHISER_DETAIL),
			mergeMap((action: BaseStateModel<AgentCreateFullPipePayload>) => from(this.saveFranchiser(action))),
			map((action: BaseStateModel<AgentCreateFullPipePayload>) =>
				AgentListStateAction.saveContextCodeSuccess(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(AgentStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter,
		private pouchUtilService: PouchUtilService,
		private permissionUtilService: PermissionUtilService
	) {}

	async getAgentList(filter: BaseStateModel<AgentListFilterModel>): Promise<BaseStateModel<AgentStateModel[]>> {
		try {
			const agentList = await this.pouchDbMobeeAgentiAdapter.agentPouch.getAgentList(filter);
			return new BaseState(agentList, filter.pagination, filter.sort);
		} catch (err) {
			throw new Error(err);
		}
	}

	async getAgentDetail(
		action: BaseStateModel<AgentCreateFullPipePayload[]>
	): Promise<BaseStateModel<AgentCreateFullPipePayload[]>> {
		try {
			const agentList = <BaseStateModel<AgentCreateFullPipePayload[]>>{
				data: [await this.pouchDbMobeeAgentiAdapter.agentPouch.getAgent(action.filters.id)]
			};
			return agentList;
		} catch (err) {
			throw new Error(err);
		}
	}

	async mergeAgentFranchiserLinkInAgentList(
		action: BaseStateModel<AgentCreateFullPipePayload[]>
	): Promise<BaseStateModel<AgentCreateFullPipePayload[]>> {
		const promises = [];
		for (let i = 0; i < action.data.length; i++) {
			promises.push(
				this.pouchDbMobeeAgentiAdapter.agentPouch
					.getAgentFranchiserLink(action.data[i].code_item)
					.then(res => {
						action.data[i].custom_field = {
							franchiser: {
								code_item: res[0].code_franchiser
							}
						};
					})
					.catch(err => {
						console.log(err);
					})
			);
		}
		await Promise.all(promises);
		return action;
	}

	async mergeFranchiserInAgentList(
		action: BaseStateModel<AgentCreateFullPipePayload[]>
	): Promise<BaseStateModel<AgentCreateFullPipePayload[]>> {
		const promises = [];
		for (let i = 0; i < action.data.length; i++) {
			promises.push(
				this.pouchDbMobeeAgentiAdapter.agentPouch
					.getFranchiserDetail(action.data[i].custom_field.franchiser.code_item)
					.then(res => {
						action.data[i].custom_field = {
							franchiser: res
						};
					})
					.catch(err => {
						console.log(err);
					})
			);
		}
		await Promise.all(promises);
		return action;
	}

	async mergeAgentStatsInAgentList(
		action: BaseStateModel<AgentCreateFullPipePayload[]>
	): Promise<BaseStateModel<AgentCreateFullPipePayload[]>> {
		const promises = [];
		for (let i = 0; i < action.data.length; i++) {
			const idAgent = action.data[i].code_item;
			if (!action.data[i].custom_field.stats) {
				action.data[i].custom_field.stats = new AgentStatisticsModel();
			}
			const stats = action.data[i].custom_field.stats;

			// findAgentOrderSum
			promises.push(
				this.pouchDbMobeeAgentiAdapter.agentStatisticsPouch.findAgentOrderSum(idAgent)
					.then((orderListView: ListViewDataPouchModel<string>) => {
						stats.orders_count = this.getCountOrders(orderListView);
						stats.orders_validated = stats.orders_count.COMPLETED;
						stats.total_orders = orderListView.rows.length;
					})
					.catch(err => {
						console.log(err);
					})
			);

			// statsFulfilledOrders
			promises.push(
				this.pouchDbMobeeAgentiAdapter.agentStatisticsPouch.statsFulfilledOrders(idAgent)
					.then((statsFulfilledOrders: ListViewDataPouchModel<StatsResultModel>) => {
						stats.orders_avg = this.getAvgOrders(statsFulfilledOrders, idAgent);
					})
					.catch(err => {
						console.log(err);
					})
			);

		}
		await Promise.all(promises);
		return action;
	}

	async saveContextCode(
		action: BaseStateModel<AgentCreateFullPipePayload>
	): Promise<BaseStateModel<AgentCreateFullPipePayload>> {
		let actionReturn = <BaseStateModel<AgentCreateFullPipePayload>>_.cloneDeep(action);
		delete actionReturn.data.custom_field;
		try {
			const contextCode = <BaseStateModel<AgentCreateFullPipePayload>>(
				await this.permissionUtilService.saveContextCode(new BaseState(actionReturn.data))
			);
			actionReturn = contextCode;
			actionReturn.data.custom_field = action.data.custom_field;
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveContextCodeCodeItem(
		action: BaseStateModel<AgentCreateFullPipePayload>
	): Promise<BaseStateModel<AgentCreateFullPipePayload>> {
		let actionReturn = <BaseStateModel<AgentCreateFullPipePayload>>_.cloneDeep(action);
		delete actionReturn.data.custom_field;
		try {
			const contextCode = <BaseStateModel<AgentCreateFullPipePayload>>(
				await this.permissionUtilService.saveContextCodeCodeItem(new BaseState(actionReturn.data))
			);
			actionReturn = contextCode;
			actionReturn.data.custom_field = action.data.custom_field;
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveAgentCustomerLink(
		action: BaseStateModel<AgentCreateFullPipePayload>
	): Promise<BaseStateModel<AgentCreateFullPipePayload>> {
		// TODO: definire il modello (AgentCustomerPouchModel differisce dall'esigenza)
		const agentCustomerLink = {
			_id: 'agent_customer_' + action.data.code_item,
			type: 'agent_customer',
			code_agent: action.data.code_item,
			values: []
		};
		try {
			await this.pouchUtilService.saveAnyDocument(agentCustomerLink);
			return action;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveFranchiser(
		action: BaseStateModel<AgentCreateFullPipePayload>
	): Promise<BaseStateModel<AgentCreateFullPipePayload>> {
		try {
			action.data.custom_field = {
				franchiser: await this.pouchUtilService.saveAnyDocument(action.data.custom_field.franchiser)
			};
			return action;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveFranchiserCodeItem(
		action: BaseStateModel<AgentCreateFullPipePayload>
	): Promise<BaseStateModel<AgentCreateFullPipePayload>> {
		try {
			const contextCode = <BaseStateModel<AgentCreateFullPipePayload>>(
				await this.permissionUtilService.saveContextCodeCodeItem(
					new BaseState(action.data.custom_field.franchiser)
				)
			);
			action.data.custom_field = {
				franchiser: contextCode.data
			};
			return action;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveContextCodeFranchiserLink(
		action: BaseStateModel<AgentCreateFullPipePayload>
	): Promise<BaseStateModel<AgentCreateFullPipePayload>> {
		const saveContextCodeFranchiserLink = <AgentFranchiserModel>{
			type: 'agent_franchiser_link',
			code_agent: action.data.code_item,
			code_franchiser: action.data.custom_field.franchiser.code_item
		};
		try {
			await this.pouchUtilService.saveAnyDocument(saveContextCodeFranchiserLink);
			return action;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	private getAvgOrders(statsFulfilledOrders: ListViewDataPouchModel<StatsResultModel>, idAgent: string): number {
		if (!statsFulfilledOrders || statsFulfilledOrders.rows.length === 0) {
			return 0;
		}
		const stats: BaseViewPouchModel<StatsResultModel> = statsFulfilledOrders.rows.find(row => row.key === idAgent);
		if (!stats) {
			return 0;
		}
		const sum = stats.value.sum;
		const count = stats.value.count;
		return sum / count;
	}

	private getCountOrders(orderListView: ListViewDataPouchModel<string>): OrderStatusStatisticsModel {
		const orderStatusStatisticsModel = new OrderStatusStatisticsModel();

		Object.keys(ContractStatusEnum).forEach(status => {
			orderStatusStatisticsModel[status] = 0;
		});

		orderListView.rows.forEach(order => {
			try {
				orderStatusStatisticsModel[order.value]++;
			} catch {
				console.error('getValidatedOrders');
			}
		});

		return orderStatusStatisticsModel;
	}
}
