import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { of, from } from 'rxjs';
import { BaseStateModel, BaseState } from '../../model/state/base-state.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { CustomerActionEnum, CustomerStateAction } from './customer.actions';
import { BasePouchModel, CustomerPouchModel } from '@saep-ict/pouch_agent_models';
import { Store } from '@ngrx/store';
import { CustomerStateModel } from '../../model/state/customer-state.model';
import { PouchErrorResponse } from '../../service/pouchdb/model/pouch-base-response.model';
import { Customer } from '../../model/state/contract-state.model';

@Injectable()
export class CustomerEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CustomerActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) => from(this.getCustomer(action.data))),
			map((customer: BaseStateModel<CustomerStateModel>) => CustomerStateAction.update(customer)),
			catchError((error, caught) => {
				this.store.dispatch(CustomerStateAction.error(null));
				return caught;
			})
		)
	);

	save$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CustomerActionEnum.SAVE),
			mergeMap((action: BaseStateModel<CustomerPouchModel>) => from(this.saveCustomerDetail(action.data))),
			map((customer: BaseStateModel<CustomerStateModel>) => CustomerStateAction.update(customer)),
			catchError((error, caught) => {
				this.store.dispatch(CustomerStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getCustomer(data: BasePouchModel): Promise<BaseStateModel<CustomerPouchModel>> {
		try {
			const customer = await this.pouchDbMobeeAgentiAdapter.customerPouch.getCustomer(data._id);
			return new BaseState(new Customer(customer));
		} catch (err) {
			throw new Error(err);
		}
	}

	//
	async saveCustomerDetail(data: CustomerPouchModel): Promise<BaseStateModel<CustomerPouchModel>> {
		return this.pouchDbMobeeAgentiAdapter.customerPouch
			.putCustomer(data, data._id ? true : false)
			.then(async contract => {
				return new BaseState(contract);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
