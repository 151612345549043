import { NgModule } from '@angular/core';
import {CovalentSearchModule, CovalentDataTableModule, CovalentPagingModule} from "@covalent/core";

@NgModule({
  exports: [
    CovalentSearchModule,
    CovalentDataTableModule,
    CovalentPagingModule
  ]
})
export class CovalentModule { }
