import {FormGroup, AbstractControl} from '@angular/forms';

export class FormValidatorCustom {

  constructor(){}

  public static match2fields(first, second) {
    return (c:FormGroup) => {
      return (c.controls && c.controls[first].value == c.controls[second].value) ? null : {
        passwordsEqual: {
          valid: false
        }
      };
    }
  }

  public static email(c: AbstractControl) {
    let EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (c.value != '') {
      return EMAIL_REGEXP.test(c.value) ? null : {
        valid: false
      };
    }
  }

  public static minNumber(number){
    return (c: FormGroup) => {
      return (parseFloat(c.value) >= number) ? null : {
        diffSettings: {
          valid: false
        }
      };
    }
  }

  public static maxNumber(number){
    return (c: FormGroup) => {
      return (parseFloat(c.value) <= number) ? null : {
        diffSettings: {
          valid: false
        }
      };
    }
  }

  public static checkInt() {
    return (c: FormGroup) => {
      let val = parseFloat(c.value)
      return Number.isInteger(val) ? null : {
        valid: false
      }
    }
  }

}
