import { ITdDataTableColumn } from '@covalent/core';

import { BaseTableListService } from '../base-table-list.service';

export class AgentCustomerListColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
        {
            name: "business_name",
            label: "Cliente"
        },
        {
            name: "vat_number",
            label: "Partita IVA / CF"
        },
        {
            name: "address",
            label: "Indirizzo",
        }
    ];
}