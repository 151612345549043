import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSelectChange } from '@angular/material/select';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription, Observable } from 'rxjs';

import { AuthService } from '../../../service/rest/auth.service';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { UserDetailModel, UserTypeContextModel } from '../../../model/user.model';
import { StateFeature } from '../../../state';
import { take } from 'rxjs/operators';
import { ContextType, UtilGuardService } from '../../../service/guard/util-guard/util-guard.service';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';

@Component({
	selector: 'ms-toolbar-user-button',
	templateUrl: './toolbar-user-button.component.html',
	styleUrls: ['./toolbar-user-button.component.scss']
})
export class ToolbarUserButtonComponent implements OnInit, OnDestroy {
	panelOpenState = false;
	isOpen: boolean;
	languages = this.translate.getLangs();
	langSelected: string = this.translate.getDefaultLang();
	userInfo: string;

	contextState$: Observable<BaseStateModel<ContextType>>;
	contextState: ContextType;
	currentContext: UserTypeContextModel;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(
		public authSevice: AuthService,
		private translate: TranslateService,
		private store: Store<any>,
		private utilGuardService: UtilGuardService
	) {
		this.retrieveUserContext();
		this.setUserInfo();
	}

	ngOnInit() {}

	ngOnDestroy() {}

	retrieveUserContext() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.currentContext = this.utilGuardService.retrieveContextPermission(this.user.current_context);
		if (this.user.current_context !== ContextApplicationItemCodeEnum.BACKOFFICE) {
			this.contextState$ = this.store.select(this.currentContext.state);
			this.contextState$.pipe(take(1)).subscribe(res => {
				this.contextState = res ? res.data : null;
			});
		}
	}

	setUserInfo() {
		switch (this.user.current_context) {
			case ContextApplicationItemCodeEnum.BACKOFFICE:
				this.userInfo = `${this.user.username}`;
				break;
			case ContextApplicationItemCodeEnum.AGENT:
				this.userInfo = `${this.contextState['code_item']} ${this.contextState['description'] ? ' - ' + this.contextState['description'] : ''}`;
				break;
			case ContextApplicationItemCodeEnum.B2B:
				this.userInfo = `${this.contextState['code']} - ${this.contextState['business_name']}`;
				break;
		}
	}

	toggleDropdown() {
		this.isOpen = !this.isOpen;
	}

	onClickOutside() {
		this.isOpen = false;
	}

	langChange(e: MatSelectChange) {
		this.translate.use(e.value);
	}

	logout() {
		this.authSevice.logout();
	}
}
