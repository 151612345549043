import { Injectable } from '@angular/core';
import { CustomerAppConfig } from '../../customer-app.config';

@Injectable({
	providedIn: 'root'
})
export class AppVersionService {
	constructor(private appConfig: CustomerAppConfig) {}
	setTag() {
		this.appConfig.config.tag = '0.0.2';
	}
}
