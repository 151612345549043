import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import { CustomerStateReducer } from "./customer.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('customer', CustomerStateReducer.reducer)
    ]
})
export class CustomerStateModule{}
