import { registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { Component, ViewEncapsulation } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { AppVersionService } from './service/structure/app-version-settings.service';

registerLocaleData(localeIt, 'it');
@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	encapsulation: ViewEncapsulation.None
})
export class AppComponent {
	languages = ['it'];

	constructor(
		translate: TranslateService,
		private matIconRegistry: MatIconRegistry,
		private domSanitizer: DomSanitizer,
		private appVersionService: AppVersionService
	) {
		// default translate configuration
		translate.addLangs(this.languages);
		translate.setDefaultLang(this.languages[0]);
		// custom icons registration
		this.matIconRegistry.addSvgIcon(
			'custom-hamburger',
			this.domSanitizer.bypassSecurityTrustResourceUrl('../assets/img/custom-icon/icon-hamburger.svg')
		);
		this.appVersionService.setTag();
	}
}
