import { createAction, props } from "@ngrx/store";
import { BaseStateModel } from "../../model/state/base-state.model";
import { CustomerPouchModel } from "@saep-ict/pouch_agent_models";
import { CustomerListFilterModel } from "../../service/pouchdb/filter/customer-filter.model";

export enum CustomerListActionEnum {
    UPDATE = '[customer list] Update',
    LOAD = '[customer list] Load',
    LOAD_FILTERED = '[customer list] Load filtered',
    COMPLETED = '[customer list] Completed',
    RESET = '[customer list] Reset',
    ERROR = '[customer list] Error'
}

export namespace CustomerListStateAction{
    export const load = createAction(
        CustomerListActionEnum.LOAD,
        props<BaseStateModel<CustomerListFilterModel>>()
    );
    export const loadFiltered = createAction(
        CustomerListActionEnum.LOAD_FILTERED,
        props<BaseStateModel<CustomerListFilterModel>>()
    );
    export const update = createAction(
        CustomerListActionEnum.UPDATE,
        props<BaseStateModel<CustomerPouchModel[]>>()
    );
    export const completed = createAction(
        CustomerListActionEnum.COMPLETED,
        props<BaseStateModel<any>>()
    );
    export const reset = createAction(
        CustomerListActionEnum.RESET
    );
    export const error = createAction(
        CustomerListActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
