import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Pagination } from '@saep-ict/pouch-db';
import {
	AgentCustomerListPouchModel,
	AgentCustomerPouchModel,
	BodyTablePouchModel
} from '@saep-ict/pouch_agent_models';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';
import { ContractStatusEnum } from '../../../enum/contract-status.enum';
import { BaseStateModel } from '../../../model/state/base-state.model';
import {
	ContractPouchModel,

	ContractStateModel, ExtraFieldContractHeaderPouchModel
} from '../../../model/state/contract-state.model';
import { ContractListFilterModel, DateFilterModel } from '../../../service/pouchdb/filter/contract-filter.model';
import { FleetListColumnService } from '../../../service/td-data-table/implementation/fleet-list.service';
import { DateMomentService } from '../../../service/util/date-moment.service';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { UtilFleetService } from '../../../service/util/util-fleet.service';
import { StateFeature } from '../../../state';
import { ContractListActionEnum } from '../../../state/contract-list/contract-list.actions';

@Component({
	selector: 'fleet',
	templateUrl: './fleet.component.html',
	styleUrls: ['./fleet.component.scss'],
	providers: [FleetListColumnService, SubscribeManagerService]
})
export class FleetComponent implements OnInit, OnDestroy {
	initialLoadSub: Subscription;

	agent$: Observable<BaseStateModel<BodyTablePouchModel>>;
	agent: BodyTablePouchModel;

	agentList: BaseStateModel<BodyTablePouchModel[]> = {
		// data: this.fleetListColumnService.data
		data: null
	};

	agentListFilters: BaseStateModel<ContractListFilterModel>;
	contractListFilterForm: FormGroup;
	filteredCustomerList: Observable<AgentCustomerPouchModel[]>;
	companyNameCtrl = new FormControl();

	agentCustomer: BaseStateModel<AgentCustomerListPouchModel>;
	agentCustomer$: Observable<BaseStateModel<AgentCustomerListPouchModel>>;

	contracts$: Observable<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>>;

	fleetListFilters: any;
	fleetList: BaseStateModel<ContractStateModel[]>;
	contractList: any;

	constructor(
		private store: Store<any>,
		public fleetListColumnService: FleetListColumnService,
		private subscribeManagerService: SubscribeManagerService,
		private dateMomentService: DateMomentService,
		private fb: FormBuilder,
		public utilFleetService: UtilFleetService,
		private route: ActivatedRoute
	) {
		this.agent$ = this.store.select(StateFeature.getAgentState);
		this.agentCustomer$ = this.store.select(StateFeature.getAgentCustomer);
		this.contracts$ = this.store.select(StateFeature.getContractListState);
		this.createFormFilters();
		this.filteredCustomerList = this.companyNameCtrl.valueChanges.pipe(
			startWith(''),
			map(company =>
				company
					? this._filterCompanyNames(company)
					: this.agentCustomer.data.values
							.sort((a, b) => (a.business_name < b.business_name ? -1 : 1))
							.slice()
			)
		);
	}

	ngOnInit() {
		if (!this.initialLoadSub) {
			this.initialLoadSub = this.agent$
				.pipe(
					take(1),
					mergeMap((agent: BaseStateModel<BodyTablePouchModel>) => {
						this.agent = agent ? agent.data : null;
						return this.agentCustomer$;
					}),
					take(1),
					mergeMap((agentCustomer: BaseStateModel<AgentCustomerListPouchModel>) => {
						this.agentCustomer = agentCustomer;
						return this.route.paramMap;
					}),
					mergeMap(params => {
						this.resetFleetListFormFilter();
						this.loadFleetList();
						this.fleetListFilters.pagination = this.getInitialPagination();
						return this.contracts$;
					}),
					filter(
						(contractList: BaseStateModel<ContractStateModel[]>) =>
							contractList && contractList.type !== ContractListActionEnum.LOAD_WITH_DETAIL
					),
					map((contractList: BaseStateModel<ContractStateModel[]>) => {
						if (contractList.type === ContractListActionEnum.UPDATE) {
							this.fleetList = contractList;
							return this.contractList;
						}
					})
				)
				.subscribe();
		}
	}

	paginationChange(pagination: Pagination) {
		this.agentListFilters.pagination = pagination;
		this.loadFleetList();
	}

	getInitialFilter(): ContractListFilterModel {
		// const agentCode = this.agent.code_item;
		// const companyCode = this.contractCompany;
		const initialFilter: ContractListFilterModel = {
			// user_code: agentCode
		};
		// if (companyCode) {
		// 	initialFilter.client_code = companyCode;
		// }
		return initialFilter;
	}

	getInitialPagination(): Pagination {
		const pagination: Pagination = {
			page_current: 1,
			page_size: 10
		};
		return pagination;
	}

	getInitialSort(): { [key: string]: 'asc' | 'desc' }[] {
		return [];

		// const sort: { [key: string]: 'asc' | 'desc' }[] = [
		// 	{
		// 		'header.date': 'desc'
		// 	}
		// ];
		// return sort;
	}

	createNew() {
		// TODO-MOBEE open dialog
	}

	loadFleetList() {
		// TODO-MOBEE
		// this.store.dispatch(ContractListStateAction.load(this.contractFilters));
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	_filterCompanyNames(value: string): AgentCustomerPouchModel[] {
		const filterValue = value.toLowerCase();

		return this.agentCustomer.data.values
			.filter(company => company.business_name.toLowerCase().includes(filterValue))
			.sort((a, b) => (a.business_name < b.business_name ? -1 : 1));
	}

	createFormFilters() {
		this.contractListFilterForm = this.fb.group({
			date: [''],
			business_name: [''],
			moped: ['']
		});
	}

	resetFleetListFormFilter() {
		this.contractListFilterForm.patchValue({
			date: '',
			business_name: '',
			moped: ''
		});

		this.resetFleetListFilters();
	}

	resetFleetListFilters() {
		const pagination: Pagination = this.fleetListFilters
			? this.fleetListFilters.pagination
			: this.getInitialPagination();
		const sort = this.fleetListFilters ? this.fleetListFilters.sort : this.getInitialSort();
		const resetFilter: ContractListFilterModel = this.getInitialFilter();
		this.fleetListFilters = {
			data: resetFilter,
			pagination: pagination,
			sort: sort
		};
		this.loadFleetList();
	}

	/**
	 * Viene usato per creare il filtro
	 */
	get goodTypeList() {
		return null;
		// return this.utilFleetService.getGoodTypeList();
	}

	submitContractListFilters() {
		// Data creazione
		if (this.contractListFilterForm && this.contractListFilterForm.value.date) {
			const filterDate: DateFilterModel = {
				begin: this.contractListFilterForm.value.date.begin.startOf('day').format('x'),
				end: this.contractListFilterForm.value.date.end.endOf('day').format('x')
			};
			this.fleetListFilters.data.date = filterDate;
		}

		// Azienda
		if (this.companyNameCtrl && (this.companyNameCtrl.value || this.companyNameCtrl.value === '')) {
			this.fleetListFilters.data.business_name = this.companyNameCtrl.value;
		}

		// Stato contratti
		if (this.contractListFilterForm && this.contractListFilterForm.value.moped) {
			this.fleetListFilters.data.status = this.contractListFilterForm.value.moped.map(
				(status: string) => ContractStatusEnum[status]
			);
		}

		console.log(this.fleetListFilters.data);

		this.fleetListFilters.pagination.page_current = 1;
		this.loadFleetList();
	}

	resetContractFormFilter(){}

	ngOnDestroy() {
		if (this.initialLoadSub) {
			this.initialLoadSub.unsubscribe();
		}
		this.subscribeManagerService.destroy();
	}
}
