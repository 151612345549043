import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { BaseStateModel } from '../../../model/state/base-state.model';
import { UserDetailModel, UserTypeContextModel } from '../../../model/user.model';
import { StateFeature } from '../../../state';

@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
	customer$: Observable<BaseStateModel<CustomerPouchModel>> = this.store.select(StateFeature.getCustomerState);
	customer: CustomerPouchModel;
	currentContext: UserTypeContextModel;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;

	constructor(private store: Store<any>) {
		this.retrieveUserContext();
	}

	ngOnInit() {}

	ngOnDestroy() {}

	retrieveUserContext() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;
		});
		this.customer$.pipe(take(1)).subscribe(res => {
			this.customer = res ? res.data : null;
		});
	}
}
