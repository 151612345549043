import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

@Component({
  selector: "dialog-text-edit",
  templateUrl: "./dialog-text-edit.component.html",
  styleUrls: ["./dialog-text-edit.component.scss"]
})
export class DialogTextEditComponent implements OnInit {
  form: FormGroup;
  constructor(
    private fb: FormBuilder,
    @Inject(MAT_DIALOG_DATA) public data,
    private dialogRef: MatDialogRef<DialogTextEditComponent>,
  ) {
    this.createForm();
  }

  ngOnInit() {}

  createForm() {
    this.form = this.fb.group({
      text: [{ value: this.data.text, disabled: this.data.disabled }, Validators.required]
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.dialogRef.close(this.prepareSaveForm());
    }    
  }

  prepareSaveForm() {
    const formModel = this.form.value;
    const saveForm = {
      text: formModel.text as string
    };
    return saveForm.text;
  }
}
