import { NgModule } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule } from '@angular/material/radio';
import {MatStepperModule} from '@angular/material/stepper';

import { Ng5SliderModule } from 'ng5-slider';

// dates
import { MatDatepickerModule } from '@angular/material/datepicker';

import {
	MomentDateAdapter,
	MAT_MOMENT_DATE_ADAPTER_OPTIONS,
	MAT_MOMENT_DATE_FORMATS
} from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';

// saturn
import {
	DateAdapter as SatDateAdapter,
	MAT_DATE_FORMATS as SAT_DATE_FORMATS,
	MAT_DATE_LOCALE as SAT_DATE_LOCALE,
	SatDatepickerModule
} from 'saturn-datepicker';

export const CUSTOM_DATE_FORMATS = {
	parse: {
		dateInput: 'DD/MM/YYYY'
	},
	display: {
		dateInput: 'DD/MM/YYYY',
		monthYearLabel: 'MMM YYYY',
		dateA11yLabel: 'LL',
		monthYearA11yLabel: 'MMMM YYYY'
	}
};
@NgModule({
	exports: [
		MatInputModule,
		MatExpansionModule,
		MatTabsModule,
		MatIconModule,
		MatListModule,
		MatButtonModule,
		MatToolbarModule,
		MatDialogModule,
		MatMenuModule,
		MatGridListModule,
		MatCardModule,
		MatSnackBarModule,
		MatTooltipModule,
		MatRadioModule,
		MatSliderModule,
		MatAutocompleteModule,
		MatDatepickerModule,
		MatSlideToggleModule,
		MatSidenavModule,
		MatCheckboxModule,
		MatNativeDateModule,
		MatProgressBarModule,
		MatProgressSpinnerModule,
		MatSelectModule,
		MatButtonToggleModule,
		MatRippleModule,
		SatDatepickerModule,
		Ng5SliderModule,
		MatStepperModule
	],
	providers: [
		/**
		 * Material Datepicker
		 */
		{ provide: MAT_DATE_LOCALE, useValue: 'it-IT' },
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
		},
		{ provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS },
		/**
		 * Saturn Datepicker
		 */
		{
			provide: SatDateAdapter,
			useExisting: DateAdapter
		},
		{ provide: SAT_DATE_FORMATS, useExisting: MAT_DATE_FORMATS },
		{ provide: SAT_DATE_LOCALE, useExisting: MAT_DATE_LOCALE }
	]
})
export class MaterialComponentsModule {}
