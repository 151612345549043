import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ListViewDataPouchModel } from '@saep-ict/pouch_agent_models';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';

const AGENT_COUNT_ORDER_STATUS = 'agent/count-order-status';
const AGENT_AVG_ORDER = 'agent/avg-order-agent';

export class StatsResultModel {
	sum: number;
	count: number;
	min: number;
	max: number;
	sumsqr: number;
}

export class AgentStatisticsPouch extends AbstractPouchdbMethod {
	/**
	 *
	 * findAgentOrderSum - map function
	 */
	// function (doc) {
	// 	if(doc.type === "contract" && doc.header.code_agent){
	// 	  emit(doc.header.code_agent, doc.header.status);
	// 	}
	//   }

	/**
	 *
	 * reduce : none
	 */
	findAgentOrderSum(agentCode: string): Promise<ListViewDataPouchModel<string>> {
		const idx = AGENT_COUNT_ORDER_STATUS;
		const group = false;
		const key = agentCode;

		return new Promise((resolve, reject) => {
			this.db
				.query(idx, { group: group, key: key })
				.then(res => {
					resolve(res);
				})
				.catch((err: PouchErrorResponse) => {
					console.log(err);
					reject(err);
				});
		});
	}

	/**
	 *
	 * statsFulfilledOrders - map function
	 */
	// function(doc) {
	// 	if (doc.type === 'contract' && doc.header.code_agent && doc.body.payment && doc.header.status) {
	// 		if (
	// 			doc.header.status === 'ASK_CONF_RID' ||
	// 			doc.header.status === 'DELIVERING' ||
	// 			doc.header.status === 'COMPLETED' ||
	// 			doc.header.status === 'ARCHIVED'
	// 		) {
	// 			var location =
	// 				doc.body.payment.location.amount +
	// 				doc.body.payment.location.amount * (doc.body.payment.location.vat / 100);
	// 			var delivery =
	// 				doc.body.payment.delivery.amount +
	// 				doc.body.payment.delivery.amount * (doc.body.payment.delivery.vat / 100);
	// 			var optionals =
	// 				doc.body.payment.optionals.amount +
	// 				doc.body.payment.optionals.amount * (doc.body.payment.optionals.vat / 100);
	// 			var somma = location + delivery + optionals;

	// 			emit(doc.header.code_agent, somma);
	// 		}
	// 	}
	// }
	/**
	 *
	 * reduce : _stats
	 */
	statsFulfilledOrders(agentCode: string): Promise<ListViewDataPouchModel<StatsResultModel>> {
		const idx = AGENT_AVG_ORDER;
		const group = true;
		const key = agentCode;

		return new Promise((resolve, reject) => {
			this.db
				.query(idx, { group: group, key: key })
				.then(res => {
					resolve(res);
				})
				.catch((err: PouchErrorResponse) => {
					console.log(err);
					reject(err);
				});
		});
	}
}
