import { AfterViewInit, Component, Inject, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ContextDialogValuesModel } from '../../../service/util/util-contract.service';
import { throwError } from 'rxjs';

@Component({
	selector: 'dialog-registry-customer',
	templateUrl: './dialog-registry-customer.component.html',
	styleUrls: ['./dialog-registry-customer.component.scss']
})
export class DialogRegistryCustomerComponent implements OnInit {
	form: FormGroup;

	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<DialogRegistryCustomerComponent>
	) {
		if (!data.customer) {
			throwError('Non è presente il customer');
		}
		this.createForm();
	}

	ngOnInit() {}

	componentIsVisible(componentName: string) {
		let visbility = false;
		if (this.data.context) {
			this.data.context.forEach((contextDialogValue: ContextDialogValuesModel) => {
				if (contextDialogValue.name === componentName) {
					visbility = true;
				}
			});
		}
		return visbility;
	}

	applyComponentValidators(componentName: string) {
		let applyValidators = false;
		if (this.data.context) {
			this.data.context.forEach((contextDialogValue: ContextDialogValuesModel) => {
				if (contextDialogValue.name === componentName) {
					applyValidators = contextDialogValue.applyValidators;
				}
			});
		}
		return applyValidators;
	}

	createForm() {
		this.form = this.fb.group({});
	}

	onFormSubmit() {
		Object.keys(this.form.controls).forEach(key => {
			this.form.controls[key].markAllAsTouched();
		});

		if (this.form.valid) {
			this.dialogRef.close(this.prepareSaveForm());
		}
	}

	prepareSaveForm(): any {
		return this.data;
	}

	subFormCreated(event: { form: FormGroup; componentName: string }) {
		this.form.addControl(event.componentName, event.form);
		event.form.setParent(this.form);
	}
}
