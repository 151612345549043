import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'print',
	templateUrl: './print.component.html',
	styleUrls: ['./print.component.scss']
})
export class PrintComponent implements OnInit {
	@Input() titlePage: string;
	@Input() subtitlePageList: string[];
	@Input() textFooter: string;
	@Input() textHeader: string;

	constructor() {}

	ngOnInit() {}
}
