import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
	selector: 'dialog-contract-terms',
	templateUrl: './dialog-contract-terms.component.html',
	styleUrls: ['./dialog-contract-terms.component.scss']
})
export class DialogContractTermsComponent implements OnInit {
	constructor(@Inject(MAT_DIALOG_DATA) public data) {}
	ngOnInit() {}
}
