import { createReducer, on } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';

import { BaseStateModel } from '../../../model/state/base-state.model';
import { BodyTablePouchCustomModel } from '../../../service/pouchdb/model/pouch-base.model';
import { BackofficeCodeListActionEnum, BackofficeCodeListStateAction } from './backoffice-code-list.actions';

export namespace BackofficeCodeListStateReducer {
	export const initialState: BaseStateModel<BodyTablePouchCustomModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(BackofficeCodeListStateAction.load, state => state),
		on(BackofficeCodeListStateAction.loadList, state => state),
		on(BackofficeCodeListStateAction.updateList, (state, param) => param),
		on(BackofficeCodeListStateAction.saveContextCodeSuccess, (state, param) => {
			const newState = <BaseStateModel<BodyTablePouchCustomModel[]>> {
				data: [param.data],
				type: param.type
			};
			return newState;
		}),
		on(BackofficeCodeListStateAction.reset, () => null),
		on(BackofficeCodeListStateAction.error, (state, agent) => ({ data: state ? state.data : null, type: agent.type }))
	);

	export function reducer(state: BaseStateModel<BodyTablePouchCustomModel[]>, action: TypedAction<BackofficeCodeListActionEnum>) {
		return _reducer(state, action);
	}
}
