import { Component, OnInit, Inject } from "@angular/core";
import { MAT_DIALOG_DATA } from "@angular/material/dialog";
import { FormGroup, FormControl } from "@angular/forms";

@Component({
  selector: "dialog-confirm",
  templateUrl: "./dialog-confirm.component.html",
  styleUrls: ["./dialog-confirm.component.scss"]
})
export class DialogConfirmComponent implements OnInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data) {}
  ngOnInit() {}
}
