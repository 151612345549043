import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'mat-card-highlight-value',
  templateUrl: './mat-card-highlight-value.component.html',
  styleUrls: ['./mat-card-highlight-value.component.scss']
})
export class MatCardHighlightValueComponent implements OnInit {

  @Input('input-val') value: number = 1000;
  @Input() percentage: number = 50;

  constructor() {}

  ngOnInit() {
  }

}
