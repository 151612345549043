import { Directive, OnInit, OnDestroy, Output, EventEmitter, HostListener, Input } from '@angular/core';
import { Subject ,  Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[debounceTimeHandler]'
})
export class DebounceTimeHandlerDirective implements OnInit, OnDestroy {

  @Input() debounceTime = 500;
  @Output() debounceEvent = new EventEmitter();
  private key = new Subject();
  private subscription: Subscription;

  private specialKeys: Array<string> = ['Escape', 'Tab', 'End', 'Home', 'ArrowDown', 'ArrowUp', 'ArrowLeft', 'ArrowRight', 'Enter'];

  constructor() { }

  ngOnInit() {
    this.subscription = this.key.pipe(
      debounceTime(this.debounceTime)
    ).subscribe(e => this.debounceEvent.emit(e));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  @HostListener('keyup', ['$event'])
  keyEvent(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.specialKeys.indexOf(event.key) === -1){
      this.key.next(event);
    }
  }

}
