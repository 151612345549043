import { AddressPouchModel, BasePouchModel, ContactDetailPouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerPouchModel, CustomerDivisionPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import { ContractPaymentUnitEnum } from '../../enum/contract-payment-unit.enum';
import { ContractStatusEnum } from '../../enum/contract-status.enum';
import { CustomerTypeEnum } from '../../enum/customer-type.enum';
import { FranchiserModel } from './agent-state.model';

export interface ContractPouchModel<T> extends BasePouchModel {
	header?: ContractHeaderPouchModel<T>;
	body?: ContractBodyPouchModel;
	footer?: ContractFooterPouchModel;
}

export interface ContractHeaderPouchModel<T> {
	date?: number;
	code_agent: string;
	franchiser: FranchiserModel;
	customer?: Customer;
	status?: ContractStatusEnum;
	custom_field?: T;
}
export class Uuid {
	public static uuid(): string {
		return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, char => {
			const random = (Math.random() * 16) | 0;
			const value = char === 'x' ? random : (random % 4) + 8; // Bei x Random 0-15 (0-F), bei y Random 0-3 + 8 = 8-11 (8-b) gemäss RFC 4122
			return value.toString(16);
		});
	}
}

export class BaseOptionItem {
	code?: string = Uuid.uuid();
}

export class ContractOptionItem extends BaseOptionItem {
	label: string;
	description: string;
	deleted?: number = null;
	doc_ref?: string; // documento couch
}

export class ContractPaimentItem {
	amount: number;
	vat: number;
	vat_incl: boolean;
	currency: string; // ISO code
}

export class CatalogDataModel extends ContractOptionItem {
	required?: boolean;
}
export class LocationModel extends BaseOptionItem implements AddressPouchModel {
	address: string;
	zip_code: string;
	locality: string;
	province: string;
}

export class BankDataModel extends BaseOptionItem {
	iban_code: string;
	bic_code: string;
	bank_address: string;
	bank_email: string;
	bank_ref: string;
	// bank_ref_name?: string;
	// bank_ref_id_card: string;
}

export class ContractFooterPouchModel {
	legal_representative: {
		name: string;
		mobile: string;
	};
	verification_code: string = null;
	sign: string = null;
	photo_front: string = null;
	photo_back: string = null;

	privacyAcceptance_0: CatalogDataModel;
	privacyAcceptance_1: CatalogDataModel;
	privacyAcceptance_2: CatalogDataModel;
	privacyAcceptance_3: CatalogDataModel;
	privacyAcceptance_4: CatalogDataModel;

	contractTerms: string = null;

	constructor(init?: Partial<ContractFooterPouchModel>) {
		this.legal_representative = {
			name: null,
			mobile: null
		};

		Object.assign(this, init);
	}
}

export class ContractBodyPouchModel {
	contract_payment_unit: ContractPaymentUnitEnum;
	moped_data_sheet: MopedDataSheetModel = null;
	moped_id: string;
	moped_chassis: string;
	moped_plate: string;
	//    good_code: string = null;
	optionals: {
		winter_kit: ContractOptionItem;
		topbox_back: ContractOptionItem;
		topbox_front: ContractOptionItem;
		second_battery: ContractOptionItem;
	};
	customization: {
		topbox_graphic: ContractOptionItem;
		topbox_graphic_type: ContractOptionItem;
		good_color: ContractOptionItem;
	};
	insurance: {
		insurance_rca: ContractOptionItem;
		insurance_theft_fire: ContractOptionItem;
		insurance_vandalism: ContractOptionItem;
		insurance_comprehensive: ContractOptionItem;
	};
	location_start: Date = null;
	location_period: number = null;
	km_max: number = null;
	support: ContractOptionItem = null;
	garaging: LocationModel = null;
	payment: {
		total: number;
		location: ContractPaimentItem;
		delivery: ContractPaimentItem;
		optional: ContractPaimentItem;
	};
	billing_term: ContractOptionItem = null;
	deposit: {
		deposit_advance: ContractOptionItem;
		deposit_after_verification: ContractOptionItem;
		deposit_guarantee_bank: ContractOptionItem;
		deposit_guarantee_letter: ContractOptionItem;
	};
	bank_data: BankDataModel = null;

	constructor(init?: Partial<ContractBodyPouchModel>) {
		this.optionals = {
			winter_kit: null,
			topbox_back: null,
			topbox_front: null,
			second_battery: null,
		};
		this.customization = {
			topbox_graphic: null,
			topbox_graphic_type: null,
			good_color: null
		};
		this.insurance = {
			insurance_rca: null,
			insurance_theft_fire: null,
			insurance_vandalism: null,
			insurance_comprehensive: null
		};
		this.payment = {
			total: 0,
			location: {
				amount: null,
				vat: null,
				vat_incl: false,
				currency: 'EUR'
			},
			delivery: {
				amount: null,
				vat: null,
				vat_incl: false,
				currency: 'EUR'
			},
			optional: {
				amount: null,
				vat: null,
				vat_incl: false,
				currency: 'EUR'
			}
		};
		this.deposit = {
			deposit_advance: null,
			deposit_after_verification: null,
			deposit_guarantee_bank: null,
			deposit_guarantee_letter: null
		};

		Object.assign(this, init);
	}
}

export class Customer implements CustomerPouchModel {
	constructor(init?: Partial<Customer>) {
		Object.assign(this, init);
	}

	_id?: string;
	customer_type: string;
	first_name: string = null;
	last_name: string = null;
	business_name?: string;
	vat_number: string = null;
	tax_code: string = null;
	rea_number: string = null;
	pec_email: string = null;
	email: string = null;
	sdi_code: string = null;
	phone_number: string = null;
	operational_headquarter?: LocationModel[] = [];
	bank_data?: BankDataModel[] = [];
	garaging?: LocationModel[] = [];

	// Saep Model
	business_name_extended?: string;
	address?: AddressPouchModel;
	code_agent?: string[] = [];
	reference_internal?: string;
	code_national_abi_cab?: string;
	code_bank_abi_cab?: string;
	charge_transport_costs?: string;
	country?: string;
	domestic_foreign?: string;
	contact_details?: ContactDetailPouchModel;
	code_price_list?: string;
	group_ddt_billing?: string;
	group_orders_ddt?: string;
	order_confirmation_print?: string;
	order_confirmation_send?: string;
	excerpts_process?: string;
	customer_type_supply_b?: string;
	banks_descriptions: string[];
	divisions?: CustomerDivisionPouchModel[];
	code_lock?: string;
	status?: string;
	cancellation: string;
	code_customer?: string;
	code?: string;
	valid: boolean;
	type?: string;
	_rev?: string;

	get customer_name(): string {
		if (this.customer_type === CustomerTypeEnum.PRIVATE) {
			return `${this.first_name} ${this.last_name}`;
		} else {
			return this.business_name;
		}
	}

	public isPrivate() {
		if (this.customer_type === CustomerTypeEnum.PRIVATE) {
			return true;
		}
	}
	public isBusiness() {
		if (this.customer_type === CustomerTypeEnum.BUSINESS) {
			return true;
		}
	}
}

export interface ExtraFieldContractHeaderPouchModel {}

export class MobeeCustomerDivisionPouchModel implements CustomerDivisionPouchModel {
	division?: string;
    payment_condition?: string;
    area_commercial?: string;
    order_causal?: string;
    method_shipping?: string;
    method_delivery?: string;
	code_agent: string[] = [];
	valid: boolean;
	status: "ENABLED" | "DISABLED";
}

export class ContractHeaderStateModel implements ContractHeaderPouchModel<ExtraFieldContractHeaderPouchModel> {
	date?: number = Date.now();
	code_agent: string = null;
	customer?: Customer = null;
	customer_operational_headquarter?: LocationModel = null;
	status?: ContractStatusEnum = ContractStatusEnum.DRAFT;
	custom_field?: ExtraFieldContractHeaderPouchModel = null;

	constructor(init?: Partial<ContractHeaderStateModel>) {
		Object.assign(this, init);
	}
	franchiser: FranchiserModel;
}

export class ContractStateModel implements ContractPouchModel<ExtraFieldContractHeaderPouchModel> {
	_id?: string;
	_rev?: string;
	type: string;
	header?: ContractHeaderStateModel = new ContractHeaderStateModel();
	body?: ContractBodyPouchModel = new ContractBodyPouchModel();
	footer?: ContractFooterPouchModel = new ContractFooterPouchModel();

	constructor(init?: Partial<ContractStateModel>) {
		Object.assign(this, init);
	}
}

export class MopedDataSheetModel implements BasePouchModel {
	_id?: string;
	_rev?: string;

	marca?: string;
	modello?: string;
	motore?: string;
	potenza?: string;
	fari?: string;
	prima_batteria?: string;
	carica_batterie?: string;
	omologazione?: string;
	tipo?: string;
	categoria?: string;
	pendenza_max?: string;
	gomma_ant?: string;
	gomma_post?: string;
	freno_ant?: string;
	freno_post?: string;
	carico_massimo?: string;
	sospensioni_ant?: string;
	sospensioni_post?: string;
	antifurto?: boolean;
}
