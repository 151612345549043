import { Route } from '@angular/router';
import { UserComponent } from '../page/b2b/user/user.component';
export namespace B2BRouteList {
	export const routes: Route[] = [
		{
            path: 'user',
            component: UserComponent,
            pathMatch: 'full'
        }
	];
}
