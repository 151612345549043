import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AgentCustomerListPouchModel, AgentCustomerPouchModel, BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { ContractPouchModel, ContractStateModel, ExtraFieldContractHeaderPouchModel } from '../../../model/state/contract-state.model';
import { ContractListFilterModel } from '../../../service/pouchdb/filter/contract-filter.model';
import { StateFeature } from '../../../state';
import { ContractListActionEnum, ContractListStateAction } from '../../../state/contract-list/contract-list.actions';
import { ContractComponent } from '../../commons/contract/contract.component';

@Component({
  selector: 'contract-agent',
  templateUrl: './contract-agent.component.html',
  styleUrls: ['./contract-agent.component.scss']
})
export class ContractAgentComponent implements OnInit, OnDestroy {
  userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.AGENT;

  agent$: Observable<BaseStateModel<BodyTablePouchModel>>;
  agent: BodyTablePouchModel;

  agentCustomer$: Observable<BaseStateModel<AgentCustomerListPouchModel>>;
  agentCustomer: BaseStateModel<AgentCustomerListPouchModel>;

  contracts$: Observable<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>>;
  contractList: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>;

  initialLoadSub: Subscription;

  initialFilter: ContractListFilterModel;

  @ViewChild(ContractComponent, {
    static: true
  })
  private contractComponent: ContractComponent;

  constructor(
    private store: Store<any>,
    private route: ActivatedRoute,
  ) {
    this.agent$ = this.store.select(StateFeature.getAgentState);
    this.agentCustomer$ = this.store.select(StateFeature.getAgentCustomer);
    this.contracts$ = this.store.select(StateFeature.getContractListState);
  }

	_filterCompanyNames(value: string): AgentCustomerPouchModel[] {
		const filterValue = value.toLowerCase();
		return this.agentCustomer.data.values
			.filter(company => company.business_name.toLowerCase().includes(filterValue))
			.sort((a, b) => (a.business_name < b.business_name ? -1 : 1));
	}

  ngOnInit() {
    this.contractComponent.filteredCustomerList = this.contractComponent.companyNameCtrl.valueChanges.pipe(
      startWith(''),
      map(value => {
        return typeof value === 'string' ? value : value.business_name;
      }),
      map(company => {
        return company
          ? this._filterCompanyNames(company)
          : this.agentCustomer.data.values.sort((a, b) => (a.business_name < b.business_name ? -1 : 1)).slice();
      })
    );

    if (!this.initialLoadSub) {
      this.initialLoadSub = this.agent$
        .pipe(
          take(1),
          mergeMap((agent: BaseStateModel<BodyTablePouchModel>) => {
            this.agent = agent ? agent.data : null;
            this.initialFilter = this.agent ? { user_code: this.agent.code_item } : {};
            return this.agentCustomer$;
          }),
          take(1),
          mergeMap((agentCustomer: BaseStateModel<AgentCustomerListPouchModel>) => {
            this.agentCustomer = agentCustomer;
            return this.contracts$;
          }),
          filter(
            (contractList: BaseStateModel<ContractStateModel[]>) =>
              contractList && contractList.type !== ContractListActionEnum.LOAD_WITH_DETAIL
          ),
          map((contractList: BaseStateModel<ContractStateModel[]>) => {
            if (contractList.type === ContractListActionEnum.UPDATE) {
              this.contractList = contractList;
              return this.contractList;
            }
          })
        )
        .subscribe();
    }
  }

  loadContractList($event: BaseStateModel<ContractListFilterModel>) {
    this.store.dispatch(ContractListStateAction.load($event));
  }

  ngOnDestroy() {
    if (this.initialLoadSub) {
      this.initialLoadSub.unsubscribe();
    }
  }

}
