import { Route } from '@angular/router';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BasePouchModel, BodyTablePouchModel, CustomerPouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerAppConfig } from '../../../customer-app.config';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { BaseState } from '../../../model/state/base-state.model';
import {
	UserDetailModel,
	UserTypePermission,
	UserTypeContextModel,
	ContextPermission, LinkCodeModel
} from '../../../model/user.model';
import { AgentRouteList } from '../../../router/agent-routing';
import { B2BRouteList } from '../../../router/b2b-routing';
import { BackofficeRouteList } from '../../../router/backoffice-routing';
import { PermissionKeyEnum } from '../../../router/permission-key.enum';
import { StateFeature } from '../../../state';
import { AgentStateAction } from '../../../state/agent/agent.actions';
import { BackofficeCodeListStateAction } from '../../../state/backoffice/backoffice-code-list/backoffice-code-list.actions';
import { CustomerStateAction } from '../../../state/customer/customer.actions';
import { BodyTablePouchCustomModel } from '../../pouchdb/model/pouch-base.model';

export namespace PermissionTypeList {
	export const typeList: UserTypeContextModel[] = [
		{
			permission: PermissionKeyEnum.BO_DASHBOARD,
			route: BackofficeRouteList.routes,
			type: ContextApplicationItemCodeEnum.BACKOFFICE,
			action: BackofficeCodeListStateAction.load,
			state: StateFeature.getBackofficeCodeList,
			description: "backoffice Area"
		},
		{
			permission: PermissionKeyEnum.AGENT_DASHBOARD,
			route: AgentRouteList.routes,
			type: ContextApplicationItemCodeEnum.AGENT,
			action: AgentStateAction.load,
			state: StateFeature.getAgentState,
			description: 'Agent Area'
		},
		{
			permission: PermissionKeyEnum.B2B_DASHBOARD,
			route: B2BRouteList.routes,
			type: ContextApplicationItemCodeEnum.B2B,
			action: CustomerStateAction.load,
			state: StateFeature.getCustomerState,
			description: 'B2B Area'
		}
	];
}

export type ContextType = BodyTablePouchModel | CustomerPouchModel | BodyTablePouchCustomModel;

@Injectable()
export class UtilGuardService {
	constructor(private store: Store<any>, private appConfig: CustomerAppConfig) {}
	checkUserContext(user: UserDetailModel, context: number) {
		let hasContext: boolean = false;
		let currentContext: ContextPermission;
		if (context) {
			if (user && user.context_application_list) {
				for (let i = 0; i < user.context_application_list.length; i++) {
					if (user.context_application_list[i].code === context) {
						hasContext = true;
						currentContext = user.context_application_list[i];
						break;
					}
				}
			}
		}
		return { hasContext, currentContext };
	}

	checkUserPermission(user: UserDetailModel, permission: number) {
		let hasPermission: boolean = false;
		if (permission) {
			if (user && user.current_permission) {
				for (let i = 0; i < user.current_permission.length; i++) {
					if (user.current_permission[i] === permission) {
						hasPermission = true;
						break;
					}
				}
			}
		}
		return hasPermission;
	}

	retrievePermissionRoute(
		user: UserDetailModel,
		permissionTypeList?: UserTypeContextModel[]
	): UserTypeContextModel[] {
		if (!permissionTypeList) {
			permissionTypeList = this.retrieveContextPermissionList();
		}
		const authPermissionList: UserTypeContextModel[] = [];
		permissionTypeList.forEach(permissionType => {
			const context = this.checkUserContext(user, permissionType.type);
			// this.checkUserPermission(user, permissionType.permission)
			if (
				context.hasContext
			) {
				const permission = new UserTypePermission(permissionType);
				permission.ambiguousRoute =
					context.currentContext.context_code_list && context.currentContext.context_code_list.length > 1
						? true
						: false;
				authPermissionList.push(permission);
			}
		});
		return authPermissionList;
	}

	checkAmbiguousPermission(data: UserTypeContextModel[]) {
		let ambiguousRoute = false;
		if (data.length > 1) {
			ambiguousRoute = true;
		} else {
			ambiguousRoute = data[0].ambiguousRoute;
		}
		return ambiguousRoute;
	}

	dispatchUserContext(permission: UserTypeContextModel, user: UserDetailModel, link?: string | number) {
		if (!link) {
			link = user.context_application_list[0].context_code_list[0].code;
		}
		if (permission.action) {
			const request: BasePouchModel = { _id: link.toString() };
			this.store.dispatch(permission.action(new BaseState(request)));
		} else {
			// TODO: error Label
			throw new Error('the permission action is not exist');
		}
	}

	retrieveContextPermissionList(): UserTypeContextModel[] {
		return PermissionTypeList.typeList.filter(permission => {
			return this.appConfig.config.permissionContext.includes(permission.type);
		});
	}

	retrieveContextPermission(key: ContextApplicationItemCodeEnum): UserTypeContextModel {
		return this.retrieveContextPermissionList().find(permission => {
			return permission.type === key;
		});
	}

	retrieveCurrentPermissionFromLinkCode(link_code: LinkCodeModel, user: UserDetailModel) {
		let current_link: ContextPermission;
		let permission: number[] = [];
		const current_contex = this.checkUserContext(user, link_code.context);
		if (current_contex.hasContext){
			current_link = current_contex.currentContext.context_code_list.find((link: ContextPermission) => {
				return link.code === link_code.code;
			});
		}
		if (current_link){
			const setPermission = new Set(permission.concat(current_link.permission).concat(user.permission));
			permission = Array.from(setPermission);
		}
		return permission;
	}

	filterRouteWithPermission(routeList: Route[], permissionList: number[]){
		const cloneRouteList: Route[] = [];
		for (let index = 0; index < routeList.length; index++) {
			const element = routeList[index];
			const currentPermission = permissionList.find(permission => {
				return permission === (element.data && element.data['key']) ? element.data['key'] : null;
			});
			if (currentPermission){
				cloneRouteList.push(element);
				if (element.children && element.children.length > 0){
					cloneRouteList[cloneRouteList.length - 1].children = this.filterRouteWithPermission(element.children, permissionList);
				}
			}
		}
		return cloneRouteList;
	}
}
