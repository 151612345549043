import { Component, OnInit } from '@angular/core';
import { ContextApplicationItemCodeEnum } from '../../../../enum/context-application-item-code.enum';

@Component({
  selector: 'company-detail-agent',
  templateUrl: './company-detail-agent.component.html',
  styleUrls: ['./company-detail-agent.component.scss']
})
export class CompanyDetailAgentComponent implements OnInit {

  userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.AGENT;

  constructor() { }

  ngOnInit() {
  }

}
