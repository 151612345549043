import { Component, Inject, OnInit, ViewChild, OnDestroy, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatStepper } from '@angular/material';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FranchiserModel, AgentCreateFullPipePayload } from '../../../model/state/agent-state.model';
import { BaseStateModel, BaseState } from '../../../model/state/base-state.model';
import { Observable, merge } from 'rxjs';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { filter, map, skipWhile, mergeMap } from 'rxjs/operators';
import { AgentListStateAction, AgentListActionEnum } from '../../../state/agent-list/agent-list.actions';
import { StateFeature } from '../../../state';
import { BodyTablePouchCustomModel } from '../../../service/pouchdb/model/pouch-base.model';
import { UserDetailModel, ContextPermission } from '../../../model/user.model';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { PermissionUtilService } from '../../../service/util/permission-util.service';
import { PermissionAuxiliaryTableStateModel } from '../../../model/state/permission-auxiliary-table.model';
import {
	UserManagementStateAction,
	UserManagementStateActionEnum
} from '../../../state/backoffice/user-management/user-management.actions';
import { UserManagementDialoRegistryModel } from '../../../model/user-management.model';
import {
	BackofficeCodeListActionEnum,
	BackofficeCodeListStateAction
} from '../../../state/backoffice/backoffice-code-list/backoffice-code-list.actions';

@Component({
	selector: 'dialog-registry-agent',
	templateUrl: './dialog-registry-agent.component.html',
	styleUrls: ['./dialog-registry-agent.component.scss'],
	providers: [SubscribeManagerService]
})
export class DialogRegistryAgentComponent implements OnInit, OnDestroy, AfterViewInit {
	@ViewChild('stepper', { static: false }) private myStepper: MatStepper;

	agentListState$ = this.store.select(StateFeature.getAgentListState);
	backofficeCodeListState$ = this.store.select(StateFeature.getBackofficeCodeList);

	user = <UserDetailModel>{
		permission: [],
		context_application_list: []
	};

	permissionAuxiliaryTableState$: Observable<BaseStateModel<PermissionAuxiliaryTableStateModel>> = this.store.select(
		StateFeature.getPermissionAuxiliaryTableState
	);

	userManagementState$: Observable<BaseStateModel<UserDetailModel[]>> = this.store.select(
		StateFeature.getUserManagementState
	);

	formFranchiser: FormGroup;
	formUser: FormGroup;

	isEditableFirstStep = true;
	isEditableSecondStep = true;

	contextApplicationItemCodeEnum = ContextApplicationItemCodeEnum;

	constructor(
		private store: Store<any>,
		private fb: FormBuilder,
		@Inject(MAT_DIALOG_DATA) public data: UserManagementDialoRegistryModel,
		private dialogRef: MatDialogRef<DialogRegistryAgentComponent>,
		private subscribeManagerService: SubscribeManagerService,
		public permissionUtilService: PermissionUtilService
	) {
		if (
			this.data.contextTypeAndApplicationLink.context_application_item_code ===
			ContextApplicationItemCodeEnum.BACKOFFICE
		) {
			this.createBackofficeCode();
		}
		// this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'component-state-flow');
		// this.subscribeManagerService.populate(this.subscribeUserManagementState().subscribe(), 'user-management-state');
		this.createForm();
	}

	ngOnInit() {}

	ngAfterViewInit() {
		this.subscribeManagerService.populate(this.subscribeContextCodeList().subscribe(), 'component-state-flow');
		this.subscribeManagerService.populate(this.subscribeUserManagementState().subscribe(), 'user-management-state');
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(AgentListStateAction.reset());
		this.store.dispatch(BackofficeCodeListStateAction.reset());
	}

	// subscribe
	subscribeContextCodeList() {
		const agentListState = this.agentListState$.pipe(
			filter(state => state && state.type === AgentListActionEnum.SAVE_CONTEXT_CODE_SUCCESS),
			map((state: BaseStateModel<BodyTablePouchCustomModel[]>) => state)
		);

		const backofficeCodeListState = this.backofficeCodeListState$.pipe(
			filter(state => state && state.type === BackofficeCodeListActionEnum.SAVE_CONTEXT_CODE_SUCCESS),
			map((state: BaseStateModel<BodyTablePouchCustomModel[]>) => state)
		);

		return merge(agentListState, backofficeCodeListState).pipe(
			skipWhile(state => !state),
			mergeMap((state: BaseStateModel<BodyTablePouchCustomModel[]>) => {
				const contextApplicationItem = <ContextPermission>{
					code: this.data.contextTypeAndApplicationLink.context_application_item_code,
					context_code_list: [
						{
							code: state.data[0].code_item
						}
					]
				};
				this.user.context_application_list = this.permissionUtilService.returnUpdatedFormContextApplicationListValue(
					this.user.context_application_list,
					this.data.contextTypeAndApplicationLink.context_application_item_code,
					contextApplicationItem
				);
				return this.permissionAuxiliaryTableState$;
			}),
			skipWhile(state => !(state && state.data && state.data.permission_list)),
			map((state: BaseStateModel<PermissionAuxiliaryTableStateModel>) => {
				// TODO: definire la politica con la quale le permission di PermissionAuxiliaryTableState
				// arrivino, non arrivino, arrivino solo alcune...
				// TODO: sistemare il salvataggio delle permission
				if (state.data.permission_list && state.data.permission_list.length > 0) {
					this.user.permission = state.data.permission_list
						.filter(i =>
							i.context_code.includes(
								this.data.contextTypeAndApplicationLink.context_application_item_code
							)
						)
						.map(i => i.code);
				}
				this.myStepper.next();
				return this.user;
			})
		);
	}

	subscribeUserManagementState(): Observable<UserDetailModel[]> {
		return this.userManagementState$.pipe(
			filter(state => state && state.type === UserManagementStateActionEnum.SAVE_LIST_SUCCESS),
			map((state: BaseStateModel<UserDetailModel[]>) => {
				this.dialogRef.close(true);
				return state.data;
			})
		);
	}

	createForm() {
		this.formFranchiser = this.fb.group({
			type: ['franchiser'],
			business_name: [null, { disabled: false, validators: Validators.required }],
			phone: [null, { disabled: false, validators: Validators.required }],
			vat_number: [null, { disabled: false, validators: [Validators.required, Validators.minLength(11)] }],
			rea_number: [null, { disabled: false, validators: Validators.required }],
			email_pec: [null, { disabled: false, validators: [Validators.required, Validators.email] }],
			legal_representative: [null, { disabled: false, validators: Validators.required }],
			address: [null, { disabled: false, validators: Validators.required }],
			zip_code: [null, { disabled: false, validators: [Validators.required, Validators.minLength(5)] }],
			locality: [null, { disabled: false, validators: Validators.required }],
			province: [null, { disabled: false, validators: [Validators.required, Validators.minLength(2)] }]
		});

		this.formUser = this.fb.group({
			first_name: [null, { disabled: false, validators: Validators.required }],
			last_name: [null, { disabled: false, validators: Validators.required }],
			email: [null, { disabled: false, validators: [Validators.required, Validators.email] }]
		});
	}

	createFranchiser() {
		if (this.formFranchiser.valid) {
			const formModelFranchiser = this.formFranchiser.value;
			const franchiser: FranchiserModel = {
				type: formModelFranchiser.type,
				business_name: formModelFranchiser.business_name,
				vat_number: formModelFranchiser.vat_number,
				rea_number: formModelFranchiser.rea_number,
				email_pec: formModelFranchiser.email_pec,
				legal_representative: formModelFranchiser.legal_representative,
				address: formModelFranchiser.address,
				zip_code: formModelFranchiser.zip_code,
				locality: formModelFranchiser.locality,
				province: formModelFranchiser.province ? formModelFranchiser.province.toUpperCase() : null,
				phone: formModelFranchiser.phone
			};

			const newAgentCreateFullPipe = <BaseStateModel<AgentCreateFullPipePayload>>{
				data: {
					type: 'agent',
					custom_field: {
						franchiser: franchiser
					}
				}
			};
			this.store.dispatch(AgentListStateAction.createContextCodeFullPipe(newAgentCreateFullPipe));
			this.isEditableFirstStep = false;
		}
	}

	createBackofficeCode() {
		const newBackofficeCode = <BaseStateModel<BodyTablePouchCustomModel>>{
			data: {
				type: 'backoffice'
			}
		};
		this.store.dispatch(BackofficeCodeListStateAction.createContextCode(newBackofficeCode));
	}

	createUser() {
		if (this.formUser.valid) {
			const formModelUser = this.formUser.value;
			const user = {
				first_name: formModelUser.first_name.trim(),
				last_name: formModelUser.last_name.trim(),
				email: formModelUser.email
			};
			this.user.first_name = user.first_name;
			this.user.last_name = user.last_name;
			this.user.username = user.email;
			this.store.dispatch(UserManagementStateAction.saveList(new BaseState([this.user])));
		}
	}

	closeDialog() {
		this.dialogRef.close();
	}
}
