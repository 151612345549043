import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {
	MatAccordion,
	MatAutocompleteSelectedEvent,
	MatDialog,
	MatExpansionPanel,
	MatSnackBar
} from '@angular/material';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import _ from 'lodash';
import { Observable } from 'rxjs';
import {
	debounceTime,
	distinctUntilChanged,
	filter,
	map,
	mergeMap,
	skipWhile,
	startWith,
	take,
	tap
} from 'rxjs/operators';
import { ContextApplicationItemCodeEnum } from '../../../../enum/context-application-item-code.enum';
import { ContractPaymentUnitEnum } from '../../../../enum/contract-payment-unit.enum';
import { ContractStatusEnum } from '../../../../enum/contract-status.enum';
import { CustomerTypeEnum } from '../../../../enum/customer-type.enum';
import { RegistryModeEnum } from '../../../../enum/registry-mode.enum';
import { RestBasePk } from '../../../../model/rest-base.model';
import { AgentStateModel } from '../../../../model/state/agent-state.model';
import { BaseState, BaseStateModel } from '../../../../model/state/base-state.model';
import {
	BankDataModel,
	ContractStateModel,
	Customer,
	LocationModel,
	MopedDataSheetModel
} from '../../../../model/state/contract-state.model';
import { StatusBarOrderService } from '../../../../service/status-bar-config/implementation/status-bar-order.service';
import { SubscribeManagerService } from '../../../../service/util/subscribe-manager.service';
import { UtilContractService } from '../../../../service/util/util-contract.service';
import { UtilService } from '../../../../service/util/util.service';
import { StateFeature } from '../../../../state';
import { ContractActionEnum, ContractStateAction } from '../../../../state/contract/contract.actions';
import { CustomerListStateAction } from '../../../../state/customer-list/customer-list.actions';
import { CustomerActionEnum, CustomerStateAction } from '../../../../state/customer/customer.actions';
import { MopedListActionEnum, MopedListStateAction } from '../../../../state/moped-list/moped-list.actions';
import { DialogConfirmComponent } from '../../../../widget/dialog/dialog-confirm/dialog-confirm.component';
import { DialogRegistryCustomerComponent } from '../../../../widget/dialog/dialog-registry-customer/dialog-registry-customer.component';
import { DialogSignatureComponent } from '../../../../widget/dialog/dialog-signature/dialog-signature.component';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { CONTRACT_TERMS } from '../../../../constants/contract.constants';
import { DialogContractTermsComponent } from '../../../../widget/dialog/dialog-contract-terms/dialog-contract-terms.component';

@Component({
	selector: 'contract-detail',
	templateUrl: './contract-detail.component.html',
	styleUrls: ['./contract-detail.component.scss'],
	providers: [SubscribeManagerService, StatusBarOrderService]
})
export class ContractDetailComponent implements OnInit, OnDestroy {
	@Input() userType: ContextApplicationItemCodeEnum;
	@ViewChild(MatAccordion, { static: false }) accordion: MatAccordion;
	@ViewChild('chooseMoped', { static: false }) chooseMoped: MatExpansionPanel;

	// Enum
	contractStatusEnum = ContractStatusEnum;
	contractPaymentUnitEnum = ContractPaymentUnitEnum;
	customerTypeEnum = CustomerTypeEnum;
	userContextEnum = ContextApplicationItemCodeEnum;

	// ngrx state : START
	customerList$: Observable<BaseStateModel<CustomerPouchModel[]>> = this.store.select(
		StateFeature.getCustomerListState
	);
	customerList: Customer[];

	contract$: Observable<BaseStateModel<ContractStateModel>> = this.store.select(StateFeature.getContractState);
	contract: ContractStateModel;

	agent$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(StateFeature.getAgentState);
	agent: AgentStateModel;

	mopedList$: Observable<BaseStateModel<MopedDataSheetModel[]>> = this.store.select(StateFeature.getMopedListState);
	mopedList: MopedDataSheetModel[];
	// ngrx state : END

	contractId: string;
	contractStatus: string;
	form: FormGroup;
	public minDate: Date = new Date();

	customerCtrl = new FormControl();
	operationalHeadquarterList: LocationModel[] = [];
	garagingList: LocationModel[] = [];
	bankAccountList: BankDataModel[] = [];
	filteredOptions: Observable<Customer[]>;
	defaultVat = 22;
	selectedCustomer: Customer;
	contractIsNew = false;
	accordionIsMulti = false;
	missingKey: any[];
	formIsDisabled = false;
	contractTerms: string;

	constructor(
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		private route: ActivatedRoute,
		private router: Router,
		private fb: FormBuilder,
		private utilService: UtilService,
		public statusBarOrderService: StatusBarOrderService,
		private dialog: MatDialog,
		public utilContractService: UtilContractService,
		public translate: TranslateService,
		private snackBar: MatSnackBar
	) {
		this.contractId = this.route.snapshot.paramMap.get('contractId');
		this.contractStatus = this.route.snapshot.paramMap.get('contractStatus');
		this.statusBarOrderService.selectOnly(this.contractStatus);
		this.retrieveSyncState<BodyTablePouchModel>(this.agent$).subscribe(res => {
			this.agent = res.data;
			this.contractTerms = this.contractTermsReplacePlaceholder();
		});

		this.createForm();
		this.store.dispatch(CustomerListStateAction.load(new BaseState(null)));
		this.store.dispatch(MopedListStateAction.load(new BaseState(null)));

		this.subscribeManagerService.populate(
			this.initMandatoryData().subscribe(
				res => {
					// Autocomplete company
					this.filteredOptions = this.customerCtrl.valueChanges.pipe(
						startWith(''),
						map(value => {
							return typeof value === 'string' ? value : value.customer_name + ' ' + value.vat_number;
						}),
						map(name => (name ? this._filter(name) : this.customerList.slice()))
					);
				},
				error => {
					console.error('something went wrong ', error);
					this.router.navigate(['/customer-not-found']);
				}
			),
			'contract-data'
		);
	}

	ngOnInit() {
		this.subscribeManagerService.populate(this.onChangeBaulettoGrafica(), 'baulettoGraficaSubscription');
	}

	private _filter(value: string): any[] {
		const filterValue = value.toLowerCase();
		if (this.customerList) {
			const val = this.customerList.filter(option => {
				return (
					(option.customer_name && option.customer_name.toLowerCase().includes(filterValue)) ||
					(option.vat_number && option.vat_number.toLowerCase().includes(filterValue))
				);
			});
			return val;
		}
		return null;
	}

	onCustomerSelected($event: MatAutocompleteSelectedEvent) {
		const customer: Customer = $event.option.value;
		if (customer) {
			this.selectedCustomer = customer;
			this.operationalHeadquarterList = customer.operational_headquarter;
			this.garagingList = customer.garaging;
			this.bankAccountList = customer.bank_data;
			// Lancia un evento di valueChanges sulla form per attivare l'autosave
			this.form.updateValueAndValidity({ onlySelf: false, emitEvent: true });
		}
	}

	clearCustomerSelection() {
		// resetta tutti i valori che dipendono dal customer selezionato
		// nel modello dati
		const cloneContract = _.cloneDeep(this.contract);
		cloneContract.header.customer = null;
		cloneContract.header.customer_operational_headquarter = null;
		cloneContract.body.bank_data = null;
		cloneContract.body.garaging = null;
		this.store.dispatch(ContractStateAction.save(new BaseState(cloneContract)));

		// nelle liste dei dropdown
		this.selectedCustomer = null;
		this.operationalHeadquarterList = null;
		this.garagingList = null;
		this.bankAccountList = null;

		// nell'autocomplete
		this.customerCtrl.setValue(null, { onlySelf: false, emitEvent: false });
	}

	displayFn(option: Customer): string {
		return option && option.business_name + ' - PIVA: ' + option.vat_number ? option.business_name : '';
	}

	onAttachmentResolverFileChange(e: any, field: string) {
		this.utilService.compressImage(e[0], 1200, 1200).then(compressed => {
			this.form.get(field).setValue(compressed);
		});
	}

	retrieveSyncState<T>(state$: Observable<BaseStateModel<T>>): Observable<BaseStateModel<T>> {
		return state$.pipe(
			skipWhile((state: BaseStateModel<T>) => !(state && state.data)),
			take(1),
			tap((state: BaseStateModel<T>) => {
				return state;
			})
		);
	}

	initMandatoryData() {
		return this.customerList$.pipe(
			filter(
				(customerList: BaseStateModel<Customer[]>) =>
					customerList && customerList.type !== CustomerActionEnum.LOAD
			),
			mergeMap((customerList: BaseStateModel<Customer[]>) => {
				if (customerList.type === CustomerActionEnum.ERROR) {
					throw new Error(CustomerActionEnum.ERROR);
				}
				if (customerList.data) {
					this.customerList = customerList.data;
				}
				return this.mopedList$;
			}),
			filter(
				(mopedList: BaseStateModel<MopedDataSheetModel[]>) =>
					mopedList && mopedList.type !== MopedListActionEnum.LOAD
			),
			mergeMap((mopedList: BaseStateModel<MopedDataSheetModel[]>) => {
				this.mopedList = mopedList.data;

				if (this.contractId === 'new') {
					if (!this.contractIsNew) {
						this.contractIsNew = true;
						this.store.dispatch(ContractStateAction.update(new BaseState(new ContractStateModel())));
					}
				} else {
					const requestContract: RestBasePk = {
						id: this.contractId
					};
					this.store.dispatch(ContractStateAction.load(new BaseState(requestContract)));
				}
				return this.contract$;
			}),
			filter(
				(contract: BaseStateModel<ContractStateModel>) => contract && contract.type !== ContractActionEnum.LOAD
			),
			map((contract: BaseStateModel<ContractStateModel>) => {
				if (contract.type === ContractActionEnum.ERROR) {
					throw new Error(ContractActionEnum.ERROR);
				}
				if (contract.type === ContractActionEnum.UPDATE) {
					if (this.contractIsNew && contract.data._id) {
						this.contractId = contract.data._id;
						this.router.navigate([`/contracts/${this.contractStatus}/${contract.data._id}`]);
						this.contractIsNew = false;
					}

					if (contract.data && contract.data.header.status !== this.contractStatus) {
						this.contractStatus = contract.data.header.status;
						this.statusBarOrderService.selectOnly(this.contractStatus);

						this.router.navigate([`/contracts/${this.contractStatus}/${contract.data._id}`]);
					}
					this.setContract(contract.data);
					this.setModelFormValueFromContract();
				}
				if (contract.type === ContractActionEnum.REMOVED) {
					this.router.navigate(['contracts/' + ContractStatusEnum.DRAFT]);
				}
				return this.contract;
			})
		);
	}

	setContract(contract: ContractStateModel) {
		this.contract = contract;
		if (this.contract.header.status !== ContractStatusEnum.DRAFT) {
			this.form.disable({ emitEvent: false });

			if (
				this.contract.header.status === ContractStatusEnum.TO_CONFIRM &&
				this.userType === this.userContextEnum.BACKOFFICE
			) {
				this.form.get('moped_id').enable({ emitEvent: false });
				this.form.get('moped_chassis').enable({ emitEvent: false });
				this.form.get('moped_plate').enable({ emitEvent: false });
			}
		} else if (!this.contract._id) {
			if (this.contract.header.code_agent) {
				this.contract.header.code_agent = this.agent.code_item;
			}
			if (this.contract.header.date) {
				this.contract.header.date = Date.now();
			}
		}
	}

	saveDraft() {
		const cloneContract = _.cloneDeep(this.contract);
		this.store.dispatch(ContractStateAction.save(new BaseState(cloneContract)));
	}

	// backofficer che inserisce matricola e conferma il contratto
	confirmContract() {
		const cloneContract = _.cloneDeep(this.contract);

		if (cloneContract.body.moped_chassis || cloneContract.body.moped_id || cloneContract.body.moped_plate) {
			cloneContract.header.status = ContractStatusEnum.COMPLETED;
			this.store.dispatch(ContractStateAction.save(new BaseState(cloneContract)));
		} else {
			const dialogRef = this.dialog.open(DialogConfirmComponent, {
				data: {
					title: 'Dati mancanti',
					text: "Manca l'identificativo del motorino"
				},
				disableClose: true
			});
			dialogRef.afterClosed().subscribe(res => {
				this.chooseMoped.open();
			});
		}
	}

	sendContract() {
		const values = this.form.value;
		console.log(this.form);
		this.missingKey = [];

		Object.keys(this.form.controls).forEach(key => {
			this.form.controls[key].markAsDirty();

			if (!this.form.controls[key].valid) {
				const field = this.translate.instant('contract.form.' + key);
				this.missingKey.push(field);
			}
		});

		if (this.form.valid) {
			this.showConfirmSendData();
		} else {
			this.accordionIsMulti = true;
			this.showAlertData();
		}
	}

	private showAlertData() {
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Dati mancanti',
				text: this.missingKey.join(', ')
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			this.accordionIsMulti = true;
			this.accordion.openAll();
		});
	}

	private showConfirmSendData() {
		const dialogRef = this.dialog.open(DialogConfirmComponent, {
			data: {
				title: 'Invio contratto',
				text: 'Sei sicuro di voler inviare il contratto?'
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				console.log(this.form);
				const cloneContract = _.cloneDeep(this.contract);

				if (cloneContract.body.moped_chassis || cloneContract.body.moped_id || cloneContract.body.moped_plate) {
					cloneContract.header.status = ContractStatusEnum.COMPLETED;
				} else {
					cloneContract.header.status = ContractStatusEnum.TO_CONFIRM;
				}

				this.store.dispatch(ContractStateAction.save(new BaseState(cloneContract)));
				this.addCodeAgentToCustomer();

				this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: 5000,
					data: {
						message: `Contratto inviato correttamente`
					}
				});
			}
		});
	}

	contractTermsReplacePlaceholder(): string {
		let contractTerms: string = CONTRACT_TERMS;
		const franchiser = this.agent.franchiser;
		const legal_headquarter = `${franchiser.address} - ${franchiser.zip_code} ${franchiser.locality} (${franchiser.province})`;

		const placeholders = [
			{ key: '{{franchiser_pec}}', value: franchiser.email_pec },
			{ key: '{{franchiser_business_name}}', value: franchiser.business_name },
			{ key: '{{franchiser_legal_headquarter}}', value: legal_headquarter },
			{ key: '{{franchiser_legal_representative}}', value: franchiser.legal_representative },
			{ key: '{{franchiser_vat_number}}', value: franchiser.vat_number },
			{ key: '{{franchiser_rea_number}}', value: franchiser.rea_number }
		];

		placeholders.forEach(placeholder => {
			contractTerms = contractTerms.split(placeholder.key).join(placeholder.value);
		});

		return contractTerms;
	}

	addCodeAgentToCustomer() {
		// Verifico se l'array code_agent è inizializzato (dovrebbe, doppio check)
		if (!this.selectedCustomer.code_agent) {
			this.selectedCustomer.code_agent = [];
		}
		// Se l'uid dell'agent non è presente allora lo aggiunge
		if (!this.selectedCustomer.code_agent.includes(this.agent.code_item)) {
			this.selectedCustomer.code_agent.push(this.agent.code_item);
			this.store.dispatch(CustomerStateAction.save(new BaseState(this.selectedCustomer)));
			this.store.dispatch(CustomerListStateAction.load(new BaseState(null)));
		}
	}

	onChangeBaulettoGrafica() {
		const topboxGraphicTypeField = this.form.get('topbox_graphic_type');
		return this.form.get('topbox_graphic').valueChanges.subscribe(res => {
			if (res) {
				topboxGraphicTypeField.setValidators([Validators.required]);
			} else {
				topboxGraphicTypeField.setValue(null);
				topboxGraphicTypeField.setValidators(null);
			}
		});
	}

	createForm() {
		this.form = this.fb.group({
			status: [this.contractStatusEnum.DRAFT, { validators: Validators.required }],
			customer_operational_headquarter: [null, { validators: Validators.required }],

			// Scegli il mezzo
			moped_data_sheet: [null, { validators: Validators.required }],
			moped_id: [null, { updateOn: 'blur' }],
			moped_chassis: [null, { updateOn: 'blur' }],
			moped_plate: [null, { updateOn: 'blur' }],

			winter_kit: [null, {}],
			topbox_back: [null, {}],
			topbox_front: [null, {}],
			second_battery: [null, {}],

			topbox_graphic: [null, {}],
			topbox_graphic_type: [null, {}],
			good_color: [null, { validators: Validators.required }],
			insurance_rca: [null, {}],
			insurance_theft_fire: [null, {}],
			insurance_vandalism: [null, {}],
			insurance_comprehensive: [null, {}],

			// Condizioni contrattuali
			contract_payment_unit: [null, { validators: [Validators.required] }],
			date_location_start: [new Date(), { validators: [Validators.required] }],
			location_period: [null, { validators: [Validators.required], updateOn: 'blur' }],
			km_max: [null, { validators: [Validators.required], updateOn: 'blur' }],
			garaging: [null, { validators: [Validators.required] }],
			support: ['light_service', {}],

			cost_lease: [null, { validators: [Validators.required], updateOn: 'blur' }],
			cost_lease_vat_incl: false,

			cost_delivery_withdrawal: [null, { validators: [Validators.required], updateOn: 'blur' }],
			cost_delivery_withdrawal_vat_incl: false,

			cost_optional: [null, { validators: [Validators.required], updateOn: 'blur' }],
			cost_optional_vat_incl: false,

			billing_term: ['monthly', { validators: [Validators.required] }],
			deposit_advance: [null, {}],
			deposit_after_verification: [null, {}],
			deposit_guarantee_bank: [null, {}],
			deposit_guarantee_letter: [null, {}],
			bank_data: [null, { validators: [Validators.required] }],

			// Firma
			legal_representative_name: [null, { validators: [Validators.required], updateOn: 'blur' }],
			legal_representative_mobile: [null, { validators: [Validators.required], updateOn: 'blur' }],
			// verification_code: [null, { disabled: isDisabled, validators: [Validators.required] }],
			sign: [null, { validators: [Validators.required] }],
			photo_front: [null, { validators: [Validators.required] }],
			photo_back: [null, { validators: [Validators.required] }]
		});

		// add dynamically privacy formControl
		this.utilContractService.privacyAcceptanceList.forEach(privacyAcceptance => {
			const validators = privacyAcceptance.required ? [Validators.required] : [];
			this.form.addControl(privacyAcceptance.code, new FormControl('', validators));
		});
	}

	setModelFormValueFromContract() {
		// console.log('setModelFormValueFromContract');

		const customer: Customer = this.contract.header.customer;
		let selectedCustomerOperationalHeadquarter: LocationModel = null;
		let selectedGaraging: LocationModel = null;
		let selectedBankData: BankDataModel = null;

		if (customer) {
			const customerFromRef: Customer = this.customerList.find(x => x._id === customer._id);
			if (customerFromRef) {
				this.selectedCustomer = customerFromRef;
				this.operationalHeadquarterList = customerFromRef.operational_headquarter;
				if (this.operationalHeadquarterList) {
					const customer_operational_headquarter: LocationModel = this.contract.header
						.customer_operational_headquarter;
					selectedCustomerOperationalHeadquarter = this.operationalHeadquarterList.find(
						x => customer_operational_headquarter && x.code === customer_operational_headquarter.code
					);
				}

				this.garagingList = customerFromRef.garaging;
				if (this.garagingList) {
					const garaging: LocationModel = this.contract.body.garaging;
					selectedGaraging = this.garagingList.find(x => garaging && x.code === garaging.code);
				}

				this.bankAccountList = customerFromRef.bank_data;
				if (this.bankAccountList) {
					const bankData: BankDataModel = this.contract.body.bank_data;
					selectedBankData = this.bankAccountList.find(x => bankData && x.code === bankData.code);
				}
			}
		}

		const selectedMoped: MopedDataSheetModel = this.mopedList.find(
			x => this.contract.body.moped_data_sheet && x._id === this.contract.body.moped_data_sheet._id
		);

		this.form.patchValue(
			{
				// Dati contratto
				status: this.contract.header.status,
				customer_operational_headquarter: selectedCustomerOperationalHeadquarter,

				// Scegli il mezzo
				moped_data_sheet: selectedMoped,
				moped_id: this.contract.body.moped_id,
				moped_chassis: this.contract.body.moped_chassis,
				moped_plate: this.contract.body.moped_plate,
				winter_kit: this.contract.body.optionals.winter_kit,
				topbox_back: this.contract.body.optionals.topbox_back,
				topbox_front: this.contract.body.optionals.topbox_front,
				topbox_graphic: this.contract.body.customization.topbox_graphic,
				topbox_graphic_type: this.contract.body.customization.topbox_graphic_type,
				good_color: this.contract.body.customization.good_color,
				insurance_rca: this.contract.body.insurance.insurance_rca,
				insurance_theft_fire: this.contract.body.insurance.insurance_theft_fire,
				insurance_vandalism: this.contract.body.insurance.insurance_vandalism,
				insurance_comprehensive: this.contract.body.insurance.insurance_comprehensive,

				// Condizioni contrattuali
				contract_payment_unit: this.contract.body.contract_payment_unit,
				support: this.contract.body.support,
				date_location_start: this.contract.body.location_start,
				location_period: this.contract.body.location_period,
				km_max: this.contract.body.km_max,

				garaging: selectedGaraging,

				cost_lease: this.contract.body.payment.location.amount,
				cost_lease_vat_incl: this.contract.body.payment.location.vat_incl,

				cost_delivery_withdrawal: this.contract.body.payment.delivery.amount,
				cost_delivery_withdrawal_vat_incl: this.contract.body.payment.delivery.vat_incl,

				cost_optional: this.contract.body.payment.optional.amount,
				cost_optional_vat_incl: this.contract.body.payment.optional.vat_incl,

				billing_term: this.contract.body.billing_term,
				deposit_advance: this.contract.body.deposit.deposit_advance,
				deposit_after_verification: this.contract.body.deposit.deposit_after_verification,
				deposit_guarantee_bank: this.contract.body.deposit.deposit_guarantee_bank,
				deposit_guarantee_letter: this.contract.body.deposit.deposit_guarantee_letter,
				bank_data: selectedBankData,

				// Firma
				legal_representative_name: this.contract.footer.legal_representative.name,
				legal_representative_mobile: this.contract.footer.legal_representative.mobile,
				sign: this.contract.footer.sign,
				photo_front: this.contract.footer.photo_front,
				photo_back: this.contract.footer.photo_back,

				privacyAcceptance_0: this.contract.footer.privacyAcceptance_0 ? true : null,
				privacyAcceptance_1: this.contract.footer.privacyAcceptance_1 ? true : null,
				privacyAcceptance_2: this.contract.footer.privacyAcceptance_2 ? true : null,
				privacyAcceptance_3: this.contract.footer.privacyAcceptance_3 ? true : null,
				privacyAcceptance_4: this.contract.footer.privacyAcceptance_4 ? true : null
			},
			{ emitEvent: false }
		);

		// set validators based on data
		this.updateBankdataValidators();

		if (!this.subscribeManagerService.hasSubscription('change-form')) {
			this.onChangeForm();
		}
	}

	onChangeForm() {
		this.subscribeManagerService.populate(
			this.form.valueChanges
				.pipe(
					debounceTime(500),
					distinctUntilChanged()
				)
				.subscribe(async formChange => {
					const formModel = this.form.getRawValue();
					const cloneContract = _.cloneDeep(this.contract);
					if (this.userType === this.userContextEnum.AGENT) {
						cloneContract.header.code_agent = this.agent.code_item;
						cloneContract.header.franchiser = this.agent.franchiser;
					}

					// Dati contratto
					cloneContract.header.status = formModel.status;
					cloneContract.header.customer = this.selectedCustomer;

					const isChangedOperationalHeadquarter = !_.isEqual(
						cloneContract.header.customer_operational_headquarter,
						formModel.customer_operational_headquarter
					);
					cloneContract.header.customer_operational_headquarter = formModel.customer_operational_headquarter;

					// Scegli il mezzo
					cloneContract.body.moped_data_sheet = formModel.moped_data_sheet;
					cloneContract.body.moped_id = formModel.moped_id;
					cloneContract.body.moped_chassis = formModel.moped_chassis;
					cloneContract.body.moped_plate = formModel.moped_plate;
					cloneContract.body.optionals.winter_kit = formModel.winter_kit;
					cloneContract.body.optionals.topbox_back = formModel.topbox_back;
					cloneContract.body.optionals.topbox_front = formModel.topbox_front;
					cloneContract.body.optionals.second_battery = formModel.second_battery;

					cloneContract.body.customization.topbox_graphic = formModel.topbox_graphic;
					cloneContract.body.customization.topbox_graphic_type = formModel.topbox_graphic_type;
					cloneContract.body.customization.good_color = formModel.good_color;

					cloneContract.body.insurance.insurance_rca = formModel.insurance_rca;
					cloneContract.body.insurance.insurance_theft_fire = formModel.insurance_theft_fire;
					cloneContract.body.insurance.insurance_vandalism = formModel.insurance_vandalism;
					cloneContract.body.insurance.insurance_comprehensive = formModel.insurance_comprehensive;

					// Condizioni contrattuali
					cloneContract.body.contract_payment_unit = formModel.contract_payment_unit;
					cloneContract.body.support = formModel.support;

					cloneContract.body.location_start = formModel.date_location_start;
					cloneContract.body.location_period = parseInt(formModel.location_period, 10);

					cloneContract.body.garaging = isChangedOperationalHeadquarter
						? formModel.customer_operational_headquarter
						: formModel.garaging;
					// Add selected customer_operational_headquarter to garaging
					if (!_.some(cloneContract.header.customer.garaging, cloneContract.body.garaging)) {
						cloneContract.header.customer.garaging.push(cloneContract.body.garaging);
						this.store.dispatch(CustomerStateAction.save(new BaseState(cloneContract.header.customer)));
					}

					cloneContract.body.km_max = formModel.km_max ? +formModel.km_max.toFixed(2) : 0;

					cloneContract.body.payment.location.amount = formModel.cost_lease
						? +formModel.cost_lease.toFixed(2)
						: 0;
					cloneContract.body.payment.location.vat = this.defaultVat;
					cloneContract.body.payment.location.vat_incl = formModel.cost_lease_vat_incl;

					cloneContract.body.payment.delivery.amount = formModel.cost_delivery_withdrawal
						? +formModel.cost_delivery_withdrawal.toFixed(2)
						: 0;
					cloneContract.body.payment.delivery.vat = this.defaultVat;
					cloneContract.body.payment.delivery.vat_incl = formModel.cost_delivery_withdrawal_vat_incl;

					cloneContract.body.payment.optional.amount = formModel.cost_optional
						? +formModel.cost_optional.toFixed(2)
						: 0;
					cloneContract.body.payment.optional.vat = this.defaultVat;
					cloneContract.body.payment.optional.vat_incl = formModel.cost_optional_vat_incl;

					cloneContract.body.payment.total = +this.utilContractService.getTotalAmount(
						cloneContract.body.payment
					);

					cloneContract.body.billing_term = formModel.billing_term;

					cloneContract.body.deposit.deposit_advance = formModel.deposit_advance;
					cloneContract.body.deposit.deposit_after_verification = formModel.deposit_after_verification;
					cloneContract.body.deposit.deposit_guarantee_bank = formModel.deposit_guarantee_bank;
					cloneContract.body.deposit.deposit_guarantee_letter = formModel.deposit_guarantee_letter;

					cloneContract.body.bank_data = formModel.bank_data;

					// Firma
					cloneContract.footer.legal_representative.name = formModel.legal_representative_name;
					cloneContract.footer.legal_representative.mobile = formModel.legal_representative_mobile;

					// Contract conditions
					if (this.userType === this.userContextEnum.AGENT) {
						cloneContract.footer.contractTerms = this.contractTerms;
					}

					// cloneContract.body.verification_code = formModel.verification_code;
					cloneContract.footer.sign = formModel.sign;
					cloneContract.footer.photo_front = formModel.photo_front;
					cloneContract.footer.photo_back = formModel.photo_back;

					(cloneContract.footer.privacyAcceptance_0 = formModel.privacyAcceptance_0
						? this.utilContractService.privacyAcceptanceList.find(x => x.code === 'privacyAcceptance_0')
						: null),
						(cloneContract.footer.privacyAcceptance_1 = formModel.privacyAcceptance_1
							? this.utilContractService.privacyAcceptanceList.find(x => x.code === 'privacyAcceptance_1')
							: null),
						(cloneContract.footer.privacyAcceptance_2 = formModel.privacyAcceptance_2
							? this.utilContractService.privacyAcceptanceList.find(x => x.code === 'privacyAcceptance_2')
							: null),
						(cloneContract.footer.privacyAcceptance_3 = formModel.privacyAcceptance_3
							? this.utilContractService.privacyAcceptanceList.find(x => x.code === 'privacyAcceptance_3')
							: null),
						(cloneContract.footer.privacyAcceptance_4 = formModel.privacyAcceptance_4
							? this.utilContractService.privacyAcceptanceList.find(x => x.code === 'privacyAcceptance_4')
							: null),
						this.store.dispatch(ContractStateAction.save(new BaseState(cloneContract)));
				}),
			'change-form'
		);
	}

	updateBankdataValidators() {
		const bankDataField = this.form.get('bank_data');
		if (this.form.get('deposit_advance').value) {
			bankDataField.setValidators(null);
		} else {
			bankDataField.setValidators([Validators.required]);
		}
		bankDataField.updateValueAndValidity({ onlySelf: true, emitEvent: false });
	}

	printContract() {
		const externalUrl = '/#/print/' + this.contractId;
		window.open(externalUrl, '_blank');
	}

	// Download document from couchdb
	// downloadFile(route: string, filename: string = null): void {
	// 	this.utilContractService.downloadFile(route, filename);
	// }

	// Dialog
	openDialogContractTerms() {
		const dialogRef = this.dialog.open(DialogContractTermsComponent, {
			data: {
				title: 'Contratto di locazione',
				text: this.contract.footer.contractTerms ? this.contract.footer.contractTerms : this.contractTerms
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		// Set contract terms checked
		if (this.contract.header.status === ContractStatusEnum.DRAFT) {
			this.form.controls['privacyAcceptance_0'].setValue(true);
		}
	}

	openDialogCustomer() {
		const newRecord: Customer = new Customer();
		newRecord.address = new LocationModel();
		const dialogRef = this.dialog.open(DialogRegistryCustomerComponent, {
			data: {
				title: 'Azienda',
				context: this.utilContractService.contextDialog.customerOnly,
				customer: newRecord,
				legalHeadquarter: newRecord.address,
				mode: RegistryModeEnum.ADD
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res && res.customer) {
				this.store.dispatch(CustomerStateAction.save(new BaseState(res.customer)));
				this.store.dispatch(CustomerListStateAction.load(new BaseState(null)));
			}
		});
	}

	openDialogOperationalHeadquarter() {
		const newRecord: LocationModel = new LocationModel();
		const dialogRef = this.dialog.open(DialogRegistryCustomerComponent, {
			data: {
				title: 'Sede operativa / Residenza',
				context: this.utilContractService.contextDialog.operationalHeadquarterOnly,
				customer: this.selectedCustomer,
				operationalHeadquarter: newRecord
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res && res.operationalHeadquarter) {
				if (this.selectedCustomer) {
					if (!this.selectedCustomer.operational_headquarter) {
						this.selectedCustomer.operational_headquarter = [];
					}
					this.selectedCustomer.operational_headquarter.push(res.operationalHeadquarter);
					this.selectedCustomer.garaging.push(res.operationalHeadquarter);
					this.store.dispatch(CustomerStateAction.save(new BaseState(this.selectedCustomer)));
					this.store.dispatch(CustomerListStateAction.load(new BaseState(null)));

					// autoselezione
					this.contract.header.customer_operational_headquarter = res.operationalHeadquarter;
					this.contract.body.garaging = res.operationalHeadquarter;
					this.store.dispatch(ContractStateAction.save(new BaseState(this.contract)));
				} else {
					console.error(
						'Nessun customer correntemente selezionata a cui aggiungere operational_headquarter',
						res.operationalHeadquarter
					);
				}
			}
		});
	}

	openDialogGaraging() {
		const newRecord: LocationModel = new LocationModel();
		const dialogRef = this.dialog.open(DialogRegistryCustomerComponent, {
			data: {
				title: 'Luogo di rimessaggio',
				context: this.utilContractService.contextDialog.garagingOnly,
				customer: this.selectedCustomer,
				garaging: newRecord
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res && res.garaging) {
				if (this.selectedCustomer) {
					if (!this.selectedCustomer.garaging) {
						this.selectedCustomer.garaging = [];
					}
					this.selectedCustomer.garaging.push(res.garaging);
					this.store.dispatch(CustomerStateAction.save(new BaseState(this.selectedCustomer)));
					this.store.dispatch(CustomerListStateAction.load(new BaseState(null)));

					// autoselezione
					this.contract.body.garaging = res.garaging;
					this.store.dispatch(ContractStateAction.save(new BaseState(this.contract)));
				} else {
					console.error('Nessun customer correntemente selezionata a cui aggiungere garaging', res.garaging);
				}
			}
		});
	}

	openDialogBankData() {
		const newRecord: BankDataModel = new BankDataModel();
		const dialogRef = this.dialog.open(DialogRegistryCustomerComponent, {
			data: {
				title: 'Dati bancari',
				context: this.utilContractService.contextDialog.bankOnly,
				bank: newRecord,
				customer: this.selectedCustomer,
				mode: RegistryModeEnum.ADD
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res && res.bank) {
				if (this.selectedCustomer) {
					if (!this.selectedCustomer.bank_data) {
						this.selectedCustomer.bank_data = [];
					}
					this.selectedCustomer.bank_data.push(res.bank);
					this.store.dispatch(CustomerStateAction.save(new BaseState(this.selectedCustomer)));
					this.store.dispatch(CustomerListStateAction.load(new BaseState(null)));

					this.contract.body.bank_data = res.bank;
					this.store.dispatch(ContractStateAction.save(new BaseState(this.contract)));
				} else {
					console.error('Nessun customer correntemente selezionata a cui aggiungere bank_data', res.bank);
				}
			}
		});
	}

	openDialogSign() {
		const dialogRef = this.dialog.open(DialogSignatureComponent, {
			data: {
				title: 'Inserisci la firma'
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res) {
				this.form.get('sign').setValue(res);
			}
		});
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
		this.store.dispatch(CustomerListStateAction.reset());
		this.store.dispatch(ContractStateAction.reset());
		this.store.dispatch(MopedListStateAction.reset());
	}
}
