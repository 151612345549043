import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { RestBasePk } from '../../../../model/rest-base.model';
import { BaseState, BaseStateModel } from '../../../../model/state/base-state.model';
import {
	ContractStateModel,
	Customer,
	LocationModel,
	BankDataModel,
	MopedDataSheetModel
} from '../../../../model/state/contract-state.model';
import { StateFeature } from '../../../../state';
import { ContractStateAction } from '../../../../state/contract/contract.actions';
import { UtilContractService } from '../../../../service/util/util-contract.service';
import { AddressPouchModel } from '@saep-ict/pouch_agent_models';
import { FranchiserModel } from '../../../../model/state/agent-state.model';
import { DatePipe } from '@angular/common';
import { ContractStatusEnum } from '../../../../enum/contract-status.enum';
import { TranslatePipe, TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'print-contract',
	templateUrl: './print-contract.component.html',
	styleUrls: ['./print-contract.component.scss'],
	providers: [TranslatePipe]
})
export class PrintContractComponent implements OnInit, OnDestroy {
	contract$: Observable<BaseStateModel<ContractStateModel>> = this.store.select(StateFeature.getContractState);
	contract: ContractStateModel;
	contractSubscription: Subscription;
	customer: Customer;
	legalHeadquarter: LocationModel = new LocationModel();
	operationalHeadquarter: LocationModel = new LocationModel();
	contractId: string;
	contractIdShort: string;
	footerFranchiser: string;
	condizioniContrattuali: string;
	franchiser: FranchiserModel;
	bankData: BankDataModel;
	moped_data_sheet: MopedDataSheetModel;
	subtitlePageList: string[] = [];

	constructor(
		private store: Store<any>,
		private route: ActivatedRoute,
		public utilContractService: UtilContractService,
		private datePipe: DatePipe,
		private translatePipe: TranslatePipe
	) {
		this.contractSubscription = this.contract$
			.pipe(
				filter(contract => !!contract),
				take(1)
			)
			.subscribe(res => {
				this.contract = res.data;
				console.log(this.contract);

				let subtitlePage = 'ID Contratto: ' + this.contractIdShort;
				subtitlePage += ' | Data: ' + this.datePipe.transform(this.contract.header.date, 'dd/MM/yyyy');
				subtitlePage +=
					' | Stato: ' + this.translatePipe.transform('contract.status.' + this.contract.header.status);

				const franchiser = this.contract.header.franchiser;
				this.subtitlePageList.push(
					subtitlePage,
					`
						Noleggiatore: ${franchiser.business_name} con sede legale in ${franchiser.address} - ${franchiser.zip_code} ${franchiser.locality} (${franchiser.province}), partita IVA ${franchiser.vat_number}, numero REA ${franchiser.rea_number}, qui rappresentata dal suo legale ${franchiser.legal_representative}, di seguito denominata NOLEGGIATORE
					`
				);

				this.customer = Object.assign(new Customer(), res.data.header.customer);
				this.franchiser = this.contract.header.franchiser;
				const franchiserLegalHeadquarter = `${this.franchiser.address} - ${this.franchiser.zip_code} ${this.franchiser.locality} (${this.franchiser.province})`;

				this.operationalHeadquarter = this.contract.header.customer_operational_headquarter;
				this.legalHeadquarter = this.setAddressfromAddressPouchModel(this.customer.address);

				this.bankData = this.contract.body.bank_data ? this.contract.body.bank_data : null;
				this.moped_data_sheet = this.contract.body.moped_data_sheet
					? this.contract.body.moped_data_sheet
					: null;

				this.condizioniContrattuali = this.contract.footer.contractTerms;
				this.footerFranchiser = `${this.franchiser.business_name} - sede legale: ${franchiserLegalHeadquarter} - p.iva ${this.franchiser.vat_number} - Email PEC ${this.franchiser.email_pec}`;
			});
	}

	ngOnInit() {
		this.contractId = this.route.snapshot.paramMap.get('contractId');
		this.contractIdShort = this.utilContractService.formatHexOrderCode(this.contractId);
		const requestContract: RestBasePk = {
			id: this.contractId
		};
		this.store.dispatch(ContractStateAction.load(new BaseState(requestContract)));
	}

	setAddressfromAddressPouchModel(address: AddressPouchModel) {
		let formattedAddress: LocationModel = new LocationModel();
		return (formattedAddress = {
			address: address.address,
			locality: address.locality,
			province: address.province,
			zip_code: address.zip_code
		});
	}

	// Download document from couchdb
	downloadFile(route: string, filename: string = null): void {
		this.utilContractService.downloadFile(route, filename);
	}

	ngOnDestroy() {
		this.contractSubscription.unsubscribe();
	}
}
