import { NgModule } from '@angular/core';
import { DropAreaDirective } from './drop-area.directive';
import { ClickOutsideDirective } from './click-outside.directive';
import { StopPropagationDirective } from './stop-propagation.directive';
import { DebounceTimeHandlerDirective } from './debounce-time-handler.directive';
import { OnlyNumbersDirective } from './only-numbers.directive';
import { UppercaseDirective } from './uppercase.directive';

@NgModule({
  declarations: [
    DropAreaDirective,
    ClickOutsideDirective,
    StopPropagationDirective,
    DebounceTimeHandlerDirective,
    OnlyNumbersDirective,
    UppercaseDirective
  ],
  exports: [
    DropAreaDirective,
    ClickOutsideDirective,
    StopPropagationDirective,
    DebounceTimeHandlerDirective,
    OnlyNumbersDirective,
    UppercaseDirective
  ]
})
export class DirectivesModule { }
