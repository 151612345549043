import { createReducer, on } from "@ngrx/store";
import { BaseStateModel } from "../../model/state/base-state.model";
import { TypedAction } from "@ngrx/store/src/models";
import { AgentCustomerListPouchModel } from "@saep-ict/pouch_agent_models";
import { AgentCustomerStateAction, AgentCustomerActionEnum } from "./agent-customer.actions";

export namespace AgentCustomerStateReducer {
	export const initialState: BaseStateModel<AgentCustomerListPouchModel> = null;

	const _reducer = createReducer(
		initialState,
		on(AgentCustomerStateAction.load, state => state),
		on(AgentCustomerStateAction.update, (state, agentCustomer) => agentCustomer),
		on(AgentCustomerStateAction.completed, (state, agentCustomer) => ({ data: state ? state.data : null, type: agentCustomer.type })),
		on(AgentCustomerStateAction.reset, () => initialState),
		on(AgentCustomerStateAction.error, (state, agentCustomer) => ({ data: state ? state.data : null, type: agentCustomer.type }))
	);

	export function reducer(state: BaseStateModel<AgentCustomerListPouchModel>, action: TypedAction<AgentCustomerActionEnum>) {
		return _reducer(state, action);
	}
}
