import { Component, OnInit, Input, ViewChild, ElementRef, HostBinding, AfterViewInit, OnDestroy } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { MediaReplayService } from '../../service/util/media-replay.service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { MediaChange } from '@angular/flex-layout';

@Component({
	selector: '[list-wrapper]',
	templateUrl: './list-wrapper.component.html',
	styleUrls: ['./list-wrapper.component.scss']
})
export class ListWrapperComponent implements OnInit, AfterViewInit, OnDestroy {
	// se true comporta la visualizzazione del sidenav aperta in init e in mode 'side'
	@Input() filterOpened: boolean;
	// se il valore viene passato
	// - l'host prende una dimensione fissa e l'overflow viene gestito dalla perfect-scroll
	// - height viene sovrascritto
	@Input() minHeight: string;
	@HostBinding('style.height') hostHeight;
	@ViewChild('sidenav', { static: false }) sidenav: MatSidenav;
	// permette di calcolare la distanza tra l'host e il top del device
	@ViewChild('offsetAnchor', { static: false, read: ElementRef }) offsetAnchor: ElementRef;

	mode: string;
	height: string = null;
	private _mediaSubscription: Subscription;

	constructor(public mediaReplayService: MediaReplayService) {}

	ngOnInit() {
		if (this.filterOpened) {
			this.mode = 'side';
		} else {
			this.mode = 'over';
		}
	}

	ngAfterViewInit() {
		this._mediaSubscription = this.mediaReplayService.media$
			.pipe(debounceTime(100))
			.subscribe((change: MediaChange) => {
				if (this.minHeight) {
					this.hostHeight = this.minHeight;
					this.height = this.minHeight;
				} else {
					this.height =
						this.mediaReplayService.getRemainingDeviceHeight(this.offsetAnchor.nativeElement) + 'px';
				}
			});
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
	}
}
