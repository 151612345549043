import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material';
import { ITdDataTableColumn } from '@covalent/core';
import { Store } from '@ngrx/store';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import { PaymentPouchModel } from '@saep-ict/pouch_agent_models/model/table-list-pouch.model';
import { Observable, Subscription } from 'rxjs';
import { map, skipWhile } from 'rxjs/operators';
import { RegistryModeEnum } from '../../../../../enum/registry-mode.enum';
import { BaseState, BaseStateModel } from '../../../../../model/state/base-state.model';
import { Customer } from '../../../../../model/state/contract-state.model';
import { CustomerDetailFullDetailPaymentsColumnService as CompanyDetailFullDetailPaymentColumnService } from '../../../../../service/td-data-table/implementation/customer-detail.service';
import { UtilContractService } from '../../../../../service/util/util-contract.service';
import { StateFeature } from '../../../../../state';
import { CustomerStateAction } from '../../../../../state/customer/customer.actions';
import { DialogRegistryCustomerComponent } from '../../../../../widget/dialog/dialog-registry-customer/dialog-registry-customer.component';
import _ from 'lodash';
import { SubscribeManagerService } from '../../../../../service/util/subscribe-manager.service';

@Component({
	selector: 'company-detail-full-detail',
	templateUrl: './company-detail-full-detail.component.html',
	styleUrls: ['./company-detail-full-detail.component.scss'],
	providers: [SubscribeManagerService]
})
export class CompanyDetailFullDetailComponent implements OnInit, OnDestroy {
	customer$: Observable<BaseStateModel<CustomerPouchModel>> = this.store.select(StateFeature.getCustomerState);
	customer: Customer;

	paymentList: PaymentPouchModel[];
	get paymentColumns(): ITdDataTableColumn[] {
		return this.paymentColumnService.columns;
	}

	constructor(
		private store: Store<any>,
		private paymentColumnService: CompanyDetailFullDetailPaymentColumnService,
		private utilContractService: UtilContractService,
		private dialog: MatDialog,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(
			this.customer$
				.pipe(
					skipWhile((customerState: BaseStateModel<CustomerPouchModel>) => !customerState),
					map((customerState: BaseStateModel<CustomerPouchModel>) => {
						this.customer = Object.assign(new Customer(), customerState.data);
						this.customer.isBusiness();
					})
				)
				.subscribe(),
			'customer'
		);
	}

	ngOnInit() {}

	editCustomer() {
		const editRecord: Customer = _.cloneDeep(this.customer);

		const dialogRef = this.dialog.open(DialogRegistryCustomerComponent, {
			data: {
				title: 'Modifica dati',
				context: this.utilContractService.contextDialog.customerOnly,
				customer: editRecord,
				legalHeadquarter: editRecord.address,
				mode: RegistryModeEnum.EDIT
			},
			panelClass: 'dialog-medium',
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res && res.customer) {
				console.log(res);
				this.store.dispatch(CustomerStateAction.save(new BaseState(res.customer)));
			}
		});
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
