import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { AgentCustomerListPouchModel } from '@saep-ict/pouch_agent_models';

export class AgentCustomerPouch extends AbstractPouchdbMethod {
	getAgentCustomer(agentCode: string): Promise<AgentCustomerListPouchModel> {
		return new Promise((resolve, reject) => {
			this.get(`agent_customer_${agentCode}`)
				.then((doc: AgentCustomerListPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
