import { BaseTableListService } from "../base-table-list.service";
import { ITdDataTableColumn } from "@covalent/core";

export class OrderDashboardColumnService extends BaseTableListService {
  constructor() {
    super();
  }
  columns: ITdDataTableColumn[] = [
    {
      name: "header.status",
      label: "",
      width: 50
    },
    {
      name: "header.order_number",
      label: "Ordine #",
    },
    {
      name: "company",
      label: "Azienda"
    },
    {
      name: "header.date",
      label: "Data creazione",
      numeric: true,
      width: 150
    },
    {
      name: "header.first_evasion_date",
      label: "Data consegna",
      numeric: true,
      width: 150
    },
    {
      name: "tot",
      label: "Importo",
      numeric: true,
      width: 150
    }
	];
}
