import { HttpClient, HttpEventType, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { ResourceHandlerHttpClient } from '@ngx-resource/handler-ngx-http';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

export class ResourceUrlEncodedHandler extends ResourceHandlerHttpClient {

    private readonly defaultMessage: string;

    constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    request(request: any): Observable<any> {
        if (request['headers'].get('content-type') === 'application/x-www-form-urlencoded') {
            if (request['body'] !== null) {
                request['body'] = this.JSON_to_URLEncoded(request['body']);
            }
        }
        return this.httpClient
            .request(request)
            .pipe(filter((resp: HttpResponse<object>) => resp.type === HttpEventType.Response));
    }

    JSON_to_URLEncoded(element: any, parent = true, key?, list?) {
        list = list || [];
        if (element && parent && typeof (element) === 'object' && element.constructor.name !== 'ArrayBuffer') {
            for (const idx of Object.keys(element)) {
                this.JSON_to_URLEncoded(element[idx], false, key ? key + '[' + idx + ']' : idx, list);
            }
        } else {
            if (element) {
                typeof (element) === 'object' && element.constructor.name !== 'ArrayBuffer' ? list.push(key + '=' + encodeURIComponent(JSON.stringify(element))) : list.push(key + '=' + encodeURIComponent(element));
            } else {
                list.push(key + '=' + null);
            }
        }
        return list.join('&');
    }

}

export interface IHttpRequestInit {
    headers?: HttpHeaders;
    params?: HttpParams;
    responseType?: 'arraybuffer' | 'blob' | 'json' | 'text';
    withCredentials?: boolean;
}


