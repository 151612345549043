import { AfterViewInit, Component, ElementRef, Inject, OnInit, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SignatureFieldComponent } from '../../signature-field/signature-field.component';
/**
 * Documentation : https://www.npmjs.com/package/signature_pad
 */
@Component({
	selector: 'dialog-signature',
	templateUrl: './dialog-signature.component.html',
	styleUrls: ['./dialog-signature.component.scss']
})
export class DialogSignatureComponent implements OnInit, AfterViewInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data,
		public fb: FormBuilder,
		public dialogRef: MatDialogRef<DialogSignatureComponent>
	) {
		this.form = fb.group({
			signatureField: ['', Validators.required]
		});
	}

	public form: FormGroup;

	@ViewChildren(SignatureFieldComponent) public sigs: QueryList<SignatureFieldComponent>;
	@ViewChildren('sigContainer') public sigContainer: QueryList<ElementRef>;

	ngOnInit() {}

	public ngAfterViewInit() {
		this.beResponsive();
		this.setOptions();
	}

	// set the dimensions of the signature pad canvas
	public beResponsive() {
		this.size(this.sigContainer.first, this.sigs.first);
	}

	public size(container: ElementRef, sig: SignatureFieldComponent) {
		sig.signaturePad.set('canvasWidth', container.nativeElement.clientWidth);
		sig.signaturePad.set('canvasHeight', container.nativeElement.clientWidth/2);
	}

	public setOptions() {
		this.sigs.first.signaturePad.set('backgroundColor', 'rgb(255,255,255)');
	}

	public submit() {
		this.dialogRef.close(this.sigs.first.signature);
	}

	public clear() {
		this.sigs.first.clear();
	}
}
