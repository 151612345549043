import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { AgentListFilterModel } from '../../service/pouchdb/filter/agent-filter.model';
import { AgentCreateFullPipePayload } from '../../model/state/agent-state.model';
import { BodyTablePouchCustomModel } from '../../service/pouchdb/model/pouch-base.model';

export enum AgentListActionEnum {
	UPDATE = '[Agent list] Update',
	LOAD = '[Agent list] Load',
	LOAD_DETAIL_WITH_FRANCHISER = '[Agent list] Load With franchiser',
	RESET = '[Agent list] Reset',
	ERROR = '[Agent list] Error',
	CREATE_CONTEXT_CODE_FULL_PIPE = '[Agent list] Save Context Code Full Pipe',
	SAVE_CONTEXT_CODE_SUCCESS = '[Agent list] Save Context Code Success',
	SAVE_FRANCHISER_DETAIL = '[Agent list] Save Franchiser Detail'
}

export namespace AgentListStateAction {
	export const load = createAction(AgentListActionEnum.LOAD, props<BaseStateModel<AgentListFilterModel>>());
	export const loadDetailWithFranchiser = createAction(AgentListActionEnum.LOAD_DETAIL_WITH_FRANCHISER, props<BaseStateModel<AgentCreateFullPipePayload>>());
	export const update = createAction(AgentListActionEnum.UPDATE, props<BaseStateModel<BodyTablePouchCustomModel[]>>());
	export const reset = createAction(AgentListActionEnum.RESET);
	export const error = createAction(AgentListActionEnum.ERROR, props<BaseStateModel<any>>());
	export const createContextCodeFullPipe = createAction(
        AgentListActionEnum.CREATE_CONTEXT_CODE_FULL_PIPE,
        props<BaseStateModel<AgentCreateFullPipePayload>>()
    );
    export const saveContextCodeSuccess = createAction(
        AgentListActionEnum.SAVE_CONTEXT_CODE_SUCCESS,
        props<BaseStateModel<AgentCreateFullPipePayload>>()
	);
	export const saveFranchiserDetail = createAction(
        AgentListActionEnum.SAVE_FRANCHISER_DETAIL,
        props<BaseStateModel<AgentCreateFullPipePayload>>()
    );
}
