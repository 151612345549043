import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { CompanyDetailBankComponent } from '../commons/company/company-detail/company-detail-bank/company-detail-bank.component';
import { CompanyDetailDestinationComponent } from '../commons/company/company-detail/company-detail-destination/company-detail-destination.component';
import { CompanyDetailFullDetailComponent } from '../commons/company/company-detail/company-detail-full-detail/company-detail-full-detail.component';
import { CompanyDetailOperationalHeadquarterComponent } from '../commons/company/company-detail/company-detail-operational-headquarter/company-detail-operational-headquarter.component';
import { PageCommonsModule } from '../commons/page-commons.module';
import { CompanyAgentComponent } from './company-agent/company-agent.component';
import { CompanyDetailAgentComponent } from './company-agent/company-detail-agent/company-detail-agent.component';
import { ContractAgentComponent } from './contract-agent/contract-agent.component';
import { ContractDetailAgentComponent } from './contract-agent/contract-detail-agent/contract-detail-agent.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { UserAgentComponent } from './user-agent/user-agent.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule],
	declarations: [
		DashboardComponent,
		ContractAgentComponent,
		ContractDetailAgentComponent,
		CompanyAgentComponent,
		CompanyDetailAgentComponent,
		UserAgentComponent,
	],
	exports: [
		DashboardComponent,
	],
	entryComponents: [
		DashboardComponent,
		ContractAgentComponent,
		ContractDetailAgentComponent,
		CompanyAgentComponent,
		CompanyDetailAgentComponent,
		CompanyDetailDestinationComponent,
		CompanyDetailBankComponent,
		CompanyDetailOperationalHeadquarterComponent,
		CompanyDetailFullDetailComponent,
		UserAgentComponent
	],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageAgentModule {}
