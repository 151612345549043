import { createReducer, on } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { TypedAction } from '@ngrx/store/src/models';
import { DestinationPouchModel } from '@saep-ict/pouch_agent_models';
import { DestinationListStateAction, DestinationListActionEnum } from './destination-list.actions';

export namespace DestinationListStateReducer {
	export const initialState: BaseStateModel<DestinationPouchModel[]> = null;

	const _reducer = createReducer(
		initialState,
		on(DestinationListStateAction.load, state => state),
		on(DestinationListStateAction.update, (state, destinationList) => destinationList),
		on(DestinationListStateAction.completed, (state, destination) => ({
			data: state ? state.data : null,
			type: destination.type
		})),
		on(DestinationListStateAction.reset, () => initialState),
		on(DestinationListStateAction.error, (state, destination) => ({
			data: state ? state.data : null,
			type: destination.type
		}))
	);

	export function reducer(
		state: BaseStateModel<DestinationPouchModel[]>,
		action: TypedAction<DestinationListActionEnum>
	) {
		return _reducer(state, action);
	}
}
