import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginAuthRequestModel } from '../../../../model/user.model';
import { AuthService } from '../../../../service/rest/auth.service';


@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  userLogin: LoginAuthRequestModel;
  hide = true;

  constructor(
    private router: Router,
    private authService: AuthService,
    private fb: FormBuilder,
  ) {
    this.createForm();
  }

  ngOnInit() {
  }

  createForm() {
    this.form = this.fb.group({
      email: ["", Validators.required],
      password: ["", Validators.required]
    });
  }

  onFormSubmit() {
    if (this.form.valid) {
      this.userLogin = this.form.value;
      this.authService.login(this.userLogin);
    }
  }
}
