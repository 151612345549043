import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { PouchErrorResponse } from '../../service/pouchdb/model/pouch-base-response.model';
import { ContractListActionEnum, ContractListStateAction } from './contract-list.actions';
import {
	ContractStateModel,
	ContractPouchModel,
	ExtraFieldContractHeaderPouchModel
} from '../../model/state/contract-state.model';
import { ContractListFilterModel } from '../../service/pouchdb/filter/contract-filter.model';

@Injectable()
export class ContractListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractListActionEnum.LOAD),
			mergeMap((action: BaseStateModel<ContractListFilterModel>) => from(this.getContractList(action))),
			map((contract: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>) =>
				ContractListStateAction.update(contract)
			),
			catchError((error, caught) => {
				this.store.dispatch(ContractListStateAction.error(null));
				return caught;
			})
		)
	);

	loadWithDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContractListActionEnum.LOAD_WITH_DETAIL),
			mergeMap((action: BaseStateModel<ContractListFilterModel>) => from(this.getContractListWithDetail(action))),
			map((contracts: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>) =>
				ContractListStateAction.update(contracts)
			),
			catchError((error, caught) => {
				this.store.dispatch(ContractListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getContractList(
		filter: BaseStateModel<ContractListFilterModel>
	): Promise<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>> {
		return this.pouchDbMobeeAgentiAdapter.contractPouch
			.getContractList(filter)
			.then(async contractList => {
				return new BaseState(contractList, filter.pagination, filter.sort);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getContractListWithDetail(
		filter: BaseStateModel<ContractListFilterModel>
	): Promise<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>> {
		try {
			return await this.getContractList(filter);
		} catch (err) {
			console.log(err);
			throw new Error(err.error + err.reason);
		}
	}
}
