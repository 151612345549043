import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, take } from 'rxjs/operators';

import { BaseState, BaseStateModel } from '../../../../model/state/base-state.model';
import { UserDetailModel } from '../../../../model/user.model';
import { SubscribeManagerService } from '../../../../service/util/subscribe-manager.service';
import { StateFeature } from '../../../../state';
import { UserActionEnum, UserStateAction } from '../../../../state/user/user.actions';
import {
	PasswordVerifyModel,
	TypePasswordVerifyEnum
} from '../../../../widget/compound-input-interface/password-verify/password-verify.component';
import { MatSnackBarWrapperComponent } from '../../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { UserPasswordUpdateModel } from '../../../../model/password.model';

@Component({
	selector: 'password-update',
	templateUrl: './password-update.component.html',
	styleUrls: ['./password-update.component.scss'],
	providers: [SubscribeManagerService]
})
export class PasswordUpdateComponent implements OnInit, OnDestroy {
	typePasswordVerifyEnum = TypePasswordVerifyEnum;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: BaseStateModel<UserDetailModel>;

	constructor(
		private router: Router,
		private snackBar: MatSnackBar,
		private store: Store<any>,
		private subscribeManagerService: SubscribeManagerService,
		public translate: TranslateService
	) {
		this.user$.pipe(take(1)).subscribe(res => {
			if (res && res.data) {
				this.user = res;
			}
		});
	}

	ngOnInit() {}

	openSnackBar(message: string, action = 'Ok', duration = 3000) {
		this.snackBar.open(message, action, {
			duration: duration
		});
	}

	userPasswordUpdate(data: PasswordVerifyModel) {
		const userPasswordUpdateRequest: BaseStateModel<UserPasswordUpdateModel> = new BaseState({
			oldPassword: data.oldPassword,
			password: data.newPassword,
			id: this.user.data.id
		});

		this.store.dispatch(UserStateAction.password_change(userPasswordUpdateRequest));
		this.subscribeManagerService.populate(
			this.initObservableState().subscribe(
				res => {
					this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
						duration: 5000,
						data: {
							message: `Modifica password Utente avvenuta con successo`
						}
					});
					this.router.navigate(['/']);
				},
				error => {
					console.warn(error);
					this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
						duration: 5000,
						data: {
							message: `Siamo spiacenti, Qualcosa è andato storto.`
						}
					});
				}
			),
			'user-password-change'
		);
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}

	initObservableState(): Observable<BaseStateModel<UserDetailModel>> {
		return this.user$.pipe(
			filter(
				(user: BaseStateModel<UserDetailModel>) =>
					user && (user.type === UserActionEnum.UPDATE || user.type === UserActionEnum.ERROR)
			),
			map((user: BaseStateModel<UserDetailModel>) => {
				if (user.type === UserActionEnum.UPDATE) {
					return user;
				} else if (user.type === UserActionEnum.ERROR) {
					throw new Error(UserActionEnum.ERROR);
				}
			}),
			catchError(error => {
				throw new Error(error);
			})
		);
	}
}
