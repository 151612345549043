import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { AgentCustomerListPouchModel } from '@saep-ict/pouch_agent_models';
import { RestBaseGuid, RestBasePk } from '../../model/rest-base.model';

export enum AgentCustomerActionEnum {
	UPDATE = '[agent customer] Update',
	LOAD = '[agent customer] Load',
	COMPLETED = '[agent customer] Completed',
	RESET = '[agent customer] Reset',
	ERROR = '[agent customer] Error'
}

export namespace AgentCustomerStateAction {
	export const load = createAction(AgentCustomerActionEnum.LOAD, props<BaseStateModel<RestBasePk>>());
	export const update = createAction(
		AgentCustomerActionEnum.UPDATE,
		props<BaseStateModel<AgentCustomerListPouchModel>>()
	);
	export const completed = createAction(AgentCustomerActionEnum.COMPLETED, props<BaseStateModel<any>>());
	export const reset = createAction(AgentCustomerActionEnum.RESET);
	export const error = createAction(AgentCustomerActionEnum.ERROR, props<BaseStateModel<any>>());
}
