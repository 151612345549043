import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

// import { DEAFULT_Customer_CODE } from '../../constants/Customer.constants';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { PouchErrorResponse } from '../../service/pouchdb/model/pouch-base-response.model';
import { CustomerListActionEnum, CustomerListStateAction } from './customer-list.actions';
import { CustomerListFilterModel } from '../../service/pouchdb/filter/customer-filter.model';
import { Customer } from '../../model/state/contract-state.model';

@Injectable()
export class CustomerListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CustomerListActionEnum.LOAD),
			mergeMap((action: BaseStateModel<CustomerListFilterModel>) => from(this.getCustomerList(action))),
			map((user: BaseStateModel<CustomerPouchModel[]>) => CustomerListStateAction.update(user)),
			catchError((error, caught) => {
				this.store.dispatch(CustomerListStateAction.error(null));
				return caught;
			})
		)
	);

	loadFilterd$ = createEffect(() =>
		this.actions$.pipe(
			ofType(CustomerListActionEnum.LOAD_FILTERED),
			mergeMap((action: BaseStateModel<CustomerListFilterModel>) => from(this.getCustomerListFiltered(action))),
			map((user: BaseStateModel<CustomerPouchModel[]>) => CustomerListStateAction.update(user)),
			catchError((error, caught) => {
				this.store.dispatch(CustomerListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getCustomerList(
		filter: BaseStateModel<CustomerListFilterModel>
	): Promise<BaseStateModel<CustomerPouchModel[]>> {
		return this.pouchDbMobeeAgentiAdapter.customerPouch
			.getCustomerList(filter)
			.then(async customerList => {
				const customerListSetted = [];
				customerList.forEach(x => {
					customerListSetted.push(new Customer(x));
				});
				return new BaseState(customerListSetted, filter.pagination, filter.sort);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}

	async getCustomerListFiltered(
		filter: BaseStateModel<CustomerListFilterModel>
	): Promise<BaseStateModel<CustomerPouchModel[]>> {
		return this.pouchDbMobeeAgentiAdapter.customerPouch
			.getCustomerList(filter)
			.then(async CustomerList => {
				// CustomerList = CustomerList.filter(
				// 	Customer =>
				// 		Customer.code === DEAFULT_Customer_CODE
				// 		// ||
				// 		// (Customer.code_agent && Customer.code_agent.includes(filter.data.code_agent))
				// )
				// ;
				return new BaseState(CustomerList, filter.pagination, filter.sort);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
