import { createAction, props } from "@ngrx/store";
import { BaseStateModel } from '../../model/state/base-state.model';
import { PermissionAuxiliaryTableStateModel } from "../../model/state/permission-auxiliary-table.model";

export enum PermissionAuxiliaryTableActionEnum {
    UPDATE = '[permission auxiliary table] Update',
    LOAD = '[permission auxiliary table] Load',
    COMPLETED = '[permission auxiliary table] Completed',
    RESET = '[auxiliary table] Reset',
    RESET_ITEM = '[auxiliary table] Reset Item',
    ERROR = '[auxiliary table] Error'
}

export namespace PermissionAuxiliaryTableStateAction{
    export const load = createAction(
        PermissionAuxiliaryTableActionEnum.LOAD
    );
    export const update = createAction(
        PermissionAuxiliaryTableActionEnum.UPDATE,
        props<BaseStateModel<PermissionAuxiliaryTableStateModel>>()
    );
    export const completed = createAction(
        PermissionAuxiliaryTableActionEnum.COMPLETED,
        props<BaseStateModel<any>>()
    );
    export const reset = createAction(
        PermissionAuxiliaryTableActionEnum.RESET
    );
    export const error = createAction(
        PermissionAuxiliaryTableActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
