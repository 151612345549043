import { Component, OnInit } from '@angular/core';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';

@Component({
	selector: 'user-backoffice',
	templateUrl: './user-backoffice.component.html',
	styleUrls: ['./user-backoffice.component.scss']
})
export class UserBackofficeComponent implements OnInit {
	userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.BACKOFFICE;

	constructor() {}

	ngOnInit() {}
}
