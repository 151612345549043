import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import {
	ITdDataTableColumn,
	ITdDataTableSortChangeEvent,
	TdDataTableComponent,
	TdDataTableSortingOrder
} from '@covalent/core';
import { Store } from '@ngrx/store';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { RegistryModeEnum } from '../../../../../enum/registry-mode.enum';
import { BaseState, BaseStateModel } from '../../../../../model/state/base-state.model';
import { Customer, LocationModel } from '../../../../../model/state/contract-state.model';
import { CustomerDetailOperationalHeadquarterColumnService } from '../../../../../service/td-data-table/implementation/customer-detail.service';
import { UtilContractService } from '../../../../../service/util/util-contract.service';
import { UtilCustomerService } from '../../../../../service/util/util-customer.service';
import { UtilService } from '../../../../../service/util/util.service';
import { StateFeature } from '../../../../../state';
import { CustomerStateAction } from '../../../../../state/customer/customer.actions';
import { DialogRegistryCustomerComponent } from '../../../../../widget/dialog/dialog-registry-customer/dialog-registry-customer.component';
import _ from 'lodash';
import { SubscribeManagerService } from '../../../../../service/util/subscribe-manager.service';

@Component({
	selector: 'company-detail-operational-headquarter',
	templateUrl: './company-detail-operational-headquarter.component.html',
	styleUrls: ['./company-detail-operational-headquarter.component.scss'],
	providers: [SubscribeManagerService]
})
export class CompanyDetailOperationalHeadquarterComponent implements OnInit, OnDestroy {
	@ViewChild('dataTable', { static: false }) dataTable: TdDataTableComponent;

	registryModeEnum = RegistryModeEnum;

	private customer$: Observable<BaseStateModel<CustomerPouchModel>> = this.store.select(
		StateFeature.getCustomerState
	);
	public customer: Customer;

	operationalHeadquarterList: LocationModel[];

	get operationalHeadquarterColumn(): ITdDataTableColumn[] {
		return this.customerDetailOperationalHeadquarterColumnService.columns;
	}
	constructor(
		private store: Store<any>,
		private customerDetailOperationalHeadquarterColumnService: CustomerDetailOperationalHeadquarterColumnService,
		private dialog: MatDialog,
		private utilContractService: UtilContractService,
		private utilCustomerService: UtilCustomerService,
		private utilService: UtilService,
		private subscribeManagerService: SubscribeManagerService
	) {
		this.subscribeManagerService.populate(
			this.customer$.subscribe(res => {
				this.customer = Object.assign(new Customer(), res.data);
				this.operationalHeadquarterList = this.customer.operational_headquarter;
			}),
			'customer'
		);
	}

	ngOnInit() {}

	openDialogOperationalHeadquarter(operationalHeadquarter: LocationModel = null, mode: RegistryModeEnum) {
		const newRecord: LocationModel = new LocationModel();
		const editRecord: LocationModel = Object.assign(new LocationModel(), operationalHeadquarter);

		let selectedCustomer = Object.assign(new Customer(), _.cloneDeep(this.customer));

		const dialogRef = this.dialog.open(DialogRegistryCustomerComponent, {
			data: {
				title: 'Sede operativa / Domicilio',
				context: this.utilContractService.contextDialog.operationalHeadquarterOnly,
				customer: selectedCustomer,
				operationalHeadquarter: mode === RegistryModeEnum.ADD ? newRecord : editRecord,
				mode: mode
			},
			disableClose: true
		});
		dialogRef.afterClosed().subscribe(res => {
			if (res && res.operationalHeadquarter) {
				if (selectedCustomer) {
					if (!selectedCustomer.operational_headquarter) {
						selectedCustomer.operational_headquarter = [];
					}
					selectedCustomer = this.utilCustomerService.handleCustomer(
						mode,
						selectedCustomer,
						'operational_headquarter',
						res.operationalHeadquarter
					);
					this.store.dispatch(CustomerStateAction.save(new BaseState(selectedCustomer)));
					this.dataTable.refresh();
				} else {
					console.error(
						'Nessun customer correntemente selezionata a cui aggiungere operationalHeadquarter',
						res.operationalHeadquarter
					);
				}
			}
		});
	}

	// table
	sortChange(sortEvent: ITdDataTableSortChangeEvent): void {
		const order = sortEvent.order === TdDataTableSortingOrder.Ascending ? 'asc' : 'desc';
		this.operationalHeadquarterList = this.utilService.sortArrayByKey(
			this.operationalHeadquarterList,
			sortEvent.name,
			order
		);
		this.dataTable.refresh();
	}

	columnsChange(e: ITdDataTableColumn) {
		this.operationalHeadquarterColumn.find((column: ITdDataTableColumn) => column.name === e.name).hidden =
			e.hidden;
		this.dataTable.refresh();
	}

	ngOnDestroy() {
		this.subscribeManagerService.destroy();
	}
}
