import { createAction, props } from "@ngrx/store";
import { BaseStateModel } from "../../model/state/base-state.model";
import { RestBasePk } from '../../model/rest-base.model';
import { AuxiliaryTableStateModel } from "../../model/state/auxiliary-table-list";

export enum AuxiliaryTableActionEnum {
    UPDATE = '[auxiliary table] Update',
    LOAD = '[auxiliary table] Load',
    COMPLETED = '[auxiliary table] Completed',
    RESET = '[auxiliary table] Reset',
    ERROR = '[auxiliary table] Error'
}

export namespace AuxiliaryTableStateAction{
    export const load = createAction(
        AuxiliaryTableActionEnum.LOAD
    );
    export const update = createAction(
        AuxiliaryTableActionEnum.UPDATE,
        props<BaseStateModel<AuxiliaryTableStateModel>>()
    );
    export const completed = createAction(
        AuxiliaryTableActionEnum.COMPLETED,
        props<BaseStateModel<any>>()
    );
    export const reset = createAction(
        AuxiliaryTableActionEnum.RESET
    );
    export const error = createAction(
        AuxiliaryTableActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
