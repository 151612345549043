import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { ChartStateValuePouchModel, ListViewDataPouchModel } from '@saep-ict/pouch_agent_models';
import { OrderStatisticsList } from '@saep-ict/pouch_agent_models/model/chart-pouch.model';
import moment, { Moment } from 'moment';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';

import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { ChartService } from '../../service/chart/chart.service';
import { ChartFilterModel } from '../../service/pouchdb/filter/chart-filter.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { PouchErrorResponse } from '../../service/pouchdb/model/pouch-base-response.model';
import { ChartActionEnum, ChartStateAction } from './chart.action';

export interface DateRange {
	from?: Moment;
	to?: Moment;
}

@Injectable()
export class ChartEffects {
	get weekRange(): DateRange {
		const today = moment().endOf('day');
		const lastWeek = moment()
			.subtract(6, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastWeek,
			to: today
		};
		return result;
	}

	get twoWeekRange() {
		const today = moment().endOf('day');
		const lastTwoWeeks = moment()
			.subtract(13, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastTwoWeeks,
			to: today
		};
		return result;
	}

	get monthRange(): DateRange {
		const today = moment().endOf('day');
		const lastMonth = moment()
			.subtract(30, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastMonth,
			to: today
		};
		return result;
	}

	get twoMonthRange(): DateRange {
		const today = moment().endOf('day');
		const lastTwoMonths = moment()
			.subtract(59, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastTwoMonths,
			to: today
		};
		return result;
	}

	get threeMonthRange(): DateRange {
		const today = moment().endOf('day');
		const lastThreeMonths = moment()
			.subtract(89, 'days')
			.startOf('day');
		const result: DateRange = {
			from: lastThreeMonths,
			to: today
		};
		return result;
	}

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter,
		private chartService: ChartService
	) {}

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ChartActionEnum.LOAD),
			mergeMap((action: BaseStateModel<ChartFilterModel>) => from(this.getOrdersStatistics(action))),
			map((stats: BaseStateModel<OrderStatisticsList>) => ChartStateAction.update(stats)),
			catchError((error, caught) => {
				this.store.dispatch(ChartStateAction.error(null));
				return caught;
			})
		)
	);

	async getOrdersStatistics(action): Promise<BaseStateModel<OrderStatisticsList>> {
		const agentCode = action.data.agent_code;
		const allPromises: Promise<OrderStatisticsList>[] = [];
		// Total
		allPromises.push(
			this.pouchDbMobeeAgentiAdapter.orderStatisticsPouch
				.findAgentOrders(agentCode)
				.then((listViewOrders: ListViewDataPouchModel<ChartStateValuePouchModel>) => {
					return this.chartService.getTotalCountAndAverage(listViewOrders.rows.map(row => row.value));
				})
		);

		// Week
		allPromises.push(
			this.pouchDbMobeeAgentiAdapter.orderStatisticsPouch
				.findAgentOrdersByDateRange(
					agentCode,
					this.weekRange.from.format('x'),
					this.weekRange.to.format('x')
				)
				.then((listViewOrders: ListViewDataPouchModel<ChartStateValuePouchModel>) => {
					const level = 'week';
					const weekOrders: ChartStateValuePouchModel[] = listViewOrders.rows.map(row => row.value);
					const weekStats: OrderStatisticsList = this.chartService.getTotalCountAndAverage(
						weekOrders,
						level
					);
					this.chartService.addOrdersToOrderStatistics(weekOrders, weekStats, level);
					const dailyStats = this.chartService.getDailyStatistics(weekOrders, this.weekRange, level, true);
					this.chartService.addDailyStatsToOrderStatistics(dailyStats, weekStats, level);
					return weekStats;
				})
		);

		// two weeks
		allPromises.push(
			this.pouchDbMobeeAgentiAdapter.orderStatisticsPouch
				.findAgentOrdersByDateRange(
					agentCode,
					this.twoWeekRange.from.format('x'),
					this.twoWeekRange.to.format('x')
				)
				.then((listViewOrders: ListViewDataPouchModel<ChartStateValuePouchModel>) => {
					const level = 'two_week';
					const twoWeeksOrders: ChartStateValuePouchModel[] = listViewOrders.rows.map(row => row.value);
					const twoWeeksStats: OrderStatisticsList = this.chartService.getTotalCountAndAverage(
						twoWeeksOrders,
						level
					);
					this.chartService.addOrdersToOrderStatistics(twoWeeksOrders, twoWeeksStats, level);
					// const dailyStats = this.chartService.getDailyStatistics(twoWeeksOrders, this.twoWeekRange, level, true);
					// this.chartService.addDailyStatsToOrderStatistics(dailyStats, twoWeeksStats, level);
					return twoWeeksStats;
				})
		);

		// month
		allPromises.push(
			this.pouchDbMobeeAgentiAdapter.orderStatisticsPouch
				.findAgentOrdersByDateRange(
					agentCode,
					this.monthRange.from.format('x'),
					this.monthRange.to.format('x')
				)
				.then((listViewOrders: ListViewDataPouchModel<ChartStateValuePouchModel>) => {
					const level = 'month';
					const monthOrders: ChartStateValuePouchModel[] = listViewOrders.rows.map(row => row.value);
					const monthStats: OrderStatisticsList = this.chartService.getTotalCountAndAverage(
						monthOrders,
						level
					);
					this.chartService.addOrdersToOrderStatistics(monthOrders, monthStats, level);
					const dailyStats = this.chartService.getDailyStatistics(monthOrders, this.monthRange, level, true);
					this.chartService.addDailyStatsToOrderStatistics(dailyStats, monthStats, level);
					return monthStats;
				})
		);

		// two month
		allPromises.push(
			this.pouchDbMobeeAgentiAdapter.orderStatisticsPouch
				.findAgentOrdersByDateRange(
					agentCode,
					this.twoMonthRange.from.format('x'),
					this.twoMonthRange.to.format('x')
				)
				.then((listViewOrders: ListViewDataPouchModel<ChartStateValuePouchModel>) => {
					const level = 'two-month';
					const twoMonthsOrders: ChartStateValuePouchModel[] = listViewOrders.rows.map(row => row.value);
					const twoMonthsStats: OrderStatisticsList = this.chartService.getTotalCountAndAverage(
						twoMonthsOrders,
						level
					);
					this.chartService.addOrdersToOrderStatistics(twoMonthsOrders, twoMonthsStats, level);
					// const dailyStats = this.chartService.getDailyStatistics(monthOrders, this.monthRange, level, true);
					// this.chartService.addDailyStatsToOrderStatistics(dailyStats, monthStats, level);
					return twoMonthsStats;
				})
		);

		// three month
		allPromises.push(
			this.pouchDbMobeeAgentiAdapter.orderStatisticsPouch
				.findAgentOrdersByDateRange(
					agentCode,
					this.threeMonthRange.from.format('x'),
					this.threeMonthRange.to.format('x')
				)
				.then((listViewOrders: ListViewDataPouchModel<ChartStateValuePouchModel>) => {
					const level = 'three-month';
					const threeMonthsOrders: ChartStateValuePouchModel[] = listViewOrders.rows.map(row => row.value);
					const threeMonthsStats: OrderStatisticsList = this.chartService.getTotalCountAndAverage(
						threeMonthsOrders,
						level
					);
					this.chartService.addOrdersToOrderStatistics(threeMonthsOrders, threeMonthsStats, level);
					const dailyStats = this.chartService.getDailyStatistics(threeMonthsOrders, this.threeMonthRange, level, true);
					this.chartService.addDailyStatsToOrderStatistics(dailyStats, threeMonthsStats, level);
					return threeMonthsStats;
				})
		);

		return Promise.all(allPromises)
			.then((orderStatistics: OrderStatisticsList[]) => {
				const result = this.chartService.mergeStatistics(orderStatistics);
				return new BaseState(result);
			})
			.catch((err: PouchErrorResponse) => {
				throw new Error(err.error + err.reason);
			});
	}
}
