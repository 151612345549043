import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as screenfull from 'screenfull';
import { NavigationService } from '../../service/navigation/navigation.service';

@Component({
	selector: 'ms-toolbar',
	templateUrl: './toolbar.component.html',
	styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {
	@Input() sidenav: any;
	isFullscreen: boolean = false;

	constructor(
		public navigationService: NavigationService,
		private activatedRoute: ActivatedRoute
	){}

	ngOnInit() {}

	toggleFullscreen() {
		if (screenfull.enabled) {
			screenfull.toggle();
			this.isFullscreen = !this.isFullscreen;
		}
	}

	toggleIconSidenav() {
		this.navigationService.isIconSidenav = !this.navigationService.isIconSidenav;
	}

	isIconSidenav(): boolean {
		return this.navigationService.isIconSidenav;
	}
}
