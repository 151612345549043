import { Injectable } from '@angular/core';
import { ITdDataTableSortChangeEvent, TdDataTableSortingOrder } from '@covalent/core';

@Injectable({
	providedIn: 'root'
})
export class UtilService {
	replaceAll(valueToTest: string, regExp: RegExp, replacer: string = '') {
		if (!valueToTest) {
			return;
		}
		if (replacer) {
			replacer = '';
		}

		let res: string = valueToTest;

		while (regExp.test(res)) {
			res = res.replace(regExp, replacer);
		}

		return res.trim();
	}

	getEnumValue(index: number, enumType) {
		const enumValueArray = Object.keys(enumType).map(enumKey => enumType[enumKey]);
		if (index > enumValueArray.length - 1) {
			return undefined;
		}
		const val = enumValueArray[index];
		return val;
	}

	getElementIndex(array: any[], property: string, value: string) {
		for (let i = 0; i < array.length; i++) {
			if (array[i][property] === value) {
				return i;
			}
		}
	}

	/**
	 *
	 * Permette la selezione delle options di una mat-select nel caso in cui venga bindato nel mat-option [value] un oggetto
	 * e non un semplice ID. Le condizioni dell'if dovrebbero diventare parametriche.
	 *
	 * @param options array che genera le options
	 * @param values valori da selezionare derivanti ad esempio da una response
	 */
	compareObjectForMatSelect(options, values) {
		const a = [];
		options.forEach(o => {
			values.forEach(v => {
				if (
					o.organization === v.organization &&
					o.channel === v.channel &&
					o.group_customer === v.group_customer
				) {
					a.push(o);
				}
			});
		});
		return a;
	}

	/**
	 * This function resize an image (format base64)
	 * @param src The original Base64 image
	 * @param maxWidth The max width of the result image
	 * @param maxHeight The max height of the result image
	 */
	compressImage(src: string, maxWidth: number, maxHeight: number): Promise<string> {
		return new Promise((res, rej) => {
			const img = new Image();
			img.src = src;

			img.onload = () => {
				const hasBothDimensions = maxHeight && maxWidth ? true : false;
				let ratio: number;
				const elem = document.createElement('canvas');

				if (img.width > img.height) {
					// horizontal
					ratio = img.width / img.height;
					if (hasBothDimensions) {
						maxHeight = maxWidth / ratio;
					} else {
						maxHeight = maxHeight ? maxHeight : maxWidth / ratio;
						maxWidth = maxWidth ? maxWidth : maxHeight * ratio;
					}
				} else {
					// vertical
					ratio = img.height / img.width;
					if (hasBothDimensions) {
						maxWidth = maxHeight / ratio;
					} else {
						maxWidth = maxWidth ? maxWidth : maxHeight / ratio;
						maxHeight = maxHeight ? maxHeight : maxWidth * ratio;
					}
				}

				// resize only if bigger
				if (img.width < maxWidth && img.height < maxHeight) {
					elem.width = img.width;
					elem.height = img.height;
				} else {
					elem.width = maxWidth;
					elem.height = maxHeight;
				}

				const ctx = elem.getContext('2d');
				ctx.drawImage(img, 0, 0, elem.width, elem.height);
				// but compress anyway 0.8 and covert to jpeg
				const data = ctx.canvas.toDataURL('image/jpeg', 0.8);
				return res(data);
			};
			img.onerror = error => rej(error);
		});
	}

	/**
	 * Return a new sort setting based on sortChange event in Teradata Table
	 */
	getSortChanged(sortEvent: ITdDataTableSortChangeEvent) {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				[sortEvent.name]: sortEvent.order === TdDataTableSortingOrder.Ascending ? 'asc' : 'desc'
			}
		];
		return sort;
	}

	/**
	 *
	 * @param array array of object
	 * @param key key of a property of the object
	 * @param order if it should asc or desc
	 */
	sortArrayByKey(array: any[], key: string, order: 'asc' | 'desc') {
		if (array.length < 1 || !(key in array[0])) {
			return array;
		}

		return array.sort((a, b) => {
			if (order === 'asc') {
				return a[key] > b[key] ? 1 : -1;
			} else {
				return a[key] < b[key] ? 1 : -1;
			}
		});
	}

	/**
	 * prefix special character `[ \ ^ $ . | ? * + ( )` with `\\`
	 * @param text is the string to check
	 */
	replaceSpecialCharacter(text: string): string {
		return text.replace(/\*|\[|\\|\^|\$|\.|\||\?|\*|\+|\(|\)/gi, function(matched) {
			return '\\' + matched;
		});
	}

	/**
	 * Restituisce l'oggetto privo delle proprietà settate a null, undefined o array vuoto
	 * @param object any
	 */
	deleteEmptyProperties(object) {
		for (const key in object) {
			if (object.hasOwnProperty(key)) {
				if (
					object[key] === null ||
					object[key] === undefined ||
					object[key].length === 0 ||
					(Object.keys(object[key]).length === 0 && object[key].constructor === Object)
				) {
					delete object[key];
				}
			}
		}
		return object;
	}
}
