import { Injectable } from '@angular/core';
import { CustomerDivisionPouchModel, AddressPouchModel } from '@saep-ict/pouch_agent_models';
import moment from 'moment';
import { CustomerTypeEnum } from '../../enum/customer-type.enum';
import { CatalogDataModel, LocationModel, ContractPaimentItem } from '../../model/state/contract-state.model';
import { UtilService } from './util.service';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { CustomerAppConfig } from '../../customer-app.config';

export class ContextDialogModel {
	customerFull: ContextDialogValuesModel[];
	customerOnly: ContextDialogValuesModel[];
	operationalHeadquarterOnly: ContextDialogValuesModel[];
	bankOnly: ContextDialogValuesModel[];
	garagingOnly: ContextDialogValuesModel[];
}

export class ContextDialogValuesModel {
	name: string;
	applyValidators?: boolean;
}

export interface CustomerMethodDelivery extends CustomerDivisionPouchModel {
	description?: string;
}

@Injectable({
	providedIn: 'root'
})
export class UtilContractService {
	constructor(
		private utilService: UtilService,
		private http: HttpClient,
		private customerAppConfig: CustomerAppConfig
		) {}

	public privacyAcceptanceList: CatalogDataModel[] = [
		{
			code: 'privacyAcceptance_0',
			label: 'Presa visione del contratto di locazione',
			description: ``,
			required: true
		},
		{
			code: 'privacyAcceptance_1',
			label: 'Trattamento dei dati personali',
			description: `Il cliente concede il consenso alla società al trattamento dei propri dati personali per la finalità di cui all'informativa GPDR artt. 13 e 14, fondamentale per lo svolgimento del rapporto.`,
			required: true
		},
		{
			code: 'privacyAcceptance_2',
			label: 'Finalità di promozione commerciale e marketing',
			description: ``,
			required: false
		},
		{
			code: 'privacyAcceptance_3',
			label: 'Comunicazione e/o diffusione dei dati personali',
			description: `Il cliente concede alla società di comunicare e/o diffondere i propri dati personali alle categorie di soggetti indicate negli artt. 6 e 7 GDPR.`,
			required: false
		},
		{
			code: 'privacyAcceptance_4',
			label: "Trasferimento dei dati personali all'estero",
			description: `Il cliente concede alla società il trasferimento all'estero dei propri dati personali, anche verso paesi non appartenenti all' U.E.`,
			required: false
		}
	];

	public contextDialog: ContextDialogModel = {
		customerFull: [
			{
				name: 'registry-customer',
				applyValidators: true
			},
			{
				name: 'registry-legal-headquarter',
				applyValidators: true
			},
			{
				name: 'registry-operational-headquarter',
				applyValidators: true
			},
			{
				name: 'registry-bank-data',
				applyValidators: false
			}
		],
		customerOnly: [
			{
				name: 'registry-customer',
				applyValidators: true
			},
			{
				name: 'registry-legal-headquarter',
				applyValidators: true
			}
		],
		operationalHeadquarterOnly: [
			{
				name: 'registry-operational-headquarter',
				applyValidators: true
			}
		],
		bankOnly: [
			{
				name: 'registry-bank-data',
				applyValidators: true
			}
		],
		garagingOnly: [
			{
				name: 'registry-garaging',
				applyValidators: true
			}
		]
	};

	/**
	 * Recursive function to get first valid date
	 */
	getFirstValidDate(currentDate: Date, day: number) {
		const currentDay = moment(currentDate).day();
		if ([0, 6].includes(currentDay)) {
			const oneDayAfter = moment(currentDate)
				.add(day, 'day')
				.toDate();
			return this.getFirstValidDate(oneDayAfter, day);
		} else {
			return currentDate;
		}
	}

	formatHexOrderCode(code: string): string {
		if (code) {
			let newCode = code.replace('order_', '');
			newCode = newCode.substr(newCode.length - 12, newCode.length);
			newCode = newCode.toUpperCase();
			return newCode;
		} else {
			return '';
		}
	}

	getTotalAmount(payment: {
		location: ContractPaimentItem;
		delivery: ContractPaimentItem;
		optional: ContractPaimentItem;
	}) {
		if (payment.location && payment.delivery && payment.optional) {
			// const locationTotal = this.getPriceWithVat(payment.location.amount, payment.location.vat);
			// const deliveryTotal = this.getPriceWithVat(payment.delivery.amount, payment.delivery.vat);
			// const optionalsTotal = this.getPriceWithVat(payment.optional.amount, payment.optional.vat);
			// return (locationTotal + deliveryTotal + optionalsTotal).toFixed(2);
			return (payment.location.amount + payment.delivery.amount + payment.optional.amount).toFixed(2);
		} else {
			return 0;
		}
	}

	// getPriceWithVat(amount: number, vat: number) {
	// 	return amount + amount * (vat / 100);
	// }

	getFormattedAddress(location: LocationModel): string {
		if (location) {
			return `${location.address} - ${location.zip_code} ${location.locality} (${location.province})`;
		} else {
			return '';
		}
	}

	getOperationalHeadquarterLabel(userType: string): string {
		return userType === CustomerTypeEnum.PRIVATE ? 'Residenza' : 'Sede Operativa';
	}
	getLegalHeadquarterLabel(userType: string): string {
		return userType === CustomerTypeEnum.PRIVATE ? 'Domicilio' : 'Sede Legale';
	}

	// Download document from couchdb
	downloadFile(route: string, filename: string = null): void {
		const config = this.customerAppConfig.config.couch.find(x => x.database === 'mobee');
		const baseUrl = config.endpoint + '/' + config.database;

		const headers = new HttpHeaders()
			.set('x-auth-couchdb-roles', '')
			.set('x-auth-couchdb-token', this.customerAppConfig.username)
			.set('x-auth-couchdb-username', this.customerAppConfig.signature)
			;
		this.http.get(baseUrl + route, { headers, responseType: 'blob' as 'json' }).subscribe(
			(response: any) => {
				const dataType = response.type;
				const binaryData = [];
				binaryData.push(response);
				const downloadLink = document.createElement('a');
				downloadLink.target = '_blank';
				downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
				if (filename) {
					downloadLink.setAttribute('download', filename);
				}
				document.body.appendChild(downloadLink);
				downloadLink.click();
				downloadLink.parentNode.removeChild(downloadLink);
			}
		)
	}
}
