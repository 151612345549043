import { DefaultProjectorFn, MemoizedSelector } from '@ngrx/store';
import { ActionCreator, TypedAction } from '@ngrx/store/src/models';
import { ContextApplicationItemCodeEnum } from '../enum/context-application-item-code.enum';
import { PermissionKeyEnum } from '../router/permission-key.enum';
import { RestBaseGuid, RestBasePk } from './rest-base.model';

export interface LoginAuthResponseModel {
	accessToken: string;
}

export class LoginAuthRequestModel {
	email: string;
	password: string;
}

export interface PermissionModel {
	key: string;
	description: string;
}

export interface UserActivateAccountRequestModel extends RestBasePk, RestBaseGuid {}
export interface VerifyGuidEmailRequestModel extends RestBaseGuid{
	email: string;
}

export interface VerifyGuidEmailResponseModel extends RestBasePk {
	type: string;
}

export interface UserActivationStateModel extends VerifyGuidEmailRequestModel, VerifyGuidEmailResponseModel {}

export interface LinkDetailModel {
	code: string | number;
	description?: string;
}

export interface UserDetailModel extends RestBasePk{
	is_active?: boolean;
	username?: string;
	permission?: number[];
	avatar?: string;
	first_name?: string;
	last_name?: string;
	context_application_list?: ContextPermission[];
	current_context?: number;
	current_permission?: number[];
}

export interface UserTypeContextModel {
	permission: PermissionKeyEnum;
	ambiguousRoute?: boolean;
	route: any;
	type: ContextApplicationItemCodeEnum;
	action?: ActionCreator<any, (props: any) => TypedAction<any>>;
	state?: MemoizedSelector<object, any, DefaultProjectorFn<any>>;
	description?: string;
}

export class UserTypePermission implements UserTypeContextModel {
	permission: PermissionKeyEnum;
	ambiguousRoute?: boolean;
	route: any;
	type: ContextApplicationItemCodeEnum;

	constructor(data: UserTypeContextModel) {
		Object.keys(data).forEach(key => {
			this[key] = data[key];
		});
	}
}

export interface ContextPermission extends LinkDetailModel {
	permission?: number[];
	context_code_list?: ContextPermission[];
}

export type UserDetailResponseModel = UserDetailModel;

export interface LinkCodeModel {
	code: string;
	context: ContextApplicationItemCodeEnum;
}

export interface ContextPermission extends LinkDetailModel {
	permission?: number[];
	context_code_list?: ContextPermission[];
}

export interface RestPostUserListPayload {
	user_list: UserDetailModel[];
}
