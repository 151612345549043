import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MaterialComponentsModule} from './modules/material-components.module';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {LoadingOverlayComponent} from "../frame/loading-overlay/loading-overlay.component";
import { PipeModule } from './pipes/pipe.module';
import { DirectivesModule } from './directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';
import { CovalentModule } from './modules/covalent.module';
import { NgApexchartsModule } from 'ng-apexcharts';
// service
import { AttachmentResolverService } from '../service/structure/attachment-resolver.service';

@NgModule({
  imports: [
    CommonModule,
    MaterialComponentsModule,
  ],
  declarations: [
    LoadingOverlayComponent
  ],
  exports: [
    LoadingOverlayComponent,
    RouterModule,
    CovalentModule,
    MaterialComponentsModule,
    FlexLayoutModule,
    PerfectScrollbarModule,
    NgApexchartsModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    PipeModule
  ],
  providers: [
    AttachmentResolverService
  ]
})
export class SharedModule {
}
