import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { WidgetModule } from '../../widget/widget.module';
import { CompanyDetailBankComponent } from '../commons/company/company-detail/company-detail-bank/company-detail-bank.component';
import { CompanyDetailDestinationComponent } from '../commons/company/company-detail/company-detail-destination/company-detail-destination.component';
import { CompanyDetailFullDetailComponent } from '../commons/company/company-detail/company-detail-full-detail/company-detail-full-detail.component';
import { CompanyDetailOperationalHeadquarterComponent } from '../commons/company/company-detail/company-detail-operational-headquarter/company-detail-operational-headquarter.component';
import { PageCommonsModule } from '../commons/page-commons.module';
import { UserManagementDetailComponent } from './user-management/user-management-detail/user-management-detail.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CompanyBackofficeComponent } from './company-backoffice/company-backoffice.component';
import { CompanyDetailBackofficeComponent } from './company-backoffice/company-detail-backoffice/company-detail-backoffice.component';
import { ContractBackofficeComponent } from './contract-backoffice/contract-backoffice.component';
import { ContractDetailBackofficeComponent } from './contract-backoffice/contract-detail-backoffice/contract-detail-backoffice.component';
import { FleetComponent } from './fleet/fleet.component';
import { UserBackofficeComponent } from './user-backoffice/user-backoffice.component';

@NgModule({
	imports: [WidgetModule, SharedModule, PageCommonsModule],
	declarations: [
		UserManagementComponent,
		UserManagementDetailComponent,
		FleetComponent,
		ContractBackofficeComponent,
		ContractDetailBackofficeComponent,
		CompanyBackofficeComponent,
		CompanyDetailBackofficeComponent,
		UserBackofficeComponent
	],
	providers: [],
	exports: [UserManagementComponent, UserManagementDetailComponent, FleetComponent],
	entryComponents: [
		UserManagementComponent,
		UserManagementDetailComponent,
		FleetComponent,
		ContractBackofficeComponent,
		ContractDetailBackofficeComponent,
		CompanyBackofficeComponent,
		CompanyDetailBackofficeComponent,
		CompanyDetailDestinationComponent,
		CompanyDetailBankComponent,
		CompanyDetailOperationalHeadquarterComponent,
		CompanyDetailFullDetailComponent,
		UserBackofficeComponent
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageBackofficeModule {}
