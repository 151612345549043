import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { BaseStateModel } from '../../../../model/state/base-state.model';
import { MopedDataSheetModel } from '../../../../model/state/contract-state.model';
import { MopedListFilterModel } from '../../filter/moped-filter.model';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';

export class MopedPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	getMopedList(filter: BaseStateModel<MopedListFilterModel>): Promise<MopedDataSheetModel[]> {
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'moped'
					}
				]
			};

			this.getWithIndexFilter('moped-list', this.selector, filter.pagination, filter.sort ? filter.sort : [])
				.then((res: ListDataPouchModel<MopedDataSheetModel>) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}
}
