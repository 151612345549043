import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AgentCustomerPouchModel, CustomerPouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';
import { BaseState, BaseStateModel } from '../../../model/state/base-state.model';
import {
	ContractPouchModel,
	ContractStateModel,
	Customer,
	ExtraFieldContractHeaderPouchModel
} from '../../../model/state/contract-state.model';
import { ContractListFilterModel } from '../../../service/pouchdb/filter/contract-filter.model';
import { StateFeature } from '../../../state';
import { ContractListActionEnum, ContractListStateAction } from '../../../state/contract-list/contract-list.actions';
import { CustomerListStateAction } from '../../../state/customer-list/customer-list.actions';
import { CustomerActionEnum } from '../../../state/customer/customer.actions';
import { ContractComponent } from '../../commons/contract/contract.component';

@Component({
	selector: 'contract-backoffice',
	templateUrl: './contract-backoffice.component.html',
	styleUrls: ['./contract-backoffice.component.scss']
})
export class ContractBackofficeComponent implements OnInit, OnDestroy {
	userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.BACKOFFICE;

	contracts$: Observable<BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>>;
	contractList: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>;

	customerList$: Observable<BaseStateModel<CustomerPouchModel[]>> = this.store.select(
		StateFeature.getCustomerListState
	);
	customerList: Customer[] = [];

	initialLoadSub: Subscription;

	initialFilter: ContractListFilterModel;

	@ViewChild(ContractComponent, {
		static: true
	})
	private contractComponent: ContractComponent;

	constructor(private store: Store<any>, private route: ActivatedRoute) {
		this.contracts$ = this.store.select(StateFeature.getContractListState);
	}

	_filterCompanyNames(value: string): Customer[] {
		const filterValue = value.toLowerCase();
		if (this.customerList) {
			const val = this.customerList.filter(option => {
				return option.customer_name && option.customer_name.toLowerCase().includes(filterValue);
			});
			// console.log("VAL: ", val)
			return val;
		}
		return null;
	}

	ngOnInit() {
		this.store.dispatch(CustomerListStateAction.load(new BaseState(null)));

		this.contractComponent.filteredCustomerList = this.contractComponent.companyNameCtrl.valueChanges.pipe(
			startWith(''),
			map(value => {
				return typeof value === 'string' ? value : value.business_name;
			}),
			map(name => (name ? this._filterCompanyNames(name) : this.customerList.slice())),
			map(customerList =>
				_.map(customerList, customer => {
					return <AgentCustomerPouchModel>{
						business_name: customer.customer_name,
						code: customer.code // TODO: ATTENZION: Per i nuovi customer creati non c'è il cod... non andrebbe usato _id di couchdb????
					};
				})
			)
		);

		if (!this.initialLoadSub) {
			this.initialLoadSub = this.route.paramMap
				.pipe(
					take(1),
					mergeMap(param => {
						this.initialFilter = {};
						return this.customerList$;
					}),
					filter(
						(customerList: BaseStateModel<Customer[]>) =>
							customerList && customerList.type !== CustomerActionEnum.LOAD
					),
					mergeMap((customerList: BaseStateModel<Customer[]>) => {
						if (customerList.type === CustomerActionEnum.ERROR) {
							throw new Error(CustomerActionEnum.ERROR);
						}
						if (customerList.data) {
							this.customerList = customerList.data;
						}
						return this.contracts$;
					}),
					filter(
						(contractList: BaseStateModel<ContractStateModel[]>) =>
							contractList && contractList.type !== ContractListActionEnum.LOAD_WITH_DETAIL
					),
					map((contractList: BaseStateModel<ContractStateModel[]>) => {
						if (contractList.type === ContractListActionEnum.UPDATE) {
							this.contractList = contractList;
							return this.contractList;
						}
					})
				)
				.subscribe();
		}
	}

	loadContractList($event: BaseStateModel<ContractListFilterModel>) {
		this.store.dispatch(ContractListStateAction.load($event));
	}

	ngOnDestroy() {
		if (this.initialLoadSub) {
			this.initialLoadSub.unsubscribe();
		}
	}
}
