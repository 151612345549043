import { Component, OnInit } from '@angular/core';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';

@Component({
	selector: 'user-agent',
	templateUrl: './user-agent.component.html',
	styleUrls: ['./user-agent.component.scss']
})
export class UserAgentComponent implements OnInit {
	userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.AGENT;
	constructor() {}

	ngOnInit() {}
}
