import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { AuxiliaryTableActionEnum, AuxiliaryTableStateAction } from './auxiliary-table.actions';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { BaseStateModel, BaseState } from '../../model/state/base-state.model';
import { AuxiliaryTableStateModel, AuxiliaryDictionary } from '../../model/state/auxiliary-table-list';
import { from } from 'rxjs';
import { Store } from '@ngrx/store';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';

@Injectable()
export class AuxiliaryTableEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AuxiliaryTableActionEnum.LOAD),
			mergeMap(() => from(this.getAuxiliaryTable())),
			map((auxiliaryTable: BaseStateModel<AuxiliaryTableStateModel>) => AuxiliaryTableStateAction.update(auxiliaryTable)),
			catchError((error, caught) => {
				this.store.dispatch(AuxiliaryTableStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getAuxiliaryTable(): Promise<BaseStateModel<AuxiliaryTableStateModel>> {
        const promises = [];
        const auxiliaryTable: AuxiliaryTableStateModel = {};
		AuxiliaryDictionary.forEach((auxiliary: {key: string, type: string}) => {
			promises.push(
				this.pouchDbMobeeAgentiAdapter.auxiliaryTablePouch
					.getAuxiliaryTable(auxiliary.key)
					.then(doc => {
						return auxiliaryTable[auxiliary.type] = doc;
					})
					.catch(err => {
						return auxiliaryTable[auxiliary.type] = null;
					})
			);
        });
        await Promise.all(promises);
        return new BaseState(auxiliaryTable);
	}
}
