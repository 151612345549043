import { ITdDataTableColumn } from '@covalent/core';

import { BaseTableListService } from '../base-table-list.service';

export class CustomerDetailDestinationsColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'address',
			label: 'Indirizzo'
		},
		{
			name: 'zip_code',
			label: 'CAP'
		},
		{
			name: 'locality',
			label: 'Città'
		},
		{
			name: 'province',
			label: 'Provincia'
		},
		{
			name: 'action',
			label: '',
			width: 50,
			sortable: false
		}
	];
}

export class CustomerDetailFullDetailPaymentsColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'code_item',
			label: 'Codice'
		},
		{
			name: 'description_short',
			label: 'Descrizione'
		}
	];
}
export class CustomerDetailBankColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'iban_code',
			label: 'Codice IBAN',
			width: 230
		},
		{
			name: 'bank_address',
			label: 'Indirizzo banca'
		},
		{
			name: 'bank_email',
			label: 'Email banca'
		},
		{
			name: 'bank_ref',
			label: 'Nome riferimento interno'
		},
		// {
		// 	name: 'bank_ref_name',
		// 	label: 'Titolare banca'
		// },
		{
			name: 'action',
			label: '',
			width: 50,
			sortable: false
		}
	];
}

export class CustomerDetailOperationalHeadquarterColumnService extends BaseTableListService {
	constructor() {
		super();
	}
	columns: ITdDataTableColumn[] = [
		{
			name: 'address',
			label: 'Indirizzo'
		},
		{
			name: 'zip_code',
			label: 'CAP'
		},
		{
			name: 'locality',
			label: 'Città'
		},
		{
			name: 'province',
			label: 'Provincia'
		},
		{
			name: 'action',
			label: '',
			width: 50,
			sortable: false
		}
	];
}
