import { Component, OnInit } from '@angular/core';
import { ContextApplicationItemCodeEnum } from '../../../../enum/context-application-item-code.enum';

@Component({
	selector: 'contract-detail-agent',
	templateUrl: './contract-detail-agent.component.html',
	styleUrls: ['./contract-detail-agent.component.scss']
})
export class ContractDetailAgentComponent implements OnInit {

	userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.AGENT;

	constructor() {}

	ngOnInit() {}
}
