import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
// import { contractstatisticsList } from '@saep-ict/pouch_agent_models/model/chart-pouch.model';
import { ContractStatusIconEnum, ContractStatusEnum } from '../../enum/contract-status.enum';
import { BaseStateModel } from '../../model/state/base-state.model';
import { NavItem } from '../../model/structure/nav-item.model';
import { UserDetailModel } from '../../model/user.model';
import { StateFeature } from '../../state';
import { UtilGuardService } from '../guard/util-guard/util-guard.service';
import { PermissionKeyEnum } from "../../router/permission-key.enum";


@Injectable()
export class NavigationService {
  private _itemsSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
  private _items: NavItem[] = [];
  items$: Observable<NavItem[]> = this._itemsSubject.asObservable();

  private _currentlyOpenSubject: BehaviorSubject<NavItem[]> = new BehaviorSubject<NavItem[]>([]);
  private _currentlyOpen: NavItem[] = [];
  currentlyOpen$: Observable<NavItem[]> = this._currentlyOpenSubject.asObservable();

  isIconSidenav: boolean;
  isHorizontal: boolean = false;

  user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
  user: UserDetailModel;
  _user: Subscription;

  // contractstats$: Observable<BaseStateModel<contractstatisticsList>> = this.store.select(StateFeature.getcontractstatistics);

  constructor(
    private store: Store<any>,
    snackbar: MatSnackBar,
    public translate: TranslateService,
    private utilGuardService: UtilGuardService
    ) {
    // setta di default tutte le voci di menu esplose (aperte)
    // this._currentlyOpen = this._items;

		if (!this._user) {
			this._user = this.user$.subscribe(res => {
        if (res) {
					this.user = res.data;
					this._items = [];
					this._itemsSubject.next(this._items);

          /**
           * Menu agent
           */
          const addressAgent = this.addItem('Rubrica', '', '', 10, PermissionKeyEnum.AGENT_DASHBOARD);
          const companiesAgent = this.addSubItem(addressAgent, 'Clienti', 'work', '/companies', 11, PermissionKeyEnum.AGENT_DASHBOARD);

          const contractsAgent = this.addItem('Contratti', '', '', 20, PermissionKeyEnum.AGENT_DASHBOARD);
          const newContractAgent = this.addSubItem(contractsAgent, translate.instant('contract.status.NEW'), 'add_circle', '/contracts/' + ContractStatusEnum.DRAFT + '/new', 21, PermissionKeyEnum.AGENT_DASHBOARD);
          const draftsContractAgent = this.addSubItem(contractsAgent, translate.instant('contract.status.DRAFT'), ContractStatusIconEnum.DRAFT, '/contracts/' + ContractStatusEnum.DRAFT, 22, PermissionKeyEnum.AGENT_DASHBOARD);
          const toConfirmContractAgent = this.addSubItem(contractsAgent, translate.instant('contract.status.TO_CONFIRM'), ContractStatusIconEnum.TO_CONFIRM, '/contracts/' + ContractStatusEnum.TO_CONFIRM, 23, PermissionKeyEnum.AGENT_DASHBOARD);
          const completedContractAgent = this.addSubItem(contractsAgent, translate.instant('contract.status.COMPLETED'), ContractStatusIconEnum.COMPLETED, '/contracts/' + ContractStatusEnum.COMPLETED, 26, PermissionKeyEnum.AGENT_DASHBOARD);

          /**
           * Menu Backoffice
           */
          const addressBo = this.addItem('Rubrica', '', '', 10, PermissionKeyEnum.BO_DASHBOARD);
          const companiesBo = this.addSubItem(addressBo, 'Clienti', 'work', '/companies', 11, PermissionKeyEnum.BO_DASHBOARD);

          const contractsBo = this.addItem('Contratti', '', '', 20, PermissionKeyEnum.BO_DASHBOARD);
          const toConfirmContractBo = this.addSubItem(contractsBo, translate.instant('contract.status.TO_CONFIRM'), ContractStatusIconEnum.TO_CONFIRM, '/contracts/' + ContractStatusEnum.TO_CONFIRM, 23, PermissionKeyEnum.BO_DASHBOARD);
          const completedContractBo = this.addSubItem(contractsBo, translate.instant('contract.status.COMPLETED'), ContractStatusIconEnum.COMPLETED, '/contracts/' + ContractStatusEnum.COMPLETED, 26, PermissionKeyEnum.BO_DASHBOARD);

          const registriesBo = this.addItem('Anagrafiche', '', '', 30, PermissionKeyEnum.BO_DASHBOARD);
          // const agentsBo = this.addSubItem(registriesBo, 'Agenti', 'people', '/agent', 31, PermissionKeyEnum.BO_REGISTRIES);
          const agentsBo = this.addSubItem(registriesBo, 'User Agent', 'people', '/user-management/AGENT', 31, PermissionKeyEnum.BO_DASHBOARD);
          const backofficesBo = this.addSubItem(registriesBo, 'User Backoffice', 'supervisor_account', '/user-management/BACKOFFICE', 31, PermissionKeyEnum.BO_DASHBOARD);

          // this.contractstats$.subscribe((stats: BaseStateModel<contractstatisticsList>) => {
          //   if (stats && stats.data) {
          //     drafts.badge = stats.data.draft.count.toString();
          //   }
          // });

        }
      });
    }


    //  questa funzione permette di generare menu dinamici ma in questo momento volatili
    const dynamicMenuFunctionDemo = () => {
      const dynamicFunction = () => {
        const snackbarRef = snackbar.open('This menu item got added dynamically!', 'Remove item', <MatSnackBarConfig>{
          duration: 5000
        });

        snackbarRef.onAction().subscribe(() => {
          this.removeItem(dynamicMenu);
        });
      };

      const dynamicMenu = this.addItem('Dynamic Menu Item', 'extension', dynamicFunction, 12);
    };

  }

	addItem(
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: number,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: route,
			subItems: [],
			position: position || 99,
			permission: permission || null,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
    });

		if (this.utilGuardService.checkUserPermission(this.user, permission)) {
			this._items.push(item);
			this._itemsSubject.next(this._items);

			return item;
		}
	}

	addSubItem(
		parent: NavItem,
		name: string,
		icon: string,
		route: any,
		position: number,
		permission?: number,
		badge?: string,
		badgeColor?: string,
		customClass?: string
	) {
		const item = new NavItem({
			name: name,
			icon: icon,
			route: route,
			parent: parent,
			subItems: [],
			position: position || 99,
			badge: badge || null,
			badgeColor: badgeColor || null,
			customClass: customClass || null
		});
		if (this.utilGuardService.checkUserPermission(this.user, permission)) {
			parent.subItems.push(item);
			this._itemsSubject.next(this._items);
			return item;
		}
	}

  // rimuove un elemento dall'itemSubject
  removeItem(item: NavItem) {
    const index = this._items.indexOf(item);
    if (index > -1) {
      this._items.splice(index, 1);
    }

    this._itemsSubject.next(this._items);
  }

  isOpen(item: NavItem) {
    // return (this._currentlyOpen.indexOf(item) !== -1);
    // Voci di menu sempre aperte
    return true;
  }

  // ritorna i subItem dell'elemento padre interrogato attivando un observable
  toggleCurrentlyOpen(item: NavItem) {
    let currentlyOpen = this._currentlyOpen;

    if (this.isOpen(item)) {
      if (currentlyOpen.length > 1) {
        currentlyOpen.length = this._currentlyOpen.indexOf(item);
      } else {
        currentlyOpen = [];
      }
    } else {
      currentlyOpen = this.getAllParents(item);
    }

    this._currentlyOpen = currentlyOpen;
    this._currentlyOpenSubject.next(currentlyOpen);
  }

  getAllParents(item: NavItem, currentlyOpen: NavItem[] = []) {
    currentlyOpen.unshift(item);
    if (item.hasParent()) {
      return this.getAllParents(item.parent, currentlyOpen);
    } else {
      return currentlyOpen;
    }
  }

  nextCurrentlyOpen(currentlyOpen: NavItem[]) {
    this._currentlyOpen = currentlyOpen;
    this._currentlyOpenSubject.next(currentlyOpen);
  }

  nextCurrentlyOpenByRoute(route: string) {
    let currentlyOpen = [];
    const item: NavItem = this.findByRouteRecursive(route, this._items);
    if (item && item.hasParent()) {
      currentlyOpen = this.getAllParents(item);
    } else if (item) {
      currentlyOpen = [item];
    }

    this.nextCurrentlyOpen(currentlyOpen);
  }

  // metodo ricorsivo che recupera l'elemento innestato selezionato
  findByRouteRecursive(route: string, collection: NavItem[]): any {
    let result = _.find(collection, {'route': route});
    let typeItem: string;
    if (!result) {
      _.each(collection, (item) => {

        typeItem = 'subItems';
        const found = this.findByRouteRecursive(route, item[typeItem]);
        if (found) {
          result = found;
          return false;
        }
      });
    }
    return result;
  }

  get currentlyOpen() {
    return this._currentlyOpen;
  }

  getSidenavItemByRoute(route) {
    return this.findByRouteRecursive(route, this._items);
  }

  getActiveItemByRoute(route){
    return this.findByRouteRecursive(route, this._items);
  }
}

