import { Component, Input } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { BankDataModel } from '../../../model/state/contract-state.model';
import { BaseRegistryComponent } from '../BaseRegistryComponent';

@Component({
	selector: 'registry-bank-data',
	templateUrl: './registry-bank-data.component.html',
	styleUrls: ['./registry-bank-data.component.scss']
})
export class RegistryBankDataComponent extends BaseRegistryComponent<BankDataModel> {
	static COMPONENT_NAME = 'registry-bank-data';

	constructor(private fb: FormBuilder) {
		super(RegistryBankDataComponent.COMPONENT_NAME);
	}

	protected createForm() {
		const isDisabled = false;
		this.form = this.fb.group({
			iban_code: [{ value: null, disabled: isDisabled }, this.applyValidators ? Validators.required : ''],
			bic_code: [{ value: null, disabled: isDisabled }, this.applyValidators ? Validators.required : ''],
			bank_address: [{ value: null, disabled: isDisabled }],
			bank_email: [{ value: null, disabled: isDisabled }],
			bank_ref: [{ value: null, disabled: isDisabled }],
			// bank_ref_name: [{ value: null, disabled: isDisabled }],
			// bank_ref_id_card: [{ value: null, disabled: isDisabled }, this.applyValidators ? Validators.required : '']
		});
	}

	protected setForm() {
		const data = this.data;
		this.form.patchValue({
			iban_code: data.iban_code ? data.iban_code : null,
			bic_code: data.bic_code ? data.bic_code : null,
			bank_address: data.bank_address ? data.bank_address : null,
			bank_email: data.bank_email ? data.bank_email : null,
			bank_ref: data.bank_ref ? data.bank_ref : null,
			// bank_ref_name: data.bank_ref_name ? data.bank_ref_name : null,
			// bank_ref_id_card: data.bank_ref_id_card ? data.bank_ref_id_card : null
		});
	}
}
