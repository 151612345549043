import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models';
import _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { Customer } from '../../../model/state/contract-state.model';
import { CustomerListFilterModel } from '../../../service/pouchdb/filter/customer-filter.model';
import { UtilService } from '../../../service/util/util.service';
import { StateFeature } from '../../../state';
import { CustomerListStateAction } from '../../../state/customer-list/customer-list.actions';
import { CustomerActionEnum } from '../../../state/customer/customer.actions';

@Component({
	selector: 'company-backoffice',
	templateUrl: './company-backoffice.component.html',
	styleUrls: ['./company-backoffice.component.scss']
})
export class CompanyBackofficeComponent implements OnInit, OnDestroy {
	userType: ContextApplicationItemCodeEnum = ContextApplicationItemCodeEnum.AGENT;

	initialFilter;

	customerList$: Observable<BaseStateModel<CustomerPouchModel[]>>;
	customerList: BaseStateModel<CustomerPouchModel[]>;

	initialLoadSub: Subscription;

	constructor(private store: Store<any>, private utilService: UtilService) {
		this.customerList$ = this.store.select(StateFeature.getCustomerListState);
	}
	ngOnInit() {
		if (!this.initialLoadSub) {
			this.initialLoadSub = this.customerList$
				.pipe(
					filter(
						(customerList: BaseStateModel<Customer[]>) =>
							customerList && customerList.type !== CustomerActionEnum.LOAD
					),
					map((customerList: BaseStateModel<Customer[]>) => {
						if (customerList.type === CustomerActionEnum.ERROR) {
							throw new Error(CustomerActionEnum.ERROR);
						}
						if (customerList.data) {
							this.customerList = customerList;
						}
						return this.customerList;
					})
				)
				.subscribe();
		}
	}

	loadCompanyList($event: BaseStateModel<CustomerListFilterModel>) {
		const customerFilter = _.cloneDeep($event);
		customerFilter.data.companySearchText = this.utilService.replaceSpecialCharacter(
			customerFilter.data.companySearchText
		);
		this.store.dispatch(CustomerListStateAction.load(customerFilter));
	}

	ngOnDestroy(): void {
		if (this.initialLoadSub) {
			this.initialLoadSub.unsubscribe();
		}
		this.store.dispatch(CustomerListStateAction.reset());
	}
}
