import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ITdDataTableColumn, ITdDataTableSortChangeEvent, TdDataTableSortingOrder } from '@covalent/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AgentCustomerPouchModel } from '@saep-ict/pouch_agent_models';
import { Observable, Subscription } from 'rxjs';
import { ContractStatusEnum } from '../../../enum/contract-status.enum';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { BaseStateModel } from '../../../model/state/base-state.model';
import { ContractPouchModel, ExtraFieldContractHeaderPouchModel } from '../../../model/state/contract-state.model';
import { Pagination } from '../../../model/structure/list-structure.model';
import { ContractListFilterModel, DateFilterModel } from '../../../service/pouchdb/filter/contract-filter.model';
import {
	AgentContractListColumnService,
	CompanyContractListColumnService
} from '../../../service/td-data-table/implementation/contract-list.service';
import { DateMomentService } from '../../../service/util/date-moment.service';
import { SubscribeManagerService } from '../../../service/util/subscribe-manager.service';
import { UtilContractService } from '../../../service/util/util-contract.service';
import { UtilService } from '../../../service/util/util.service';
import { ContractListStateAction } from '../../../state/contract-list/contract-list.actions';

@Component({
	selector: 'contract',
	templateUrl: './contract.component.html',
	styleUrls: ['./contract.component.scss'],
	providers: [AgentContractListColumnService, CompanyContractListColumnService, SubscribeManagerService]
})
export class ContractComponent implements OnInit, OnDestroy {
	@Input() userType: ContextApplicationItemCodeEnum;
	@Input() initialFilter: ContractListFilterModel;
	@Input() contractList: BaseStateModel<ContractPouchModel<ExtraFieldContractHeaderPouchModel>[]>;
	@Output() loadContractList: EventEmitter<BaseStateModel<ContractListFilterModel>> = new EventEmitter();

	companyNameCtrl = new FormControl();

	contractState: string;
	contractCompany: string;

	initialLoadSub: Subscription;

	contractFilters: BaseStateModel<ContractListFilterModel>;

	filteredCustomerList: Observable<AgentCustomerPouchModel[]>;

	columns: ITdDataTableColumn[];
	contractListFilterForm: FormGroup;

	/**
	 * Viene usato per creare il filtro nel contesto company detail
	 */
	get contractStates() {
		return Object.keys(ContractStatusEnum).filter(status => status !== ContractStatusEnum.DELETED);
	}

	constructor(
		private store: Store<any>,
		private router: Router,
		private route: ActivatedRoute,
		private translate: TranslateService,
		private fb: FormBuilder,
		private agentContractColumnService: AgentContractListColumnService,
		private companyContractColumnService: CompanyContractListColumnService,
		private dateMomentService: DateMomentService,
		public utilContractService: UtilContractService,
		private subscribeManagerService: SubscribeManagerService,
		private utilService: UtilService
	) {
		this.createFormFilters();
	}

	createFormFilters() {
		this.contractListFilterForm = this.fb.group({
			date: [''],
			business_name: [''],
			contract_states: ['']
		});
	}

	getCustomerBusinessName(customer: AgentCustomerPouchModel) {
		if (customer) {
			return customer.business_name;
		} else {
			return null;
		}
	}

	ngOnInit() {
		if (!this.initialLoadSub) {
			this.initialLoadSub = this.route.paramMap.subscribe(params => {
				this.contractState = params.get('contractStatus');
				this.contractCompany = this.route.snapshot.parent.params['idCompany'];
				this.resetContractFormFilter();
				this.createTableColumns();
				this.contractFilters.pagination = this.getInitialPagination();
			});
		}
	}

	paginationChange(pagination: Pagination) {
		this.contractFilters.pagination = pagination;
		this.loadContractList.emit(this.contractFilters);
	}

	createTableColumns() {
		this.columns = this.contractState
			? (this.columns = this.agentContractColumnService.columns)
			: (this.columns = this.companyContractColumnService.columns);

		// hide agent column if user is agent
		['header.franchiser.business_name'].forEach(columnName => {
			this.columns[this.utilService.getElementIndex(this.columns, 'name', columnName)].hidden =
				this.userType === ContextApplicationItemCodeEnum.AGENT;
		});
	}

	resetContractFilters() {
		const pagination: Pagination = this.contractFilters
			? this.contractFilters.pagination
			: this.getInitialPagination();
		const sort = this.contractFilters ? this.contractFilters.sort : this.getInitialSort();
		const resetFilter: ContractListFilterModel = this.getInitialFilter();
		this.contractFilters = {
			data: resetFilter,
			pagination: pagination,
			sort: sort
		};
		this.loadContractList.emit(this.contractFilters);
	}

	getInitialFilter(): ContractListFilterModel {
		const companyCode = this.contractCompany;
		const status: ContractStatusEnum[] = this.getCurrentContractStatus();
		const initialFilter: ContractListFilterModel = this.initialFilter ? this.initialFilter : {};

		if (status) {
			initialFilter.status = status;
		}

		if (companyCode) {
			initialFilter.client_code = companyCode;
		}

		initialFilter.business_code = null;

		return initialFilter;
	}

	getCurrentContractStatus(): ContractStatusEnum[] {
		if (!this.contractState || this.contractState === 'all') {
			return undefined;
		} else {
			const result: ContractStatusEnum[] = [];

			result.push(ContractStatusEnum[this.contractState.toUpperCase()]);
			/**
			 * If in a section you have to show multiple contractStates
			 */
			// switch (ContractStatusEnum[this.contractState.toUpperCase()]) {
			// 	// ALL
			// 	case 'all':
			// 		break;

			// 	default:
			// 		break;
			// }
			return result;
		}
	}

	getInitialPagination(): Pagination {
		const pagination: Pagination = {
			page_current: 1,
			page_size: 10
		};
		return pagination;
	}

	getInitialSort(): { [key: string]: 'asc' | 'desc' }[] {
		const sort: { [key: string]: 'asc' | 'desc' }[] = [
			{
				'header.date': 'desc'
			}
		];
		return sort;
	}

	goToContractDetail(selectedContract: ContractPouchModel<ExtraFieldContractHeaderPouchModel>) {
		this.router.navigate([`/contracts/${selectedContract.header.status}/${selectedContract._id}`]);
	}

	submitContractListFilters() {
		// Data creazione
		if (this.contractListFilterForm && this.contractListFilterForm.value.date) {
			const filterDate: DateFilterModel = {
				begin: this.contractListFilterForm.value.date.begin.startOf('day').format('x'),
				end: this.contractListFilterForm.value.date.end.endOf('day').format('x')
			};
			this.contractFilters.data.date = filterDate;
		}

		// Azienda
		if (this.companyNameCtrl && (this.companyNameCtrl.value || this.companyNameCtrl.value === '')) {
			const company: AgentCustomerPouchModel = this.companyNameCtrl.value;
			this.contractFilters.data.business_code = company.code;
		}

		// Stato contratti
		if (this.contractListFilterForm && this.contractListFilterForm.value.contract_states) {
			this.contractFilters.data.status = this.contractListFilterForm.value.contract_states.map(
				(status: string) => ContractStatusEnum[status]
			);
		}

		this.contractFilters.pagination.page_current = 1;
		this.loadContractList.emit(this.contractFilters);
	}

	resetContractFormFilter() {
		this.contractListFilterForm.patchValue({
			date: '',
			business_name: '',
			contract_states: ''
		});
		this.resetContractFilters();
	}

	getOrderStateLabel() {
		return this.translate.instant(`contract.status.${this.contractState.toUpperCase()}`);
	}

	convertMilllisStringToStringDate(stringMillis: string, format: string = 'DD/MM/YYYY'): string {
		return this.dateMomentService.convertStringDateFromFormatToFormat(stringMillis, 'x', format);
	}

	convertMillisStringToTime(stringMillis: string) {
		const date = new Date(parseInt(stringMillis, 10));
		const options = { hour: 'numeric', minute: 'numeric' };
		return new Intl.DateTimeFormat('it', options).format(date);
	}

	sortChange(sortEvent: ITdDataTableSortChangeEvent): void {
		this.contractFilters.sort = this.utilService.getSortChanged(sortEvent);
		this.loadContractList.emit(this.contractFilters);
	}

	ngOnDestroy() {
		if (this.initialLoadSub) {
			this.initialLoadSub.unsubscribe();
		}
		this.store.dispatch(ContractListStateAction.reset());
		this.subscribeManagerService.destroy();
	}
}
