import { Injectable } from '@angular/core';

// model
import { BaseStateModel, BaseState } from '../../../model/state/base-state.model';
import { BasePouchModel, BodyTablePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';

// store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BackofficeCodeListActionEnum, BackofficeCodeListStateAction } from './backoffice-code-list.actions';

// widget & utility
import { from } from 'rxjs';
import { map, mergeMap, catchError } from 'rxjs/operators';
import { PouchDbMobeeAgentiAdapter } from '../../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { BodyTablePouchCustomModel } from '../../../service/pouchdb/model/pouch-base.model';
import { PermissionUtilService } from '../../../service/util/permission-util.service';

@Injectable()
export class BackofficeCodeListEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BackofficeCodeListActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) => from(this.getBackofficeCode(action.data))),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) =>
				BackofficeCodeListStateAction.updateList(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(BackofficeCodeListStateAction.error(null));
				return caught;
			})
		)
	);

	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BackofficeCodeListActionEnum.LOAD_LIST),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) => from(this.getBackofficeCodeList(action))),
			map((action: BaseStateModel<BodyTablePouchCustomModel[]>) =>
				BackofficeCodeListStateAction.updateList(action)
			),
			catchError((error, caught) => {
				this.store.dispatch(BackofficeCodeListStateAction.error(null));
				return caught;
			})
		)
	);

	// TODO: aggiornare la pipe come fatto nel contesto agent
	createContextCode$ = createEffect(() =>
		this.actions$.pipe(
			ofType(BackofficeCodeListActionEnum.CREATE_CONTEXT_CODE),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) => from(this.permissionUtilService.saveContextCode(action))),
			mergeMap((action: BaseStateModel<BodyTablePouchCustomModel>) => from(this.permissionUtilService.saveContextCodeCodeItem(action))),
			map((action: BaseStateModel<BodyTablePouchCustomModel>) => BackofficeCodeListStateAction.saveContextCodeSuccess(action)),
			catchError((error, caught) => {
				this.store.dispatch(BackofficeCodeListStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter,
		private permissionUtilService: PermissionUtilService
	) {}

	async getBackofficeCode(data: BasePouchModel): Promise<BaseStateModel<BodyTablePouchCustomModel[]>> {
		try {
			const backofficeList: BodyTablePouchCustomModel[] = [];
			const idBackoffice = data._id;
			backofficeList.push(await this.pouchDbMobeeAgentiAdapter.backofficePouch.getBackoffice(idBackoffice));
			return new BaseState(backofficeList);
		} catch (err) {
			throw new Error(err);
		}
	}

	async getBackofficeCodeList(
		action: BaseStateModel<BodyTablePouchCustomModel>
	): Promise<BaseStateModel<BodyTablePouchCustomModel[]>> {
		try {
			const actionReturn = await this.pouchDbMobeeAgentiAdapter.backofficePouch.getBackofficeCodeList(action);
			return new BaseState(actionReturn);
		} catch (err) {
			throw new Error(err);
		}
	}
}
