import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { AgentStateModel, AgentFranchiserModel, FranchiserModel } from '../../../../model/state/agent-state.model';
import { BaseStateModel } from '../../../../model/state/base-state.model';
import { AgentListFilterModel } from '../../filter/agent-filter.model';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';
import { Uuid } from '../../../../model/state/contract-state.model';
export class AgentPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	getAgent(agentCode: string): Promise<AgentStateModel> {
		return new Promise((resolve, reject) => {
			this.get('agent_' + agentCode)
				.then((doc: AgentStateModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getAgentList(filter: BaseStateModel<AgentListFilterModel>): Promise<AgentStateModel[]> {
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'agent'
					}
				]
			};
			this.getWithIndexFilter('agent-list', this.selector, filter.pagination, filter.sort ? filter.sort : [])
				.then((res: ListDataPouchModel<AgentStateModel>) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}

	putAgent(data: AgentStateModel, isGuid: boolean): Promise<AgentStateModel> {
		let guid;
		if (isGuid) {
			guid = data._id;
		} else {
			const newUuid = Uuid.uuid();
			guid = 'agent_' + newUuid;
			data.code_item = newUuid;
		}
		data.type = 'agent';
		return new Promise((resolve, reject) => {
			this.put<AgentStateModel>(data, guid, false)
				.then((doc: AgentStateModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	putFranchiser(data: FranchiserModel, isGuid: boolean): Promise<FranchiserModel> {
		const guid = isGuid ? data._id : 'franchiser';
		data.type = 'franchiser';
		return new Promise((resolve, reject) => {
			this.put<FranchiserModel>(data, guid, !isGuid)
				.then((doc: FranchiserModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	putAgentFranchiser(data: AgentFranchiserModel, isGuid: boolean): Promise<AgentFranchiserModel> {
		const guid = isGuid ? data._id : 'agent_franchiser';
		data.type = 'agent_franchiser';
		return new Promise((resolve, reject) => {
			this.put<AgentFranchiserModel>(data, guid, !isGuid)
				.then((doc: AgentFranchiserModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getAgentFranchiserLink(idAgent: string): Promise<AgentFranchiserModel[]> {
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'agent_franchiser_link'
					},
					{
						code_agent: idAgent
					}
				]
			};
			this.getWithIndexFilter('agent_franchiser_link', this.selector, null, null ? null : [])
				.then((res: any) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}

	getFranchiserDetail(franchiserCodeItem: string): Promise<AgentFranchiserModel> {
		return new Promise((resolve, reject) => {
			this.get('franchiser_' + franchiserCodeItem)
				.then((doc: AgentFranchiserModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}
}
