import { Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

import { RestBaseResponse, RestBaseMessageError } from '../../../model/rest-base.model';
import { BaseStateModel, BaseState } from '../../../model/state/base-state.model';
import { UserDetailModel, UserDetailResponseModel, UserTypeContextModel } from '../../../model/user.model';
import { UserService } from '../../../service/rest/user.service';
import { StateFeature } from '../../../state';
import { UserStateAction } from '../../../state/user/user.actions';
import { ContextApplicationItemCodeEnum } from '../../../enum/context-application-item-code.enum';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSnackBarWrapperComponent } from '../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';
import { UtilService } from '../../../service/util/util.service';
import { UtilCustomerService } from '../../../service/util/util-customer.service';

export interface PersonalInfoUpdate {
	id: string;
	first_name?: string;
	last_name?: string;
}
@Component({
	selector: 'user',
	templateUrl: './user.component.html',
	styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit, OnDestroy {
	@Input() userType: ContextApplicationItemCodeEnum;
	agent$: Observable<BaseStateModel<BodyTablePouchModel>> = this.store.select(StateFeature.getAgentState);
	agent: BodyTablePouchModel;
	currentContext: UserTypeContextModel;
	user$: Observable<BaseStateModel<UserDetailModel>> = this.store.select(StateFeature.getUserState);
	user: UserDetailModel;
	userContextEnum = ContextApplicationItemCodeEnum;

	// Form
	form: FormGroup;
	isChanged = false;
	@ViewChild('firstElementFocus', { static: true }) firstElementFocus: ElementRef;
	userNameSurname: string;

	constructor(
		private store: Store<any>,
		private fb: FormBuilder,
		private userService: UserService,
		private snackBar: MatSnackBar,
		private utilCustomer: UtilCustomerService
	) {}

	ngOnInit() {
		this.retrieveUserContext();
	}

	createForm() {
		this.form = this.fb.group({
			first_name: [this.user.first_name],
			last_name: [this.user.last_name],
			email: [this.user.username]
		});
	}

	retrieveUserContext() {
		this.user$.pipe(take(1)).subscribe(res => {
			this.user = res ? res.data : null;

			this.updateNameSurname();

			this.createForm();
		});

		if (this.userType === ContextApplicationItemCodeEnum.AGENT) {
			this.agent$.pipe(take(1)).subscribe(res => {
				this.agent = res ? res.data : null;
			});
		}
	}

	updateNameSurname(){
		if (this.user){
			this.userNameSurname = this.utilCustomer.getFormattedName(this.user);
		}
	}

	onFormSubmit(e) {
		if (this.form.valid) {
			const personalInfoUpdate: PersonalInfoUpdate = {
				id: this.user.id
			};

			if (this.form.value.first_name !== this.user.first_name) {
				personalInfoUpdate.first_name = this.form.value.first_name;
			}
			if (this.form.value.last_name !== this.user.last_name) {
				personalInfoUpdate.last_name = this.form.value.last_name;
			}

			this.userService
				.updateUserDetail(personalInfoUpdate)
				.then((response: RestBaseResponse<UserDetailResponseModel>) => {
					this.user.first_name = response.data.first_name;
					this.user.last_name = response.data.last_name;
					this.store.dispatch(UserStateAction.update(new BaseState(this.user)));

					this.updateNameSurname();

					this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
						duration: 3000,
						data: {
							message: `Dati dell'utente aggiornati`
						}
					});
				})
				.catch((err: RestBaseMessageError) => {
					this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
						duration: 3000,
						data: {
							message: `Siamo spiacenti, qualcosa è andato storto`,
						}
					});

					throw new Error(err.body.detail);
				});

			this.isChanged = false;
		}
	}
	editFocus() {
		this.isChanged = true;
		this.firstElementFocus.nativeElement.focus();
	}

	ngOnDestroy() {}
}
