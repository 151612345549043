import { RestBasePk } from './../../model/rest-base.model';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { RestBaseMessageError } from '../../model/rest-base.model';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { UserDetailResponseModel } from '../../model/user.model';
import { UserService } from '../../service/rest/user.service';
import { UserActionEnum, UserStateAction } from './user.actions';
import { UserPasswordUpdateModel } from '../../model/password.model';

@Injectable()
export class UserEffects {
	password_change$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActionEnum.PASSWORD_CHANGE),
			mergeMap((action: BaseStateModel<UserPasswordUpdateModel>) => from(this.userPasswordChange(action.data))),
			map((user: BaseStateModel<UserDetailResponseModel>) => UserStateAction.update(user)),
			catchError((error, caught) => {
				this.store.dispatch(UserStateAction.error({ type: UserActionEnum.ERROR, data: null }));
				return caught;
			})
		)
	);

	password_recovery$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserActionEnum.PASSWORD_RECOVERY),
			mergeMap((action: BaseStateModel<{ email: string }>) => from(this.userPasswordRecovery(action.data))),
			map((user: BaseStateModel<void>) => UserStateAction.completed(user)),
			catchError((error, caught) => {
				this.store.dispatch(UserStateAction.error({ type: UserActionEnum.ERROR, data: null }));
				return caught;
			})
		)
	);


	constructor(private actions$: Actions, private store: Store<any>, private userService: UserService) {}

	async userPasswordChange(data: UserPasswordUpdateModel): Promise<BaseStateModel<UserDetailResponseModel>> {
		const userRequest: RestBasePk = { id: data.id };
		return this.userService
			.passwordUpdate({ password: data.password, old_password: data.oldPassword }, null, userRequest)
			.then(async user => {
				try {
					const userDetail = (await this.userService.getUserDetail(userRequest)).data;
					return new BaseState(userDetail);
				} catch (err) {
					throw new Error(err.body.detail);
				}
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async userPasswordRecovery(data: { email: string }): Promise<BaseStateModel<void>> {
		return this.userService
			.passwordRecovery({ email: data.email })
			.then(async user => {
				return new BaseState(null);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}
}
