import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import { AgentStateReducer } from "./agent.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('agent', AgentStateReducer.reducer)
    ]
})
export class AgentStateModule{}
