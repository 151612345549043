import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { catchError, map, skipWhile, take, takeUntil, filter } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { BaseStateModel, BaseState } from '../../model/state/base-state.model';
import { UserActivationStateModel, VerifyGuidEmailRequestModel } from '../../model/user.model';
import { StateFeature } from '../../state';
import { UserActivateActionEnum, UserActivateStateAction } from '../../state/user-activate/user-activate.actions';

@Injectable()
export class VerifyTokenEmailGuard implements CanActivate {
	userActivate$: Observable<BaseStateModel<UserActivationStateModel>> = this.store.select(
		StateFeature.getUserActivateState
	);
	destroy$: Subject<boolean> = new Subject<boolean>();

	constructor(private router: Router, private store: Store<any>) {}
	canActivate(
		route: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean> | Promise<boolean> | boolean {
		if (!environment.mockup) {
			if (route.params) {
				let userActivate: BaseStateModel<UserActivationStateModel>;
				this.userActivate$.pipe(take(1)).subscribe(res => {
					if (res && res.data) {
						if (
							res.type !== UserActivateActionEnum.ERROR &&
							(route.params['email'] === res.data.email && route.params['guid'] === res.data.guid)
						) {
							userActivate = res;
						} else {
							this.store.dispatch(UserActivateStateAction.reset());
						}
					}
				});
				if (!userActivate) {
					const requestVerifyToken: VerifyGuidEmailRequestModel = {
						email: route.params['email'],
						guid: route.params['guid']
					};
					this.store.dispatch(UserActivateStateAction.load(new BaseState(requestVerifyToken)));
					return new Promise(resolve => {
						this.initObservableState().subscribe(
							(res: BaseStateModel<UserActivationStateModel>) => {
								this.destroy$.next(true);
								return resolve(true);
							},
							error => {
								console.warn(error);
								this.destroy$.next(true);
								return resolve(this.errorValidateTokenEmail());
							}
						);
					});
				} else {
					return true;
				}
			}
			return false;
		}
		return true;
	}

	initObservableState(): Observable<BaseStateModel<UserActivationStateModel>> {
		return this.userActivate$.pipe(
			skipWhile((userActivate: BaseStateModel<UserActivationStateModel>) => !userActivate),
			takeUntil(this.destroy$),
			map((userActivate: BaseStateModel<UserActivationStateModel>) => {
				if (userActivate.type === UserActivateActionEnum.ERROR) {
					throw new Error(UserActivateActionEnum.ERROR);
				}
				return userActivate;
			}),
			catchError(error => {
				throw new Error(error);
			})
		);
	}

	errorValidateTokenEmail(): boolean {
		this.router.navigate([`verify-token-error`]);
		this.store.dispatch(UserActivateStateAction.reset());
		return false;
	}
}
