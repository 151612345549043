import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NavigationEnd, Router } from '@angular/router';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';
import { CustomerAppConfig } from '../../customer-app.config';
import { NavItem } from '../../model/structure/nav-item.model';
import { NavigationService } from '../../service/navigation/navigation.service';
import { MediaReplayService } from '../../service/util/media-replay.service';

@Component({
	selector: 'ms-sidenav',
	templateUrl: './sidenav.component.html',
	styleUrls: ['./sidenav.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class SidenavComponent implements OnInit, OnDestroy {
	@Input() sidenav: any;
	@Input() sidenavOpen: any;

	items: NavItem[];

	private _itemsSubscription: Subscription;
	private _routerEventsSubscription: Subscription;

	configSubscription: Subscription;
	appTag: string;

	constructor(
		private navigationService: NavigationService,
		private router: Router,
		public dialog: MatDialog,
		public mediaReplayService: MediaReplayService,
		protected appConfig: CustomerAppConfig
	) {
		this.configSubscription = this.appConfig.config$.subscribe(config => {
			this.appTag = config.tag;
		});
	}

	ngOnInit() {
		this._itemsSubscription = this.navigationService.items$.subscribe((items: NavItem[]) => {
			this.items = this.sortRecursive(items, 'position');
		});

		this._routerEventsSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd) {
				this.navigationService.nextCurrentlyOpenByRoute(event.url);
				// riesco a recuperare nell'elemento zero il master senza passare da una funzione ricorsiva che recupera subItem e slaveItem
				setTimeout(() => {
					window.dispatchEvent(new Event('resize'));
				}, 400);
			}
		});
	}

	toggleIconSidenav() {
		this.navigationService.isIconSidenav = !this.navigationService.isIconSidenav;
	}

	isIconSidenav(): boolean {
		return this.navigationService.isIconSidenav;
	}

	sortRecursive(array: NavItem[], propertyName: string) {
		array.forEach(item => {
			const keys = _.keys(item);
			keys.forEach(key => {
				if (_.isArray(item[key])) {
					item[key] = this.sortRecursive(item[key], propertyName);
				}
			});
		});
		return _.sortBy(array, propertyName);
	}

	ngOnDestroy() {
		if (this._itemsSubscription && this._routerEventsSubscription) {
			this._itemsSubscription.unsubscribe();
			this._routerEventsSubscription.unsubscribe();
		}
		if (this.configSubscription) {
			this.configSubscription.unsubscribe();
		}
	}
}
