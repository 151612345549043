import { SharedModule } from "../../shared/shared.module";
import { WidgetModule } from "../../widget/widget.module";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { UserComponent } from "../b2b/user/user.component";

@NgModule({
	imports: [WidgetModule, SharedModule],
	declarations: [UserComponent],
	exports: [UserComponent],
	entryComponents: [UserComponent],
	providers: [],
	schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class PageB2BModule {}
