import { Routes } from '@angular/router';
import { FramePageComponent } from '../frame/admin/admin.component';
import { BoxWrapperComponent } from '../page/commons/box-wrapper/box-wrapper.component';
import { NotFoundComponent } from '../page/commons/not-found/not-found.component';
import { PasswordNewComponent } from '../page/commons/user-activation/password-new/password-new.component';
import { AuthLoginGuard } from '../service/guard/auth-login.guard';
import { AuthTokenGuard } from '../service/guard/auth-token.guard';
import { AuthUserStateGuard } from '../service/guard/auth-user-state.guard';
import { VerifyTokenEmailGuard } from '../service/guard/verify-token-email.guard';
import { ClientCodeSelectComponent } from './../page/commons/authentication/client-code-select/client-code-select.component';
import { LoginComponent } from './../page/commons/authentication/login/login.component';
import { PasswordRecoveryComponent } from './../page/commons/authentication/password-recovery/password-recovery.component';
import { PasswordUpdateComponent } from './../page/commons/authentication/password-update/password-update.component';
import { PrintContractComponent } from '../page/commons/print/print-contract/print-contract.component';

export const routes: Routes = [
	{
		path: 'authentication',
		component: BoxWrapperComponent,
		children: [
			{
				path: 'login',
				canActivate: [AuthLoginGuard],
				component: LoginComponent,
				pathMatch: 'full'
			},
			{
				path: 'password-recovery',
				canActivate: [AuthLoginGuard],
				component: PasswordRecoveryComponent,
				pathMatch: 'full'
			},
			{
				path: 'password-update',
				canActivate: [AuthTokenGuard],
				component: PasswordUpdateComponent,
				pathMatch: 'full'
			},
			{
				path: 'client-code-select',
				canActivate: [AuthUserStateGuard],
				component: ClientCodeSelectComponent,
				pathMatch: 'full'
			}
		]
	},
	{
		path: '',
		canActivate: [AuthTokenGuard],
		component: FramePageComponent,
		children: []
	},
	{
		path: 'user-activation/:type/:email/:guid',
		component: BoxWrapperComponent,
		canActivate: [VerifyTokenEmailGuard],
		children: [
			{
				path: '',
				component: PasswordNewComponent,
				pathMatch: 'full'
			}
		]
	},
	{
		path: 'verify-token-error',
		component: NotFoundComponent,
		pathMatch: 'full'
	},
	// Print
	{
		path: 'print/:contractId',
		canActivate: [AuthTokenGuard],
		component: PrintContractComponent,
		children: []
	},
	{
		path: '**',
		canActivate: [AuthTokenGuard],
		component: NotFoundComponent,
		children: []
	}
];
