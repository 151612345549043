import { Component, OnInit, Input, ViewChild, OnDestroy } from '@angular/core';
import { ApexOptions, ChartComponent } from 'ng-apexcharts';

@Component({
  selector: 'apexchart-wrapper',
  templateUrl: './apexchart-wrapper.component.html',
  styleUrls: ['./apexchart-wrapper.component.scss']
})
export class ApexchartWrapperComponent implements OnInit, OnDestroy {

  @ViewChild('chart', {static: false}) chart: ChartComponent;
  // @Input() config: ApexOptions;

  _config: ApexOptions;

  @Input('config') set config(r: ApexOptions) {
		if (r) {
			this._config = r;
		}
  }

  constructor() { }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.chart.destroy();
  }

}
