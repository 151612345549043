import { Injectable } from '@angular/core';

// model
import { UserDetailModel, RestPostUserListPayload } from '../../../model/user.model';
import { BaseStateModel, BaseState } from '../../../model/state/base-state.model';
import { RestBaseMessageError } from '../../../model/rest-base.model';

// store
import { Store } from '@ngrx/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UserManagementStateActionEnum, UserManagementStateAction } from './user-management.actions';

// widget & utility
import { from } from 'rxjs';
import { catchError, concatMap, map } from 'rxjs/operators';
import { UserService } from '../../../service/rest/user.service';
import { UserManagementQueryParameter } from '../../../model/user-management.model';
import { UtilService } from '../../../service/util/util.service';
import { MatSnackBar } from '@angular/material';
import { MatSnackBarWrapperComponent } from '../../../widget/mat-snack-bar-wrapper/mat-snack-bar-wrapper.component';

@Injectable()
export class UserManagementEffects {

	loadList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.LOAD_LIST),
			concatMap((action: any) => from(this.getUserList(action))),
			map((listStructure: any) => UserManagementStateAction.update(listStructure)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	loadDetail$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.LOAD_DETAIL),
			concatMap((action: any) => from(this.getUserDetail(action))),
			map((listStructure: any) => UserManagementStateAction.update(listStructure)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	saveList$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UserManagementStateActionEnum.SAVE_LIST),
			concatMap((action: BaseStateModel<UserDetailModel[]>) => from(this.postUserList(action))),
			map((action: BaseStateModel<UserDetailModel[]>) => UserManagementStateAction.saveListSuccess(action)),
			catchError((error, caught) => {
				UserManagementStateAction.error(null);
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private userService: UserService,
		private utilService: UtilService,
		private snackBar: MatSnackBar
	) {}


	async getUserList(action: BaseStateModel<UserDetailModel[]>): Promise<BaseStateModel<UserDetailModel[]>> {
		// TODO: gestire tutti i possibili parametri espressi in issue
		// https://gitlab.saep-ict.it/saep-ict/progetti-interni/starter-project/spin8/issues/61
		let queryParameter: UserManagementQueryParameter = {
			page_current: action.pagination && action.pagination.page_current,
			page_size: action.pagination && action.pagination.page_size,
			sort_type: action.sort && action.sort[0] && action.sort[0].sort_type ? action.sort[0].sort_type : null,
			sort_direction: action.sort && action.sort[0] && action.sort[0].sort_direction ? action.sort[0].sort_direction : null,
			id: action.filters && action.filters.id ? action.filters.id : null,
			text: action.filters && action.filters.text ? action.filters.text : null,
			context_code: action.filters && action.filters.context_code ? action.filters.context_code : null
		};

		queryParameter = this.utilService.deleteEmptyProperties(queryParameter);

		return this.userService
			.getUserList(queryParameter)
			.then(async res => {
				const actionReturn = <BaseStateModel<UserDetailModel[]>> {
					data: res.data,
					pagination: res.pagination,
					sort: res.sort,
					filters: res.filters
				};
				return actionReturn;
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async getUserDetail(restBasePk: any) {
		// TODO: do consolidare la situazione RestBasePk model
		const idUser = restBasePk.id;
		return this.userService
			.getUserDetail({id: idUser})
			.then(async res => {
				// TODO: capire come manipolare la response in modo da utilizzare lo stato di lista anche per il dettaglio
				return new BaseState([res.data], res.pagination);
			})
			.catch((err: RestBaseMessageError) => {
				throw new Error(err.body.detail);
			});
	}

	async postUserList(action: BaseStateModel<UserDetailModel[]>): Promise<BaseStateModel<UserDetailModel[]>> {
		const postUserListPayload = <RestPostUserListPayload> {
			user_list: action.data
		};
		return this.userService
			.postUserList(postUserListPayload)
			.then(async res => {
				return action;
			})
			.catch((err: RestBaseMessageError) => {
				this.snackBar.openFromComponent(MatSnackBarWrapperComponent, {
					duration: 5000,
					data: {
						message: err.body.detail
					}
				});
				console.log(err);
				throw new Error(err.body.detail);
			});
	}
}
