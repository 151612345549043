import { Injectable } from '@angular/core';
import { BaseStateModel } from '../../model/state/base-state.model';
import { PouchUtilService } from './pouch-util.service';
import { ContextPermission } from '../../model/user.model';
import { ContextApplicationItemCodeEnum } from '../../enum/context-application-item-code.enum';
import { ContextTypeAndApplicationLink } from '../../model/user-management.model';
import { BodyTablePouchCustomModel } from '../pouchdb/model/pouch-base.model';
import { FranchiserModel } from '../../model/state/agent-state.model';

// model

// constant

// store

// widget & utility

@Injectable({
	providedIn: 'root'
})
export class PermissionUtilService {

	// TODO: valutare se spostare questa associazione sul BE
	contextTypeAndApplicationLink = <ContextTypeAndApplicationLink[]> [
		{
			context_application_item_code: ContextApplicationItemCodeEnum.AGENT,
			context_code_item: {
				description: 'agent code',
				action_key: 'agent'
			},
			application_name: 'Agent App',
			icon: 'work'
		},
		{
			context_application_item_code: ContextApplicationItemCodeEnum.BACKOFFICE,
			context_code_item: {
				description: 'backoffice user code',
				action_key: 'backoffice'
			},
			application_name: 'Backoffice',
			icon: 'admin_panel_settings'
		}
	];

	constructor(
		private pouchUtilService: PouchUtilService
	) {}

	/**
	 * Restituisce i singoli oggetti che compongono il context_application_list
	 *
	 * @param {ContextPermission[]} context_application_list
	 * @param {ContextApplicationItemCodeEnum} context_application_item_code
	 * @returns {ContextPermission}
	 * @memberof PermissionUtilService
	 */
	getContextApplicationItem(
		context_application_list: ContextPermission[],
		context_application_item_code: ContextApplicationItemCodeEnum
	): ContextPermission {
		const contextApplicationItem: ContextPermission =
			context_application_list.find(i => i.code === context_application_item_code);
		return contextApplicationItem;
	}

	/**
	 * Restituisce il valore di context_application_list dopo averne aggiornato uno dei singoli oggetti
	 * che lo compongono
	 *
	 * @param {ContextPermission[]} context_application_list
	 * @param {ContextApplicationItemCodeEnum} context_application_item_code
	 * @param {ContextPermission} value
	 * @returns {ContextPermission[]}
	 * @memberof PermissionUtilService
	 */
	returnUpdatedFormContextApplicationListValue(
		context_application_list: ContextPermission[],
		context_application_item_code: ContextApplicationItemCodeEnum,
		value: ContextPermission
	): ContextPermission[] {
		context_application_list = context_application_list ? context_application_list : [];
		let context_application_item_not_found = true;
		// aggiornamento di un context_application_item
		for (let i = 0; i < context_application_list.length; i++) {
			if (context_application_list[i].code === context_application_item_code) {
				context_application_item_not_found = false;
				context_application_list[i] = value;
			}
		}
		// creazione di un context_application_item ancora non esistente nel form
		if (context_application_item_not_found) {
			context_application_list.push(value);
		}
		return context_application_list;
	}

	// promise

	async saveContextCode(action: BaseStateModel<BodyTablePouchCustomModel>):
		Promise<BaseStateModel<BodyTablePouchCustomModel>> {
		const actionReturn = <BaseStateModel<BodyTablePouchCustomModel>> action;
		try {
			actionReturn.data =
				await this.pouchUtilService.saveAnyDocument(action.data);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

	async saveContextCodeCodeItem(action: BaseStateModel<BodyTablePouchCustomModel | FranchiserModel>):
		Promise<BaseStateModel<BodyTablePouchCustomModel>> {
		const actionReturn = <BaseStateModel<BodyTablePouchCustomModel>> action;
		actionReturn.data.code_item =
			actionReturn.data._id.replace(
				actionReturn.data.type + '_', ''
			);
		try {
			actionReturn.data = await this.pouchUtilService.saveAnyDocument(actionReturn.data);
			return actionReturn;
		} catch (err) {
			console.log(err);
			throw new Error(err);
		}
	}

}
