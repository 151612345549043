import { StoreModule } from "@ngrx/store";
import { NgModule } from "@angular/core";
import { BackofficeCodeListStateReducer } from "./backoffice-code-list.reducer";

@NgModule({
    imports: [
        StoreModule.forFeature('backoffice-code-list', BackofficeCodeListStateReducer.reducer)
    ]
})
export class BackofficeCodeListStateModule{}
