import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MediaChange } from '@angular/flex-layout';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { NavigationService } from '../../service/navigation/navigation.service';
import { MediaReplayService } from '../../service/util/media-replay.service';

@Component({
	selector: 'ms-admin',
	templateUrl: './admin.component.html',
	styleUrls: ['./admin.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class FramePageComponent implements OnInit, OnDestroy {
	@ViewChild('sidenav', { static: false }) sidenav;

	private _mediaSubscription: Subscription;
	private _routerEventsSubscription: Subscription;

	sidenavOpen = false;
	sidenavMode = 'over';
	isMobile = false;

	constructor(
		private router: Router,
		public mediaReplayService: MediaReplayService,
		public navigationService: NavigationService,
		private cdr: ChangeDetectorRef
	) {
		this.mediaReplayService.deviceH = window.innerHeight;
		this.mediaReplayService.deviceW = window.innerWidth;
	}

	ngOnInit() {
		this.cdr.detectChanges();
		this._mediaSubscription = this.mediaReplayService.media$
			.pipe(debounceTime(100))
			.subscribe((change: MediaChange) => {
				this.mediaReplayService.deviceH = window.innerHeight;
				this.mediaReplayService.deviceW = window.innerWidth;
			});

		this._routerEventsSubscription = this.router.events.subscribe(event => {
			if (event instanceof NavigationEnd && this.isMobile) {
				this.sidenav.close();
			}
		});
	}

	ngOnDestroy() {
		if (this._mediaSubscription) {
			this._mediaSubscription.unsubscribe();
		}
		if (this._routerEventsSubscription) {
			this._routerEventsSubscription.unsubscribe();
		}
	}
}
