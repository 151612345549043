import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { from } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import {
	AgentStateModel,
	FranchiserModel
} from '../../model/state/agent-state.model';
import { BaseState, BaseStateModel } from '../../model/state/base-state.model';
import { PouchDbMobeeAgentiAdapter } from '../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter';
import { AgentActionEnum, AgentStateAction } from './agent.actions';

@Injectable()
export class AgentEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AgentActionEnum.LOAD),
			mergeMap((action: BaseStateModel<BasePouchModel>) => from(this.getAgent(action.data))),
			mergeMap((action: BaseStateModel<AgentStateModel>) =>
				from(this.mergeAgentFranchiserLinkInAgentList(action))
			),
			mergeMap((action: BaseStateModel<AgentStateModel>) => from(this.mergeFranchiserInAgentList(action))),
			map((agent: BaseStateModel<AgentStateModel>) => AgentStateAction.update(agent)),
			catchError((error, caught) => {
				this.store.dispatch(AgentStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(
		private actions$: Actions,
		private store: Store<any>,
		private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter
	) {}

	async getAgent(data: BasePouchModel): Promise<BaseStateModel<AgentStateModel>> {
		try {
			const idAgent = data._id;
			const agent = await this.pouchDbMobeeAgentiAdapter.agentPouch.getAgent(idAgent);

			return new BaseState(agent);
		} catch (err) {
			throw new Error(err);
		}
	}

	async mergeAgentFranchiserLinkInAgentList(
		action: BaseStateModel<AgentStateModel>
	): Promise<BaseStateModel<AgentStateModel>> {
		await this.pouchDbMobeeAgentiAdapter.agentPouch
			.getAgentFranchiserLink(action.data.code_item)
			.then(res => {
				action.data.franchiser = new FranchiserModel();
				action.data.franchiser = {
					code_item: res[0].code_franchiser
				};
			})
			.catch(err => {
				console.log(err);
			});
		return action;
	}

	async mergeFranchiserInAgentList(
		action: BaseStateModel<AgentStateModel>
	): Promise<BaseStateModel<AgentStateModel>> {
		await this.pouchDbMobeeAgentiAdapter.agentPouch
			.getFranchiserDetail(action.data.franchiser.code_item)
			.then(res => {
				action.data.franchiser = res;
			})
			.catch(err => {
				console.log(err);
			});
		return action;
	}
}
