import { Injectable } from "@angular/core";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { mergeMap, map, catchError } from "rxjs/operators";
import { PouchDbMobeeAgentiAdapter } from "../../service/pouchdb/mobee-agenti/pouchdb-mobee-agenti-adapter";
import { Store } from "@ngrx/store";
import { BaseStateModel, BaseState } from "../../model/state/base-state.model";
import { DestinationActionEnum, DestinationStateAction } from "./destination.actions";
import { DestinationFilterModel } from "../../service/pouchdb/filter/destination-filter.model";
import { DestinationPouchModel } from "@saep-ict/pouch_agent_models";
import { from } from "rxjs";

@Injectable()
export class DestinationEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(DestinationActionEnum.LOAD),
			mergeMap((action: BaseStateModel<DestinationFilterModel>) => from(this.getDestination(action.data))),
			map((customer: BaseStateModel<DestinationPouchModel>) => DestinationStateAction.update(customer)),
			catchError((error, caught) => {
				this.store.dispatch(DestinationStateAction.error(null));
				return caught;
			})
		)
	);

	constructor(private actions$: Actions, private store: Store<any>, private pouchDbMobeeAgentiAdapter: PouchDbMobeeAgentiAdapter) {}

	async getDestination(data: DestinationFilterModel): Promise<BaseStateModel<DestinationPouchModel>> {
		try {
			const destination = await this.pouchDbMobeeAgentiAdapter.destinationPouch.getDestination(data);
			return new BaseState(destination);
		} catch (err) {
			throw new Error(err);
		}
	}
}
