import { createAction, props } from '@ngrx/store';
import { BaseStateModel } from '../../model/state/base-state.model';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import { CustomerStateModel } from '../../model/state/customer-state.model';

export enum CustomerActionEnum {
    UPDATE = '[customer] Update',
    LOAD = '[customer] Load',
    SAVE = '[customer] Save',
    RESET = '[customer] Reset',
    ERROR = '[customer] Error'
}

export namespace CustomerStateAction{
    export const load = createAction(
        CustomerActionEnum.LOAD,
        props<BaseStateModel<BasePouchModel>>()
    );
    export const update = createAction(
        CustomerActionEnum.UPDATE,
        props<BaseStateModel<CustomerStateModel>>()
    );
    export const save = createAction(
		CustomerActionEnum.SAVE,
		props<BaseStateModel<BasePouchModel>>()
	);
    export const reset = createAction(
        CustomerActionEnum.RESET
    );
    export const error = createAction(
        CustomerActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
}
