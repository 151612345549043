import { NavItem } from './../../../model/structure/nav-item.model';
import { Component, OnInit, ViewEncapsulation, HostBinding, Input } from '@angular/core';
import { NavigationService } from '../../../service/navigation/navigation.service';

@Component({
  selector: 'ms-sidenav-item',
  templateUrl: './sidenav-item.component.html',
  styleUrls: ['./sidenav-item.component.scss'],
  //encapsulation: ViewEncapsulation.None
})
export class SidenavItemComponent implements OnInit {

  @Input() item: NavItem;

  @HostBinding('class.open')
  get isOpen() {
    return this.navigationService.isOpen(this.item);
  }

  @HostBinding('class.sidenav-item') sidenavItemClass = true;

  constructor(
    private navigationService: NavigationService
  ) { }

  ngOnInit() {
  }
  //
  toggleDropdown(): void {
    if (this.item.hasSubItems()) {
      this.navigationService.toggleCurrentlyOpen(this.item);
    }
  }

  // Receives the count of Sub Items and multiplies it with 56 (height of one SidenavItem) to set the height for animation.
  getSubItemsHeight(): string {
    return (this.getOpenSubItemsCount(this.item) * 56) + "px";
  }

  // Counts the amount of Sub Items there is and returns the count.
  getOpenSubItemsCount(item: NavItem): number {
    let count = 0;

    if (item.hasSubItems() && this.navigationService.isOpen(item)) {
      count += item.subItems.length;

      item.subItems.forEach((subItem) => {
        count += this.getOpenSubItemsCount(subItem);
      });
    }

    return count;
  }
}
