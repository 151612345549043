import { DestinationPouchModel, ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { DestinationFilterModel, DestinationListFilterModel } from '../../filter/destination-filter.model';
import { BaseStateModel } from '../../../../model/state/base-state.model';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';

export class DestinationPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;
	getDestination(data: DestinationFilterModel): Promise<DestinationPouchModel> {
		return new Promise((resolve, reject) => {
			this.get(`customer_destination_${data.code_customer}_${data.id}`)
				.then((doc: DestinationPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getDestinationList(filter: BaseStateModel<DestinationListFilterModel>): Promise<DestinationPouchModel[]> {
		const exists = {
			$exists: true
		};
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'customer_destination'
					}
				]
			};
			if (filter.data) {
				this.setField('code_customer', filter.data.code_customer ? filter.data.code_customer : exists);
				this.setField('valid', true);
				this.setField('code', filter.data.code ? filter.data.code : exists);
			}
			this.getWithIndexFilter('order-list', this.selector, filter.pagination, filter.sort ? filter.sort : [])
				.then((res: ListDataPouchModel<DestinationPouchModel>) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}

	setField(key: string, condition: any) {
		this.selector.$and.push({ [key]: condition });
	}
}
