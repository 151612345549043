import { createAction, props } from '@ngrx/store';

// model
import { UserDetailModel } from './../../../model/user.model';
import { BaseStateModel } from '../../../model/state/base-state.model';

export enum UserManagementStateActionEnum {
    UPDATE = '[user management] Update List',
    UPDATE_DETAIL = '[user management] Update Detail',
    LOAD_LIST = '[user management] Load List',
    LOAD_DETAIL = '[user management] Load Detail',
    RESET = '[user management] Reset',
    ERROR = '[user management] Error',
    SAVE_LIST = '[user management] Save List',
    SAVE_LIST_SUCCESS = '[user management] Save List Success'
}

export namespace UserManagementStateAction {
    export const update = createAction(
        UserManagementStateActionEnum.UPDATE,
        props<BaseStateModel<UserDetailModel[]>>()
    );
    export const loadList = createAction(
        UserManagementStateActionEnum.LOAD_LIST,
        props<BaseStateModel<UserDetailModel[]>>()
    );
    export const loadDetail = createAction(
        UserManagementStateActionEnum.LOAD_DETAIL,
        props<{id: string}>()
    );
    export const reset = createAction(
        UserManagementStateActionEnum.RESET
    );
    export const error = createAction(
        UserManagementStateActionEnum.ERROR,
        props<BaseStateModel<any>>()
    );
    export const saveList = createAction(
        UserManagementStateActionEnum.SAVE_LIST,
        props<BaseStateModel<UserDetailModel[]>>()
    );
    export const saveListSuccess = createAction(
        UserManagementStateActionEnum.SAVE_LIST_SUCCESS,
        props<BaseStateModel<UserDetailModel[]>>()
    );
}
