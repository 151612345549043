import { AbstractPouchdbMethod } from '@saep-ict/pouch-db';
import { ListDataPouchModel } from '@saep-ict/pouch_agent_models';
import { CustomerPouchModel } from '@saep-ict/pouch_agent_models/model/customer-pouch.model';
import { BaseStateModel } from '../../../../model/state/base-state.model';
import { CustomerListFilterModel } from '../../filter/customer-filter.model';
import { PouchErrorResponse } from '../../model/pouch-base-response.model';
import { Uuid, Customer } from '../../../../model/state/contract-state.model';
import { CustomerTypeEnum } from '../../../../enum/customer-type.enum';

export class CustomerPouch extends AbstractPouchdbMethod {
	selector: PouchDB.Find.Selector;

	getCustomer(customerCode: string): Promise<CustomerPouchModel> {
		return new Promise((resolve, reject) => {
			this.get('customer_' + customerCode)
				.then((doc: CustomerPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	putCustomer(data: CustomerPouchModel, isGuid: boolean): Promise<CustomerPouchModel> {
		let guid;
		if (isGuid) {
			guid = data._id;
		} else {
			const newUuid = Uuid.uuid();
			guid = 'customer_' + newUuid;
			data.code = newUuid;
			data.type = "customer";
			data.valid = true;
			data.status = "ENABLED";
			const customerExtended = data as Customer;
			if (customerExtended && customerExtended.customer_type === CustomerTypeEnum.PRIVATE) {
				data.business_name = customerExtended.last_name + ' ' + customerExtended.first_name;
			}
		}
		data.type = 'customer';
		return new Promise((resolve, reject) => {
			this.put<CustomerPouchModel>(data, guid, false)
				.then((doc: CustomerPouchModel) => {
					resolve(doc);
				})
				.catch(err => {
					console.log(err);
					reject(err);
				});
		});
	}

	getCustomerList(filter: BaseStateModel<CustomerListFilterModel>): Promise<CustomerPouchModel[]> {
		const exists = {
			$exists: true
		};
		return new Promise((resolve, reject) => {
			this.selector = {
				$and: [
					{
						type: 'customer'
					}
				]
			};
			if (filter.data) {
				if (filter.data.companySearchText) {
					const searchText = filter.data.companySearchText.trim();

					this.selector.$and.push({
						$or: [
							{
								business_name: {
									$regex: `(?i)${searchText}`
								}
							},
							{
								vat_number: {
									$regex: `(?i)${searchText}`
								}
							},
							{
								tax_code: {
									$regex: `(?i)${searchText}`
								}
							}
						]
					});
				}
			}
			this.getWithIndexFilter('customer-list', this.selector, filter.pagination, filter.sort ? filter.sort : [])
				.then((res: ListDataPouchModel<CustomerPouchModel>) => {
					resolve(res.docs);
				})
				.catch((err: PouchErrorResponse) => {
					reject(err);
				});
		});
	}

	setField(key: string, condition: any) {
		this.selector.$and.push({ [key]: condition });
	}
}
