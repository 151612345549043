import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApexOptions } from 'ng-apexcharts';

// model
export class DatasetPeriodModel {
  label: string;
  start: string;
  end: string;
  selected?: boolean;
}

export class DatasetTypeModel {
  label: string;
  id: string;
  icon: string;
  selected?: boolean;
}

export class CardChartConfigurableConfigurationSelected {
  period: DatasetPeriodModel;
  type: DatasetTypeModel;
}

export class CardChartConfigurableConfigurationModel {
  title: string;
  apexChart: ApexOptions;
  datasetType?: DatasetTypeModel[];
  datasetPeriod?: DatasetPeriodModel[];
}

@Component({
  selector: 'card-chart-configurable',
  templateUrl: './card-chart-configurable.component.html',
  styleUrls: ['./card-chart-configurable.component.scss']
})
export class CardChartConfigurableComponent implements OnInit {

  private _config: CardChartConfigurableConfigurationModel = new CardChartConfigurableConfigurationModel();

  configSelected: CardChartConfigurableConfigurationSelected = new CardChartConfigurableConfigurationSelected();

  @Input() set config(c: CardChartConfigurableConfigurationModel) {
    if (c) {
      this._config = c;
      this.setView();
    }
  };
  get config(): CardChartConfigurableConfigurationModel { return this._config; }

  @Output() onChange = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  // util
  setView() {
    if (this.config.datasetPeriod) {
      this.configSelected.period = this.config.datasetPeriod.filter(i => i.selected)[0];
    }
    if (this.config.datasetType) {
      this.configSelected.type = this.config.datasetType.filter(i => i.selected)[0];
    }
  }

  // emitter
  onParametersChange(type: string, e) {
    this.configSelected[type] = e;
    this.onChange.emit(this.configSelected);
  }

}
