export enum ContractStatusEnum {
	DRAFT = 'DRAFT',
	TO_CONFIRM = 'TO_CONFIRM',
	COMPLETED = 'COMPLETED',
	DELETED = 'DELETED'
	// ASK_CONF_RID = 'ASK_CONF_RID',
	// DELIVERING = 'DELIVERING',
}

export enum ContractStatusIconEnum {
	DRAFT = 'gesture',
	TO_CONFIRM = 'fact_check',
	// ASK_CONF_RID = 'account_balance',
	// DELIVERING = 'send',
	COMPLETED = 'check_circle',
	// ARCHIVED = 'archive'
}
