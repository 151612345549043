import { BodyTablePouchModel } from '@saep-ict/pouch_agent_models';
import { BasePouchModel } from '@saep-ict/pouch_agent_models/model/base-pouch.model';
import { BodyTablePouchCustomModel } from '../../service/pouchdb/model/pouch-base.model';

export class AgentStateModel implements BodyTablePouchModel, BasePouchModel {
	_id?: string;
	_rev?: string;
	type?: string;
	code_item: string;
	description?: string;
	description_short?: string;
	// custom
	first_name?: string;
	last_name?: string;
	email?: string;
	phone?: string;
	stats?: AgentStatisticsModel;
	franchiser?: FranchiserModel;
}

export class AgentStatisticsModel {
	total_orders?: number;
	orders_avg?: number;
	orders_count?: OrderStatusStatisticsModel;
	orders_validated?: number;
}

export class AgentFranchiserModel implements BasePouchModel {
	_id?: string;
	_rev?: string;
	type?: string;
	// custom
	code_agent: string;
	code_franchiser: string;
	phone?: string;
}

export class FranchiserModel implements BasePouchModel {
	_id?: string;
	_rev?: string;
	type?: string;
	code_item?: string;
	// custom
	business_name?: string;
	vat_number?: string;
	rea_number?: string;
	address?: string;
	zip_code?: string;
	locality?: string;
	province?: string;
	phone?: string;
	email_pec?: string;
	legal_representative?: string;
}

export class OrderStatusStatisticsModel {
	DRAFT: number;
	TO_CONFIRM: number;
	ASK_CONF_RID: number;
	DELIVERING: number;
	COMPLETED: number;
	ARCHIVED: number;
	DELETED: number;
}

export interface AgentCreateFullPipePayload extends BodyTablePouchCustomModel {
	custom_field: {
		franchiser: FranchiserModel;
		stats?: AgentStatisticsModel;
	};
}
